import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import React,{useContext, useState} from "react";
import useLanguage from "../../hooks/useLanguage";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GifsContext } from "../../state/gifContext";
import { baseURL } from "../../../helper/conf";

const WiseGifs = ({ setGifs }) =>{
    const { text } = useLanguage()
    const {gifs} = useContext(GifsContext)
    const tabWise = [
        {text:text.french,couleur:'#004aad',type:'wig',typeopt:'fr'},
        {text:text.english,couleur:'#ff3131',type:'wig',typeopt:'en'},
        {text:text.italian,couleur:'#1cb743',type:'wig',typeopt:'it'},
        {text:text.dutch,couleur:'#de5b6d',type:'wig',typeopt:'nl'},
        {text:text.spanish,couleur:'#f4a100',type:'wig',typeopt:'es'},
        {text:text.german,couleur:'#000000',type:'wig',typeopt:'de'},
]
    return(
        <Box>
            <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>
                <AccordionSummary  style={{width: "100%", backgroundColor: "#63747a", cursor: "pointer", minHeight: '20px', height: "30px"}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                    <Typography variant="pfsboldwhite" style={{textAlign: "center", width: '100%'}}>{text.wiseg}</Typography>
                </AccordionSummary>
                <Stack marginTop={'.5em'} gap={'.5em'}>
                    {tabWise.map((p,i) => <Accordion key={i} style={{position:'relative',left:'1.5em', backgroundColor: "transparent", boxShadow: 'none' }}>
                        <AccordionSummary style={{ width: "115%", backgroundColor: `${p.couleur}`, cursor: "pointer", minHeight: '20px', height: "35px" }} expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}>
                            <Typography variant="pfsboldwhite" style={{ width: "100%", textAlign: 'center' }}>{p.text}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'flex',gap:1}}>
                            <Grid gap={2} paddingTop={'5px'} container columns={{xs:4}} >
                                {gifs.map(gif => gif.type === p.type && gif.typeoption === p.typeopt && <Grid key={gif.id}><span onClick={()=>setGifs(gif)}>
                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ height: "4em"}} alt="gif"/>
                                </span>
                                </Grid>
                            )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    )}
                </Stack>
            </Accordion>
        </Box>
    )
}

export default WiseGifs