import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../axios';
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";
import useLanguage from '../hooks/useLanguage';

const DisplayMessage = ({idVp, idFp, error, setError, setLastMess}) => {
    const messagesEndRef = useRef(null);
    const { text } = useLanguage()
    const [ datas, setDatas ] = useState()

    // GET ALL MESSAGES
    const [ messages, setMessages ] = useState([])
    const [ loading, setLoading ] = useState(false)
    useEffect(() => {
        if(idVp && idFp){
            setError()
            setLoading(true)
            axiosInstance.get(`/message_lists/${idVp}/${idFp}`)
            .then((response) => {
                if(response.data.succes == true){
                    setMessages(response.data.data)
                    if (response.data.data.length > 0) {
                        const last = response.data.data[response.data.data.length - 1];
                        setLastMess(last);
                    }else{
                        setLastMess();
                    }
                }else{
                    setError(response.data.data)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            setError(text.chxha)
        }
    }, [idVp, idFp, text])

    // Message scroll botton
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);


    // PUSHER RECEIVE MESSAGE
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-message")
        channel.bind("add", (data)=> {
            setDatas(data)
            setLastMess(data);
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-message")
        }
    },[])

    useEffect(()=>{
        if(datas){
            if(datas.receiver_profil == idFp && datas.sender_profil == idVp){
                setMessages((prev)=>[...prev, datas])
            }
        }
    }, [datas, idFp, idVp])

    return (
        <Box sx={{ 
            height: '250px', 
            width: '98%',
            border: `2px solid #ccc`, 
            borderRadius: '8px', 
            padding: '10px', 
            backgroundColor: '#1e1e1e', 
            overflowY: 'auto' 
        }}>
            {error ? 
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        {error}
                    </div>
                </div>
                :
                ( messages?.length == 0 ?
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            {text.aucunt} .
                        </div>
                    </div>
                    :
                    messages?.map((msg, index) => (
                        <Box key={index} sx={{ 
                            width: '100%',  
                            display: "flex",
                            justifyContent: msg.type_profil === 2 ? "start" : "end"
                        }}>
                            <Stack
                                sx={{
                                    // minWidth: '100px',
                                    maxWidth: '600px',
                                    backgroundColor: msg.type_profil === 2 ? '#4caf50' : '#2196f3',
                                    marginBottom: '10px', 
                                    padding: '10px', 
                                    borderRadius: '8px', 
                                    color: 'black', 
                                }}
                            >
                                {msg.photos?.length > 0 &&
                                    <Stack flexDirection={"row-reverse"}>
                                        {msg.photos?.map((image) => {
                                            return (
                                                <div key={image.id}>
                                                    <div style={{ padding: '4px' }} >
                                                        <img src={`${baseURL}/photorequests/${image.photoName}`} style={{ width: '100px', height: '100px' }} alt="photo" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Stack>
                                }
                                {msg.gifs?.length > 0 &&
                                    <Stack flexDirection={"row-reverse"}>
                                        {msg.gifs?.map((gif) => {
                                            return (
                                                <div key={gif.id}>
                                                    <div style={{ padding: '4px' }} >
                                                        <img src={`${baseURL}/gifs/${gif.photoName}`} style={{ width: '100px', height: '100px' }} alt="gif" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Stack>
                                }
                                <Typography variant='h4'>
                                    {msg.content}
                                </Typography>
                            </Stack>
                        </Box>
                    ))
                )
            }
            <div ref={messagesEndRef} />
        </Box>
    )
}

export default DisplayMessage