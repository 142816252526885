import React from "react";
import { Stack, Typography } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import AccountantHeader from "../Header/AccountantHeader";
import AccountantBody from "./AccountantBody";

const Accountant = () => {
    const { text } = useLanguage()
    return (
        <Stack spacing={2} padding="20px 0">
            <AccountantHeader /> 
            <Stack style={{ position: 'relative', alignItems: 'center' }}>
                <img className="imgbg" src='/images/accountant/3.png' alt="Background" style={{ display: 'block'}}/>
                <Typography className="horizon-regular" variant="h2"
                    style={{fontFamily: 'Horizon Regular', position: 'absolute', zIndex: 2, top: '50%',
                    WebkitTextStroke: ' 1.3px #00546b', left: '50%', transform: 'translate(-50%, -50%)', 
                    textAlign: 'center', color: '#f5bb1b'} } 
                >
                    {text.accdash.toUpperCase()}
                </Typography>
                
            </Stack>
        <AccountantBody admin={false} />
        </Stack>
    )
} 

export default Accountant;