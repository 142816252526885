import axiosInstance from "../../axios"
import { useState, useContext } from "react"
import { roles } from "../../helper/roles"
import { AuthStateContext } from "../state/AuthStateContext"
import useData from "./useData"
import { formatDate } from "../../helper/formatDate"

const useList = () => {

    const [agentList, setAgentList] = useState([])
    const [supervisorList, setSupervisorList] = useState([])
    const [reportList, setReportList] = useState([])
    const [treatedConvList, setTreatedConvList] = useState({data:[],pagination:{}})
    const [convOnHoldList, setConvOnHoldList] = useState([])
    const [platformsPerf, setPlatformsPerf] = useState([])
    const { authState } = useContext(AuthStateContext)
    const [agentsPerf, setAgentsPerf] = useState([])
    const [qualityControllerList, setQualityControllerList] = useState({data:[],pagination:{}})
    const [accountantList, setAccountantList] = useState([])
    const [agentStatistics, setAgentStatistics] = useState([])
    const [wireTransfer, setWireTransfer] = useState([])
    const [paypal, setPaypal] = useState([])
    const [wise, setWise] = useState([])
    const [yoursafe, setYoursafe] = useState([])
    const [rate, setRate] = useState([])
    const [platform, setPlatform] = useState([])
    const { paymentList } = useData()

    const getOperatorList = async () =>{
        try {
            const { data } = await axiosInstance.get(`/user/${roles.operator}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            console.log(data)
            setAgentList(data)
        } catch (error){
            console.log(error)
        }
    }

    const getSupervisorList = async () =>{
        try {
            const { data } = await axiosInstance.get(`/user/${roles.supervisor}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            setSupervisorList(data)
        } catch (error){}
    }

    const getReportList = () =>{
        axiosInstance.get('/all_report', { 
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            setReportList(response.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getTreatedConvList = async (filter,page,type=1) =>{
        axiosInstance.get(`/conversation/treated?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&fakeProfil=${filter?.users}&conversation=${filter?.idconv}&message=${filter?.idmess}&agents=${filter?.agents}&page=${page}&type=${type}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                console.log(response.data.data)
                setTreatedConvList(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getConvOnHoldList = async () => {
        try {
            const {data} = await axiosInstance.get('/conversation_on_hold', { headers: {'Authorization': `Bearer ${authState.token}`} })
            setConvOnHoldList(data)
        } catch (error){}
    }

    const getPlatformsPerformance = async () => {
        try {
            const {data} = await axiosInstance.get('/platforms_performance', { headers: {'Authorization': `Bearer ${authState.token}`} })
            setPlatformsPerf(data)
        } catch (error){}
    }

    const getAgentsPerformance = async () => {
        try {
            const {data} = await axiosInstance.get('/agents_performance', { headers: {'Authorization': `Bearer ${authState.token}`} })
            setAgentsPerf(data)
        } catch (error){}
    }

    const getQualityControllerList = async () =>{
        try {
            const { data } = await axiosInstance.get(`/user/${roles.qc}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            setQualityControllerList(data)
            
        } catch (error){console.log(error)}
    } 

    const getAccountantList = async () =>{
        try {
            const { data } = await axiosInstance.get(`/user/${roles.accountant}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            setAccountantList(data.data)
        } catch (error){
            console.log(error)
        }
    } 

    const getAgentStatistics = async () =>{
        try {
            const { data } = await axiosInstance.get('/agent_statistics', { headers: {'Authorization': `Bearer ${authState.token}`} })
            setAgentStatistics(data)
        } catch (error){}
    }

    const getWireTransfer = async () =>{
        try {
            const { data } = await axiosInstance.get(`/payment/${paymentList[0].value}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            console.log(data)
            setWireTransfer(data)
        } catch (error){}
    }

    const getPaypal = async () =>{
        try {
            const { data } = await axiosInstance.get(`/payment/${paymentList[1].value}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            setPaypal(data)
        } catch (error){}
    }

    const getWise = async () =>{
        try {
            const { data } = await axiosInstance.get(`/payment/${paymentList[2].value}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            setWise(data)
        } catch (error){}
    }

    const getYoursafe = async () =>{
        try {
            const { data } = await axiosInstance.get(`/payment/${paymentList[3].value}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
            setYoursafe(data)
        } catch (error){}
    }

    const getRate = async () =>{
        try {
            const { data } = await axiosInstance.get('/rate', { headers: {'Authorization': `Bearer ${authState.token}`} })
            setRate(data)
        } catch (error){}
    }

    const getPlatform = async () =>{
        try {
            const { data } = await axiosInstance.get('/allplatform', { headers: {'Authorization': `Bearer ${authState.token}`} })
            setPlatform(data)
        } catch (error){}
    }

    return {agentList,setTreatedConvList, setAgentList, supervisorList, reportList, treatedConvList, convOnHoldList, platformsPerf, agentsPerf, qualityControllerList, accountantList, agentStatistics,setReportList, getOperatorList, getSupervisorList, getReportList, getTreatedConvList, getConvOnHoldList, getPlatformsPerformance, getAgentsPerformance, getQualityControllerList, getAccountantList, getAgentStatistics, wireTransfer, getWireTransfer, paypal, getPaypal, wise, getWise, yoursafe, getYoursafe, rate,setRate, getRate, platform, setPlatform, getPlatform}
}
export default useList