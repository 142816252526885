import { Stack, Typography, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";
import axiosInstance from "../../axios";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";

const StopMessages = () =>{
    const { globalFilter, setGlobalFilter } = useContext(GifsContext)
    
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: ''
    })
    const [filter2, setFilter2] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        agents: '',
    })
    const {text} = useLanguage()
    const { stopMessages,languagesList } = useData()
    const { control, handleSubmit, formState: {errors} } = useForm()

    // GET ALL STOP
    const [ stop, setStop ] = useState([])
    useEffect(() => {
        axiosInstance.get('/stop')
        .then((response) => {
          console.log(response.data.data)
          setStop(response.data.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])

    // PUSHER RECEIVE REPONSE STOP
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-stop")
        channel.bind("add", (data) => {
            console.log(data)
            setStop((prev) => prev.some((d) => d.id === data.id) ? 
                prev.map((d) => (d.id === data.id ? data : d))
                : 
                [...prev, data]
            );
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-stop")
        }
    },[])

    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#ff9b45', WebkitTextStroke: '2px #7f3a00'}}
                >{text.stopmessages}</Typography> */}
                <Title>{text.stopmessages}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/7sto.png"} alt="" height='50px' width='50px'/> */}
            </Stack>
            <BigContainerAccordion 
                transparent
                summaryBg="#bd2424"
                summary={text.lststpmsg}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.9,  text: "N:"},
                                { xs: 1.9,  text: text.recep},
                                { xs: 1.9, text: text.exp },
                                { xs: 1.9,  text: text.dtofdlvr},
                                { xs: 1.9,  text: text.rsptostp},
                                { xs: 1.9,  text: text.respVp},
                            ]}/>
                            {
                                stop.map((sm, key) =>
                                    <TableRow key={key} columns={[
                                        { xs: 1.9,  text: key+1},
                                        { xs: 1.9,  text: sm.vprofil},
                                        { xs: 1.9, text: sm.fprofil},
                                        { xs: 1.9,  text: sm.date},
                                        { xs: 1.9,  text: sm.response.length > 80 ? `${sm.response.substring(0, 80)} ...` : (sm.response == "pas encore." ? text.pasEn : sm.response)},
                                        { xs: 1.9,  text: sm.réresponse.length > 80 ? `${sm.réresponse.substring(0, 80)} ...` : (sm.réresponse == "pas encore." ? text.pasEn : sm.réresponse)},
                                    ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#1e3d58"
                summary={text.stpperfanal}
                details={
                    <Stack spacing={4}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter2({...filter2, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.agents , comp: 'TextInput', onChange: e => setFilter2({...filter2, agents: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={2}>
                            <FormRow label={text.stpgrssttl} control={control} name="stopgrosstotal" error={errors.stopgrosstotal} />
                            <FormRow label={text.rsptostp} control={control} name="responsetostop" error={errors.responsetostop} />
                            <FormRow label={text.stpprcntresp} control={control} name="stoppercentresponse" error={errors.stoppercentresponse} />
                            <FormRow label={text.avrgrsptm} control={control} name="averageresponsetime" error={errors.averageresponsetime} />
                        </Stack>
                        
                    </Stack>
                }
            />
        </Stack>
    )
}

export default StopMessages