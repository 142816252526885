import React, {useContext} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";

export default ({onDetails}) => {
    const { text } = useLanguage()
    const { stat } = useContext(StatContext);

    const languages = Object.keys(stat?.gifts ?? {});
    const platformsgif = Object.keys(stat?.gifs ?? {});

    const StatGiftTab = (language) => {
        const platforms = Object.keys(stat?.gifts[language] ?? {});
        return (
            <TableRow end columns={[
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {stat.gifts[language][platform]?.requests}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.gifts[language][platform]?.sent}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };
    const StatGifTab = () => {
        return (
            <TableRow end columns={[
                { xs: 5,
                    customContent: true,
                    content: <Stack>
                        {platformsgif.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 6.7,
                    customContent: true,
                    content: <Stack>
                        {platformsgif.map(platform => (
                            <Typography  key={platform}>
                                {stat?.gifs[platform]}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };
    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.gftgtfman}</Title>
        <Stack spacing={1}>
            <TableRow header end columns={[
                { xs: 5.9,  text: text.gfts},
            ]}/>
            <TableRow header end columns={[
                { xs: 2.9,  text: text.language},
                { xs: 2.9,  text: text.platform},
                { xs: 2.9,  text: text.rqsts},
                { xs: 2.9,  text: text.sent},
            ]}/>
            {languages.map((language) => (<Stack key={language}>{StatGiftTab(language)}</Stack>))}

        </Stack>
        <Stack spacing={1}>
            <TableRow header end columns={[
                { xs: 6.7,  text: text.gifpj},
            ]}/>
            <TableRow header end columns={[
                { xs: 5,  text: text.platform},
                { xs: 6.7,  text: text.total},
            ]}/>
            <Stack>{StatGifTab()}</Stack>

        </Stack>
        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

