import React, {useState, createContext} from 'react';

const SupervisorStateContext = createContext(false);

const SupervisorStateProvider = ({children}) => {
    const [supervisorState, setSupervisorState] = useState(0)
    return (
        <SupervisorStateContext.Provider value={{ supervisorState, setSupervisorState }}>
            {children}
        </SupervisorStateContext.Provider>
    )
}
export {SupervisorStateContext , SupervisorStateProvider }