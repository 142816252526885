export const formatDate = (date) => {

    if (!(date instanceof Date) || isNaN(date)) {
        return "";
    }

    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear().toString();

    const day = d < 10 ? '0' + d : d;
    const month = m < 10 ? '0' + m : m;

    return `${day}-${month}-${y}`;
};

export const formatDate2 = (date) => {

    if (!(date instanceof Date) || isNaN(date)) {
        return "";
    }

    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear().toString();

    const day = d < 10 ? '0' + d : d;
    const month = m < 10 ? '0' + m : m;

    return `${y}-${month}-${day}`;
};
const setProf = (setProfil, ind, ind1, ind2, ind3, number,casen) => {
    if (ind1 !== null && (ind === ind1 || ind === ind2) || (ind === ind3 && (casen === 2 && number.dodge || !casen)) ) {
        setProfil(casen === 1 ? 
            ind === ind1 ? { type: false, content: number.sender_profil } :
            ind === ind2 ? { type: true, content: number.message } :
            {type: true, content: number.content} : 
            ind !== ind3 ? { type: false, content: ind === ind1 ? 
            number.sender_profil : number.receiver_profil }:
            { type: true, content: casen === 2 ? number.dodge : number.content })
        return true
    }
}
export const checkPop = (setProfil, ind, stat, number) => {
    switch (stat) {
        case 1: return setProf(setProfil, ind, 3, 4, 5, number)
        case 2: return setProf(setProfil, ind, 3, 7, 6, number)
        case 3: return setProf(setProfil, ind, 1, 2, 3, number)
        case 4: return setProf(setProfil, ind, 6,5,9,number,1)
        case 5: return setProf(setProfil, ind, 5, 4, 3, number)
        case 6: return setProf(setProfil, ind, null, null, 5, number)
        case 7: return setProf(setProfil, ind, 7, 8, null, number)
        case 9: return setProf(setProfil, ind, 7, 6, 3, number)
        case 10: return setProf(setProfil, ind, 7, 8, 10, number,2)
    }
    return false
}
