import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React,{useContext} from "react";
import useLanguage from "../../hooks/useLanguage";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GifsContext } from "../../state/gifContext";
import { baseURL } from "../../../helper/conf";

const SexualGifs = ({ setGifs }) =>{
    const { text } = useLanguage()
    const {gifs} = useContext(GifsContext)

    const tabSG = [
        {text:text.bdsm,couleur:'#004aad',type:'seg',typeopt:'bdsm'},
        {text:text.dont,couleur:'#ff3131',type:'seg',typeopt:'dont'},
        {text:text.blj,couleur:'#1cb743',type:'seg',typeopt:'blj'},
        {text:text.org,couleur:'#de5b6d',type:'seg',typeopt:'org'},
        {text:text.vagpen,couleur:'#f4a100',type:'seg',typeopt:'vagpen'},
        {text:text.sod,couleur:'#000000',type:'seg',typeopt:'sod'},
        {text:text.titss,couleur:'#64483b',type:'seg',typeopt:'titss'},
]

    return(
        <Box>
            <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>
                <AccordionSummary  style={{width: "100%", backgroundColor: "#ff3131", cursor: "pointer", minHeight: '20px', height: "30px"}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                    <Typography variant="pfsboldwhite" style={{textAlign: "center", width: '100%'}}>{text.sexg}</Typography>
                </AccordionSummary>
                <Stack marginTop={'.5em'} gap={'.5em'}>
                    {tabSG.map((p,i) => <Accordion key={i} style={{position:'relative',left:'1.5em', backgroundColor: "transparent", boxShadow: 'none' }}>
                        <AccordionSummary style={{ width: "115%", backgroundColor: `${p.couleur}`, cursor: "pointer", minHeight: '20px', height: "35px" }} expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}>
                            <Typography variant="pfsboldwhite" style={{ width: "100%", textAlign: 'center' }}>{p.text}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'flex',gap:1}}>
                            {gifs.map(gif => gif.type === p.type && gif.typeoption === p.typeopt && <span key={gif.id} onClick={()=>setGifs(gif)}>
                            <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                            </span>)}
                        </AccordionDetails>
                    </Accordion>
                    )}
                </Stack>
            </Accordion>

        </Box>
    )
}

export default SexualGifs