import { Box, Stack, Typography, Icon } from "@mui/material";
import React from "react";
import { ChevronRight } from '@mui/icons-material';

export default ({ rank, text, selected=0 }) => {
    const imageDimensions = "35px"
    return <Stack flexDirection='row' width="100%" justifyContent='space-between' alignItems="center">
        <Stack position="relative">
            <Box component='img' src={`/images/admin2/${rank}.png`} sx={{ height: imageDimensions, margin: '0 15px' }}/>
            { (selected == rank) && <Icon style={{ position: 'absolute', bottom: "0", right: "0" }}><ChevronRight/></Icon>}
        </Stack>
        <Typography textTransform="none" flexGrow={1} textAlign="start" fontWeight="bold">{text}</Typography>
        { (selected == rank) && <Icon style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><ChevronRight/></Icon> }
    </Stack>
}