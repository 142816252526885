import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import RoutesList from './helper/routes'
import Auth from './components/Auth/Auth';
import Register from './components/Auth/Register';
import Admin from './components/Admin/Admin';
import Admin2 from './components/Admin2/Admin';
import Operator from './components/Operator/Operator';
import Supervisor from './components/Supervisor/Supervisor';
import { AuthStateProvider, AuthStateContext } from './components/state/AuthStateContext';
import { MessageProvider } from './components/state/MessageContext';
import WithThemeProvider from './helper/theme';
import useDynamicTitle from './components/hooks/useDynamicTitle';
import post from './axios'
import useLanguage from './components/hooks/useLanguage';
import useAuth from './components/hooks/useAuth';
import { SupervisorStateProvider } from './components/state/SupervisorStateContext';
import Accountant from './components/Accountant/Accountant';
import QualityController from './components/QualityController/QualityController';
import Statistics from './components/Operator/Statistics';
import './App.css'
import GifsProvider from './components/state/gifContext';
import SendMessage from './components/SiteTest/SendMessage';
import PrivacyPolicy from './components/Auth/PrivacyPolicy';
import Home from './components/SiteTest/Home';
import Poke from './components/SiteTest/Poke';
import Stop from './components/SiteTest/Stop';
import Teaser from './components/SiteTest/Teaser';
import LoginTest from './components/SiteTest/LoginTest';
import { StatProvider } from './components/state/StatContext';


const PrivateRoute = ({from, to: destination, isAuthRoute=false}) =>{
  const { authState: {success, userId, userRole} } = useContext(AuthStateContext)
  const { getDest } = useAuth()
  const dest = getDest(userRole)
  if (userId) return (dest.includes(from)) ? destination : <Navigate to={dest[0]}/> 
  else return (isAuthRoute) ? destination : <Navigate to={dest[0]}/>

}
const Redirect = ({to: destination}) =>{
  const { currentLanguage } = useLanguage()
  return <Navigate to={destination + currentLanguage}/>
}

const Routing = () =>{
  document.title = useDynamicTitle()
  return(
      <Routes>    
          <Route path='/login' element={<LoginTest />} />
          <Route path='/home' element={<Home />} />
          <Route path='/sendmessage' element={<SendMessage />} />
          <Route path='/pokemessage' element={<Poke />} />
          <Route path='/stopmessage' element={<Stop />} />
          <Route path='/teasermessage' element={<Teaser />} />

          <Route path={RoutesList.landing} element={<Redirect to={RoutesList.auth}/>}/>   

          <Route path={RoutesList.auth} element={<Redirect to={RoutesList.auth}/>}/>
          <Route path={RoutesList.register} element={<Redirect to={RoutesList.register}/>}/>
          <Route path={RoutesList.admin} element={<Redirect to={RoutesList.admin}/>}/>     
          <Route path={RoutesList.operator} element={<Redirect to={RoutesList.operator}/>}/>     
          <Route path={RoutesList.supervisor} element={<Redirect to={RoutesList.supervisor}/>}/>  
          <Route path={RoutesList.accountant} element={<Redirect to={RoutesList.accountant}/>}/>  
          <Route path={RoutesList.qualitycontroller} element={<Redirect to={RoutesList.qualitycontroller}/>}/>  
          <Route path={RoutesList.statistics} element={<Redirect to={RoutesList.statistics}/>}/>
          <Route path={RoutesList.pp} element={<Redirect to={RoutesList.pp}/>}/>
          <Route path={RoutesList.adminNew} element={<Redirect to={RoutesList.adminNew}/>}/>

          <Route path={RoutesList.authFinal} element={<PrivateRoute from={RoutesList.auth} to={<Auth/>} isAuthRoute={true}/>}/>
          <Route path={RoutesList.registerFinal} element={<PrivateRoute from={RoutesList.register} to={<Register/>} isAuthRoute={true}/>}/>
          <Route path={RoutesList.operatorFinal} element={<PrivateRoute from={RoutesList.operator} to={<Operator/>}/>}/>    
          <Route path={RoutesList.adminFinal} element={<PrivateRoute from={RoutesList.admin} to={<Admin/>}/>}/>    
          <Route path={RoutesList.supervisorFinal} element={<PrivateRoute from={RoutesList.supervisor} to={<SupervisorStateProvider><Supervisor/></SupervisorStateProvider>}/>}/>
          <Route path={RoutesList.accountantFinal} element={<PrivateRoute from={RoutesList.accountant} to={<Accountant/>}/>}/>
          <Route path={RoutesList.qualitycontrollerFinal} element={<PrivateRoute from={RoutesList.qualitycontroller} to={<QualityController/>}/>}/>
          <Route path={RoutesList.statisticsFinal} element={<PrivateRoute from={RoutesList.statistics} to={<Statistics/>} />}/>
          <Route path={RoutesList.ppFinal} element={<PrivacyPolicy/>}/>
          {/* <Route path={RoutesList.adminNewFinal} element={<Admin2/>}/> */}
          <Route path={RoutesList.adminNewFinal} element={<PrivateRoute from={RoutesList.adminNew} to={<Admin2/>}/>}/>    
         
      
      </Routes>       
  )
}

const App = () => {
  return( 
        <AuthStateProvider>
          <GifsProvider>
            <MessageProvider>
              <StatProvider> 
                <BrowserRouter>
                  <WithThemeProvider>
                    <Routing/>
                  </WithThemeProvider>
                </BrowserRouter>
              </StatProvider>
            </MessageProvider>
          </GifsProvider>
        </AuthStateProvider>
  )
}

export default App