import {useState,useEffect,useContext} from 'react'
import Pusher from 'pusher-js'
import { PUSHER_SECRET,CLUSTER,apiURL } from "../../helper/conf";
import ChatOverview0 from "../Supervisor/ChatOverview0";
import ChatOverview from "../Admin/ChattoolOverview";
import { AuthStateContext } from "../state/AuthStateContext";
import Conversations from '../Supervisor/Conversations1'
import Agents from '../Supervisor/Agents2'
import PanicRoom from '../Supervisor/PanicRoom3'
import Notes from '../Supervisor/Notes4'
import TeaserMessages from '../Supervisor/TeaserMessages5'
import PokeMessages from '../Supervisor/PokeMessages6'
import StopMessages from '../Supervisor/StopMessages7'
import PhotosRequest from '../Supervisor/PhotosRequest8'
import Report from '../Supervisor/Report9'
import LogBook from '../Supervisor/LogBook10'
import DuplicatedMessages from '../Supervisor/DuplicatedMessages11'
import Gifs from '../Supervisor/Gifs12'
import SupervisorAndTrafficManagement from "../Admin/SupTrMng";
import QualityControllerBody from "../QualityController/QualityControllerBody";
import AccountantBody from "../Accountant/AccountantBody";

const CurrentComponentSuper = ({state,setState,stat,setStat}) => {
    const { authState:{userRole} } = useContext(AuthStateContext)
        switch (state){
            case 0: return userRole === 'administrator' ? <ChatOverview setStat={setStat} stat={stat} seeDetails={setState}/> : <ChatOverview0 setStat={setStat} stat={stat} seeDetails={setState}/>
            case 1: return <Conversations/>
            case 2: return <Agents/>
            case 3: return <PanicRoom/>
            case 4: return <Notes/>
            case 5: return <TeaserMessages/>
            case 6: return <PokeMessages/>
            case 7: return <StopMessages/>
            case 8: return <PhotosRequest/>
            case 9: return <Report/>
            case 10: return <LogBook/>
            case 11: return <DuplicatedMessages/>
            case 12: return <Gifs/>
            case 13: return <SupervisorAndTrafficManagement/>
            case 14: return <QualityControllerBody admin/>
            case 15: return <AccountantBody/>
            default: return <></>
        }
}
export default CurrentComponentSuper