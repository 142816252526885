import React, {useState, useEffect, useContext} from "react";
import { Stack, Typography, Button, Pagination} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import useData from "../hooks/useData";
import TableRow from "../tools/TableRow";
import useList from "../hooks/useList";
import { GifsContext } from "../state/gifContext";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { formatDate2 } from "../../helper/formatDate";
import SlidingTypography from "../tools/SlidingTypography";
import { baseURL } from "../../helper/conf";

const Paypal = () => {
    const { globalFilter } = useContext(GifsContext)
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
        country: '',
        payment: ''
    }))

    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const { languagesList, paymentList } = useData()
    

    // LIST PAYPAL PAYMENT STATISTIQUE
    const [ datas, setDatas ] = useState()
    const [ total, setTotal ] = useState()
    const [ pagination, setPagination ] = useState()
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        const type = "paypal"
        axiosInstance.get(`/paymentBy?type=${type}&year=${filter?.year}&month=${filter?.month}&date=${formatDate2(filter?.date)}&platform=${filter?.platforms}&week=${filter?.semaine}&startingDate=${formatDate2(filter?.startingDate)}&closingDate=${formatDate2(filter?.closingDate)}&page=${page}&payment=${filter?.payment}&agent=${filter?.agents}&country=${filter?.country}&language=${filter?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setDatas(response.data.data)
            setTotal(response.data.total)
            setPagination(response.data.pagination)
        })
        .catch((error) => {
            console.log(error)
        })
    },[filter, page])

    // GET PRINT PDF PAYPAL
    const [ loading, setLoading ] = useState(false)
    const handlePrint = () => {
        const type = "paypal"
        const isPrint = true

        setLoading(true)
        axiosInstance.get(`/paymentBy?isPrint=${isPrint}&type=${type}&year=${filter?.year}&month=${filter?.month}&date=${formatDate2(filter?.date)}&platform=${filter?.platforms}&week=${filter?.semaine}&startingDate=${formatDate2(filter?.startingDate)}&closingDate=${formatDate2(filter?.closingDate)}&page=${page}&payment=${filter?.payment}&agent=${filter?.agents}&country=${filter?.country}&language=${filter?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.pdfName)
            const pdfName = response.data.pdfName;

            // Ouvrir le PDF dans un nouvel onglet
            const downloadUrl = `${baseURL}/print/${pdfName}`;
            window.open(downloadUrl, '_blank');

            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }
    
    return (
        <Stack spacing={2} padding="20px 0">
            <SearchFilter payment
                onDateChange={{
                    year: y => setFilter({...filter, year: y}),
                    month: m => setFilter({...filter, month: m}),
                }}
                items= {[
                    { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },                    
                    { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                    { label: text.agent, comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                    { label: text.agcountry, comp: 'TextInput', onChange: e => setFilter({...filter, country: e.target.value}) },
                ]}
            />
            
            <Stack spacing={'10px'}>
                <TableRow header columns={[
                    { xs: 1.25,  text: text.agentid},
                    { xs: 1.25,  text: text.login},
                    { xs: 1.25,  text: text.language},
                    { xs: 1.25,  text: "Total payment"},
                    { xs: 1.25,  text: "Agent Full Name"},
                    { xs: 1.25,  text: "Agent Full Address"},
                    { xs: 1.25,  text: "Agent Country of residency"},
                    { xs: 1.25,  text: text.paymeth},
                    { xs: 1.25,  text: "Bank Details"},
                ]}/>
                { datas?.map((data, i) => (
                    <TableRow
                        key={i}
                        columns={[
                            { xs: 1.25, text: data.name },
                            { xs: 1.25, text: data.login },
                            { xs: 1.25, text: data.language },
                            { xs: 1.25, text: `${data.totalPayment} €` },
                            { xs: 1.25, text: data.fullName },
                            { xs: 1.25, text: data.fullAddress },
                            { xs: 1.25, text: data.country },
                            { xs: 1.25, text: data.paymentMethod },
                            {
                                xs: 1.25,
                                customContent: true,
                                content: (
                                    <Stack sx={{ width: '100%'}}>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            Email: {data.bankDetails.email}
                                        </SlidingTypography>
                                    </Stack>
                                ),
                            },
                        ]}
                    />
                ))}
                <TableRow
                    columns={[
                        { xs: 1.25,  text: "Gross Total"},
                        { xs: 1.25,  text: ""},
                        { xs: 2.56,  text: `${total} €` },
                        { xs: 1.25,  text: ""},
                        { xs: 1.25,  text: ""},
                        { xs: 1.25,  text: ""},
                        { xs: 1.25,  text: ""},
                        { xs: 1.25,  text: ""},
                    ]}
                />
            </Stack>

            <Stack justifyItems="center" alignItems="center" >
                <Pagination
                    page={page}
                    onChange={handleChange}
                    count={pagination?.total_pages}
                    variant="outlined" 
                    shape="rounded" 
                    color="secondary"
                />
            </Stack>

            <Button 
                variant="formrow" 
                style={{ alignSelf:"flex-end"}}
                onClick={() => handlePrint()}
            >
                <Typography variant="pfsboldwhite" style={{textTransform: "capitalize"}}>
                    { loading ? "loading" : text.prstat }
                </Typography>
            </Button>

        </Stack>
    )
} 

export default Paypal;