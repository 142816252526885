import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Button } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import AdminHeader from "../Header/AdminHeader";
import ConvOnHold from "../Supervisor/ConvOnHold";
import CurrentComponentSuper from "../tools/CurrentComponentSuper";
import { AuthStateContext } from "../state/AuthStateContext";
import { MessageContext } from "../state/MessageContext";
import Pusher from 'pusher-js'
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";

const Admin = () => {
    const { text } = useLanguage()
    const [state, setState] = useState(0)
    const [stat, setStat] = useState()
    const { authState } = useContext(AuthStateContext)
    const {setChan1} = useContext(MessageContext)

    useEffect(()=>{
        console.log(authState.token)
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const strUsrId = authState.userId.toString()
        const chan = pusher.subscribe('private-'+strUsrId)
        setChan1(chan)
        return ()=> {
            chan.unbind()
            pusher.unsubscribe('private-'+strUsrId)
        }
    },[])
    return (
        <Stack paddingBottom='50px'>
            <AdminHeader stat={stat} setState={setState} />
            <Stack spacing={3}>
                <ConvOnHold inverseDisplay/>
                <Stack className="height-resize-adm" position='relative' alignSelf='center'>
                    <Typography variant="h2" style={{whiteSpace: 'nowrap', fontFamily: 'Horizon Regular', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#0cc0df', WebkitTextStroke: '1px #096474'}}>{text.admdash.toUpperCase()}</Typography>
                    <img src="/images/admin/cadre.png" alt="" style={{position: 'absolute', height: '100%', width: '100%', top: '0', left: '0'}}/>
                </Stack>            
            </Stack>
            <CurrentComponentSuper stat={stat} setStat={setStat} state={state} setState={setState}/>
            { 
                (state != 0) ?
                <Button onClick={()=>setState(0)} style={{backgroundColor: '#feba3b', alignSelf: 'center', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}><Typography>{text.back}</Typography></Button>
                : <></>
            }
        </Stack>
    )
} 

export default Admin;