import React, { useState, useEffect, useContext } from "react";
import TableRow from "../tools/TableRow";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import SearchFilter from "../tools/SearchFilter";
import useData from "../hooks/useData";
import useLanguage from "../hooks/useLanguage";
import { Stack, Typography, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, Chip, Box, Icon, Dialog, DialogTitle, DialogActions, DialogContent, Pagination } from "@mui/material";
import AddQualityController from "./AddQualityController";
import useList from "../hooks/useList";
import { format } from "date-fns";
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import axiosInstance from "../../axios";
import { GifsContext } from "../state/gifContext";
import SlidingTypography from "../tools/SlidingTypography";
import { toNameCase } from "../tools/Snackbars";
import { findIndex, selectMenu, selectMenu2 } from "../tools/selectMenu";
import { AuthStateContext } from "../state/AuthStateContext";
import Pusher from 'pusher-js'
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";
import Title from "../Admin2/Comps/Title";
import { MessageContext } from "../state/MessageContext";

const QualityControllerBody = ({ admin = false }) => {
    const { globalFilter, setGlobalFilter } = useContext(GifsContext)
    const { qclistofmess, qcpannel, languagesList, qualityControllerOpt, critereQCOpt, appCntrlQC, qualityResultOpt, qualityResultOpt2 } = useData()
    const { text } = useLanguage()
    const typeData = { data: [], pagination: {page:1,total_pages:0,total_items:0},total_mess:0,total_type:[] }
    const {authState} = useContext(AuthStateContext)
    const { qualityControllerList, getQualityControllerList, treatedConvList, getTreatedConvList, setTreatedConvList } = useList()
    const [listMsg, setListMsg] = useState(typeData)
    const [platformSelect,setPlatformSelect] = useState([{value:0,innerHTML:''}])
    const [listMsgNTreated, setListMsgNTreated] = useState(typeData)
    const [listMsgTreated, setListMsgTreated] = useState(typeData)
    const [statByAgent, setStatByAgent] = useState(typeData)
    const [statByQc,setStatByQC] = useState(typeData)
    const [chargement, setChargement] = useState(false)
    const typeUser = authState.userRole === 'qualitycontroller' ? 1 : 2
    const {chan1} = useContext(MessageContext)
    const [newMsg,setNewMsg] = useState(null)
    const [newMsgQC,setNewMsgQC] = useState(null)
    const defaultFilter = {
        ...globalFilter,
        users: '',
        idconv: '',
        idmess: '',
        messId:'',
        idControl:'',
        archive:'',
        page:1,
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [filter1, setFilter1] = useState(defaultFilter)
    const [filter2, setFilter2] = useState(defaultFilter)
    const [filter3, setFilter3] = useState(defaultFilter)
    const [filter4, setFilter4] = useState(defaultFilter)

    const handleChange = (e, j) => {
        const value = e.target.value
        console.log(value,listMsgNTreated)
        setListMsgNTreated(p => ({ ...p, data: p.data.map((k, i) => i === j ? { ...k, qc_control: typeof value === 'string' ? value.split(',') : value } : k) }))
    }

    const handleChangeResult = (e, j, type) => {
        const value = e.target.value
        switch (type) {
            case 0:return setListMsgNTreated(p => ({ ...p, data: p.data.map((k, i) => i === j ? { ...k, qc_result: value } : k) }))
            case 1: return setStatByAgent(p => ({ ...p, data: p.data.map((k, i) => i === j ? { ...k, qc_appreciate: value } : k) })) //statAgent 1
            case 2: return setStatByAgent(p => ({ ...p, data: p.data.map((k, i) => i === j ? { ...k, qc_action: value } : k) })) //statAgent 2
            default: return setStatByQC(p => ({ ...p, data: p.data.map((k, i) => i === j ? { ...k, qc_appreciate: value } : k) })) //statQC
        }
    }
    const checkNull = (res,list,setList) => !res.length && list.data.length || res.data.length ? setList(res):''
    const handleFilter = async(value,type) => {
        switch (type) {
            case 1: return getDataQC(type,value).then(res=> checkNull(res,listMsgNTreated,setListMsgNTreated))
            case 3: return getDataQC(type,value).then(res=> checkNull(res,statByAgent,setStatByAgent))
            case 4: return getDataQC(type,value).then(res=> checkNull(res,statByQc,setStatByQC))
            case 2: return getDataQC(type,value).then(res=> checkNull(res,listMsgTreated,setListMsgTreated))
            default: return getDataQC(type,value).then(res=> checkNull(res,listMsg,setListMsg))
        }
    }
    const getDataQC = async (n,value) => {
        let data
        await axiosInstance.get(`/listQC/${n}?page=${value.page > 0 ? value.page:1}&control=${value.idControl}&platform=${value.platforms ? value.platforms:''}&messid=${value.idmess}&convid=${value.idconv}&agent=${value.agents}&language=${value.language}&archive=${value.archive ? format(value.archive,'yyyy-MM'):''}`).then(res => data = res.data).catch(e => console.log(e))
        switch(n){
            case 3: case 4: {
                let tab = []
                n === 3 ? setFilter3(value):setFilter4(value)
                Object.entries(data.data).map(c => tab.push(c[1]))
                console.log('list qc ',data,tab)
                return { data: tab,total_type:data.total_type,total_mess:data.total_mess, pagination: data.pagination }
            }
            case 1: {setFilter1(value);console.log('data not treated : ',data);break;}
            case 2: {setFilter2(value);console.log('data treated : ',data);break;}
            default: {setFilter(value);break;}
        }
        return data
    }
    const getAllData = async () => {
        axiosInstance.get('/listSelect').then(res=>setPlatformSelect(res.data)).catch(e => console.log(e))
        setListMsg(await getDataQC(10,filter))
        if(typeUser === 1){
            setListMsgNTreated(await getDataQC(1,filter1))
            setStatByAgent(await getDataQC(3,filter3))
        }
        setListMsgTreated(await getDataQC(2,filter2))
        if(admin){
            setStatByQC(await getDataQC(4,filter4))
            await getQualityControllerList()
        }
        // setChargement(true)
    }
    useEffect(() => {
        console.log(authState.token)
        if (!chargement) getAllData()
        if(typeUser === 1){
            const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
            const strUsrId = authState.userId.toString()
            const chan = pusher.subscribe('private-'+strUsrId)
            chan.bind('listQC',data => setNewMsg(data))
            return ()=> {
            chan.unbind()
            pusher.unsubscribe('private-'+strUsrId)
            }
        }
        if(admin) {
            chan1.bind('listMsgT',data => setNewMsgQC(data))
            chan1.bind('listQC',data => setNewMsg(data))
        }
    }, [])
    useEffect(()=>{
        if(newMsgQC){
            let msg = listMsg.data.find(k => k.message_id === newMsgQC.message_id)
            msg = {...msg,ag_log:newMsgQC.ag_log,qc_id:newMsgQC.qc_id,qc_control:newMsgQC.qc_type}
            addMsgPag(listMsgTreated,setListMsgTreated,msg)
            const data = {qc_control:newMsgQC.qc_type}
            const updVal = updateVal(newMsgQC.qc_id1,statByQc,data)
            const changUpd1 = changUpd(data,statByQc.total_type)
            setStatByQC(p => ({...p,data:updVal,total_mess:p.total_mess+1,total_type:changUpd1}))
            setListMsg(p => ({...p,data:p.data.map(k => k.message_id === newMsgQC.message_id ? {...k,validation:true} : k)}))
            setNewMsgQC(null)
        }
    },[newMsgQC])
    useEffect(()=>{
        if(newMsg){
            addMsgPag(listMsg,setListMsg,newMsg)
            if(typeUser === 1) {
                const dataNT = {content:newMsg.content,conv_id:newMsg.conv_id,message_at:newMsg.message_at,message_id:newMsg.message_id,operator:newMsg.operator,qc_control:[],qc_result:null,receiver_profil:newMsg.receiver_profil,sender_profil:newMsg.sender_profil,type:2}
                addMsgPag(listMsgNTreated,setListMsgNTreated,dataNT)
            }
            setNewMsg(null)
        }
    },[newMsg])
    const addMsgPag = (list,setList,newData) => {
        let tabMsgTreated = list.data
        const total_items = list.pagination.total_items+1
        const total_pages = Math.ceil(total_items/10)
        if(list.pagination.page === 1) {
            tabMsgTreated.unshift(newData)
            if(total_pages>1) tabMsgTreated.pop()
        }
        setList(p => ({...p,data:tabMsgTreated,pagination:{...p.pagination,total_items:total_items,total_pages:total_pages}}))
    }
    
    const changUpd = (data,updT) => {
        const updateType = updT
        console.log('update Type before : ',updateType)
        data.qc_control.forEach(qc=> updateType[qc]+=1)
        console.log('update Type after : ',updateType)
        return updateType
    }
    const updateVal = (ag_id,p,data) => {
        return p.data.map(k => {
            if(k.agent_id === ag_id){
            const updateType = changUpd(data,k.type)
            return {...k,number_msg:k.number_msg+1,type:updateType}
        } return k
        })
    }

    const validateAction = async (data,type) => {
        const validateQC = () => {
            console.log('data : ',data)
            const datas = {agent:data.agent_id,qc_appreciate:data.qc_appreciate,qc_action:data.qc_action,qc_critere:JSON.stringify(data.type),number_msg:data.number_msg}
            axiosInstance.post('/setAppreciate',datas).then(()=>{
                if(type === 1) setStatByAgent(p => ({...p,data:p.data.map(k => k.agent_id === data.agent_id ? {...k,validation:true}:k)}))
                else setStatByQC(p => ({...p,data:p.data.map(k => k.agent_id === data.agent_id ? {...k,validation:true}:k)}))
            }).catch(e=>console.log(e))
        }
        const validateQCMsg = () => {
            const listMsgNT = listMsgNTreated.data.filter(k => k.message_id !== data.message_id)
            if(listMsgNT.length) setListMsgNTreated(p => ({...p, data: listMsgNT}))
            else getDataQC(1,1).then(res=>setListMsgNTreated(res))
            const dataS = { message_id: data.message_id, note: JSON.stringify(data.qc_control), action: data.qc_result }
            axiosInstance.post('/qcControl', dataS,{headers:{ 'Authorization': `Bearer ${authState.token}`}}).then(res => {
                const rslt = res.data
                // const updateQC = updateVal(rslt.agent_id,statByAgent,data)
                setStatByAgent(p => ({...p,data:updateVal(rslt.agent_id,p,data),total_mess:p.total_mess+1,total_type:changUpd(data,p.total_type)}))
                const dataT = {...data,ag_log:rslt.ag_log,qc_id:rslt.qc_id,language:rslt.language,platform:rslt.platform}
                setListMsg(p => ({ ...p, data: p.data.map(k => k.message_id === data.message_id ? { ...k, validation: true } : k) }))
                addMsgPag(listMsgTreated,setListMsgTreated,dataT)
            }).catch(e => console.log(e))
        }
        if(!type) validateQCMsg()
        else validateQC()
    }

    const [openDialog, setOpenDialog] = useState({ status: false, data: null })

    const closeODialog = () => setOpenDialog({ ...openDialog, status: false })
    const clickT = (number) => {
        setOpenDialog({ status: true, data: number })
    }
    const afficheSR = (n) => {
        return openDialog.status && ((n === 1 ? openDialog.data.sender_profil.Name : openDialog.data.receiver_profil.Name) + ' ' + ((openDialog.data.operator && (openDialog.data.type !== n)) ? '(' + text.operator + ' : ' + openDialog.data.operator + ')' : ''))
    }
    const dateF = (date) => format(new Date(date), 'dd/MM/yyyy hh:mm:ss')
    return <Stack style={{ overflowY: "scroll" }} spacing={2}>
        <Stack display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
            <Dialog open={openDialog.status} onClose={closeODialog}>
                <DialogTitle >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '2em' }}>{text.continfo} : {openDialog.status ? openDialog.data.message_id : ''}</Typography>
                </DialogTitle>
                {openDialog.status &&

                    <DialogContent sx={{ minWidth: '420px' }}>
                        <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', gap: 2 }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>{text.conv} : {openDialog.data.conv_id}</Typography>
                            <Typography sx={{ fontWeight: 'bold', color: '#858585', fontSize: '1.2em' }}>{dateF(openDialog.data.message_at)}</Typography>
                        </Stack>
                        <Box style={{ display: 'flex', gap: 4 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{text.exp} :</Typography>
                            <Typography>{afficheSR(1)}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: 4 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{text.recep} :</Typography>
                            <Typography>{afficheSR(2)}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: 4, maxWidth: '400px', flexDirection: openDialog.data.content.length > 30 ? 'column' : 'row' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{text.mess} {openDialog.data.content.length < 30 ? ':' : ''}</Typography>
                            <Typography>{openDialog.data.content}</Typography>
                        </Box>
                    </DialogContent>
                }
                <DialogActions sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant='contained' color='success' onClick={closeODialog}>OK</Button>
                </DialogActions>
            </Dialog>
            {/* <Typography style={{
                fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#c5a9ff',
                WebkitTextStroke: '2px #8547ff'
            }} variant="h1" >{text.qctrl}
            </Typography> */}
            <Title>{text.qctrl}</Title>
            {/* <img src="/images/qc/4.png" alt="" width="7%" /> */}
        </Stack>
        <BigContainerAccordion
            transparent
            summaryBg="#1e3d58"
            summary={text.lstmess}
            details={
                <Stack spacing={2}>
                    <SearchFilter
                        selectedDate={filter.date}
                        onDateChange={{
                            year: y => setFilter({ ...filter, year: y }),
                            month: m => setFilter({ ...filter, month: m }),
                            date: d => setFilter({ ...filter, date: d }),
                        }}
                        items={[
                            { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                            { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                            { label: text.platform, comp: 'Select', choices: platformSelect, onChange: e => handleFilter({ ...filter, platforms: e.target.value },10) },
                            { label: text.language, comp: 'Select', choices: languagesList, onChange: e => handleFilter({ ...filter, language: e.target.value },10) },
                            { label: text.idconv, comp: 'TextInput', onChange: e => handleFilter({ ...filter, idconv: e.target.value },10) },
                            { label: text.idmess, comp: 'TextInput', onChange: e => handleFilter({ ...filter, idmess: e.target.value },10) },
                        ]}
                    />
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: 1.37, text: text.date },
                            { xs: 1.37, text: text.platform },
                            { xs: 1.37, text: text.language },
                            { xs: 1.37, text: text.idconv },
                            { xs: 1.37, text: text.idmess },
                            { xs: 1.37, text: text.mess },
                            { xs: 1.37, text: text.agent },
                            { xs: 1.37, text: text.qctrl },
                        ]} />
                        {
                            listMsg.data.map((c, i) =>
                                <TableRow key={`msg${c.conv_id}${c.message_id}`} columns={[
                                    { xs: 1.37, text: dateF(c.message_at)},
                                    { xs: 1.37, text: c.platform },
                                    { xs: 1.37, text: c.language },
                                    { xs: 1.37, text: c.conv_id },
                                    { xs: 1.37, text: c.message_id },
                                    { xs: 1.37, text: c.content },
                                    { xs: 1.37, text: c.operator },
                                    {
                                        xs: 1.37,
                                        customContent: true,
                                        content: (c.validation) ?
                                            <Typography color='#1bb642' style={{ fontFamily: 'Delm' }}>{text.yes}</Typography>
                                            : <Typography color='#ff3131' style={{ fontFamily: 'Delm' }}>{text.no}</Typography>
                                    }
                                ]} clickT={clickT} number={c} stat={6} ind={i > -1 ? true : false} />
                            )
                        }
                    </Stack>
                    {listMsg.data.length ? <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={listMsg.pagination.page}
                                onChange={(e,v) => v !== listMsg.pagination.page && handleFilter({...filter,page:v},10)}
                                count={listMsg.pagination.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>:<></>}
                </Stack>
            }
        />

        {typeUser === 1 && <BigContainerAccordion
            transparent
            summaryBg="#ff3131"
            summary={text.qcpnl}
            details={
                <Stack spacing={2}>
                    <SearchFilter
                        selectedDate={filter.date}
                        onDateChange={{
                            year: y => setFilter({ ...filter, year: y }),
                            month: m => setFilter({ ...filter, month: m }),
                            date: d => setFilter({ ...filter, date: d }),
                        }}
                        items={[
                            { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                            { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                            { label: text.platform, comp: 'Select', choices: platformSelect, onChange: e => handleFilter({ ...filter, platforms: e.target.value },1) },
                            { label: text.language, comp: 'Select', choices: languagesList, onChange: e => handleFilter({ ...filter1, language: e.target.value },1) },
                            { label: text.agent, comp: 'TextInput', onChange: e => handleFilter({ ...filter1, agents: e.target.value },1) },
                            { label: text.idconv, comp: 'TextInput', onChange: e => handleFilter({ ...filter1, idconv: e.target.value },1) },
                            { label: text.idmess, comp: 'TextInput', onChange: e => handleFilter({ ...filter1, idmess: e.target.value },1) },
                        ]}
                    />
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: .8, text: text.idconv },
                            { xs: .8, text: text.idmess },
                            { xs: 1.2, text: text.agent },
                            { xs: 1.5, text: text.mess },
                            { xs: 1.2, text: text.userprofil },
                            { xs: 1.2, text: text.botprofil },
                            { xs: 1.9, text: text.qccrit },
                            { xs: 1.4, text: text.actions },
                            { xs: 1, text: text.traitement },
                        ]} />
                        {
                            listMsgNTreated.data.map((qcp, j) => !qcp.validation &&
                                <TableRow key={`qcp${qcp.conv_id}${qcp.message_id}`} columns={[
                                    { xs: .8, text: qcp.conv_id },
                                    { xs: .8, text: qcp.message_id },
                                    { xs: 1.2, text: qcp.operator },
                                    { xs: 1.5, text: qcp.content },
                                    { xs: 1.2, text: qcp.receiver_profil.Name },
                                    { xs: 1.2, text: qcp.sender_profil.Name },
                                    {
                                        xs: 1.9,
                                        customContent: true,
                                        content: selectMenu(qcp, j, handleChange, qualityControllerOpt)
                                    },
                                    {
                                        xs: 1.4,
                                        customContent: true,
                                        content: selectMenu2(qcp, j, handleChangeResult, qualityResultOpt,0)
                                    },
                                    {
                                        xs: 1,
                                        customContent: true,
                                        content: <Icon onClick={() => (qcp.qc_control.length && !qcp.qc_result !== null) ? validateAction(qcp,0) : undefined} style={{ width: '100%', cursor: (qcp.qc_control.length && !qcp.qc_result !== null) ? 'pointer' : '', justifyContent: 'center', background: (!qcp.qc_control.length || qcp.qc_result === null) ? '#c9c9c9' : !qcp.validation ? '#82b57c' : '#82b57c66', color: 'white', height: '100%', display: 'flex', placeItems: 'center' }}>
                                            <TaskAltSharpIcon />
                                        </Icon>

                                    }
                                ]} clickT={clickT} number={qcp} type={2} stat={5} ind={j > -1 ? true : false} />
                            )
                        }
                    </Stack>
                    {listMsgNTreated.data.length ? <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={listMsgNTreated.pagination.page}
                                onChange={(e,v) => v !== listMsgNTreated.pagination.page && handleFilter({...filter3,page:v},1)}
                                count={listMsgNTreated.pagination.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>:<></>}
                </Stack>

            }
        />}

        <BigContainerAccordion transparent
            summaryBg="#e34ff5"
            summary={text.statcontrol}
            details={
                <Stack spacing={2}>
                    <SearchFilter
                        selectedDate={filter.date}
                        onDateChange={{
                            year: y => setFilter({ ...filter, year: y }),
                            month: m => setFilter({ ...filter, month: m }),
                            date: d => setFilter({ ...filter, date: d }),
                        }}
                        items={[
                            { label: text.platform, comp: 'Select', choices: platformSelect, onChange: e => handleFilter({ ...filter, platforms: e.target.value },2) },
                            { label: text.language, comp: 'Select', choices: languagesList, onChange: e => handleFilter({ ...filter2, language: e.target.value },2) },
                            { label: text.idconv, comp: 'TextInput', onChange: e => handleFilter({ ...filter2, idconv: e.target.value },2) },
                            { label: text.idmess, comp: 'TextInput', onChange: e => handleFilter({ ...filter2, idmess: e.target.value },2) },
                            { label: text.agentid, comp: 'TextInput', onChange: e => handleFilter({ ...filter2, agents: e.target.value },2) },
                            { label: text.controlID, comp: 'TextInput', onChange: e => handleFilter({ ...filter2, idControl: e.target.value },2) },
                        ]}
                    />
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: .7, text: text.date },
                            { xs: .5, text: text.idconv },
                            { xs: .5, text: text.idmess },
                            { xs: 1, text: text.mess },
                            { xs: .5, text: text.language },
                            { xs: .8, text: text.platform },
                            { xs: 1, text: text.userprofil },
                            { xs: 1, text: text.botprofil },
                            { xs: .8, text: text.agent },
                            { xs: 1, text: text.aglogin },
                            { xs: .7, text: text.qcid },
                            { xs: 1, text: text.qccrit },
                            { xs: 1, text: text.actions },
                        ]} />
                        {
                            listMsgTreated.data.map((c, i) =>
                                <TableRow key={`mt${c.conv_id}${c.message_id}`} columns={[
                                    { xs: .7, text: dateF(c.message_at)},
                                    { xs: .5, text: c.conv_id },
                                    { xs: .5, text: c.message_id },
                                    { xs: 1, text: c.content },
                                    { xs: .5, text: toNameCase(c.language) },
                                    { xs: .8, text: c.platform },
                                    { xs: 1, text: c.receiver_profil.Name },
                                    { xs: 1, text: c.sender_profil.Name },
                                    { xs: .8, text: c.operator },
                                    { xs: 1, text: c.ag_log },
                                    { xs: .7, text: c.qc_id },
                                    {
                                        xs: 1, customContent: true,
                                        content: <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .4, width: '100%', padding: '5px' }}>
                                            {c.qc_control.map((value, i) => <SlidingTypography key={i} delm color={qualityControllerOpt[value].color}>{qualityControllerOpt[value].name}</SlidingTypography>)}
                                        </Box>
                                    },
                                    { xs: 1, text: text.dlt },
                                ]} clickT={clickT} number={c} stat={9} ind={i > -1 ? true : false} />
                            )
                        }
                    </Stack>
                    {listMsgTreated.data.length ? <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={listMsgTreated.pagination.page}
                                onChange={(e,v) => v !== listMsgTreated.pagination.page && handleFilter({...filter3,page:v},2)}
                                count={listMsgTreated.pagination.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>:<></>}
                </Stack>
            }
        />

        {typeUser === 1 && <BigContainerAccordion transparent
            summaryBg="#d4d711"
            summary={text.statcontrolag}
            details={
                <Stack spacing={2}>
                    <SearchFilter
                        selectedDate={filter3.date}
                        onDateChange={{
                            year: y => setFilter({ ...filter, year: y }),
                            month: m => setFilter({ ...filter, month: m }),
                            date: d => setFilter({ ...filter, date: d }),
                        }}
                        items={[
                            { label: text.language, comp: 'Select', choices: languagesList, onChange: e => handleFilter({...filter3,language:e.target.value},3) },
                            { label: text.idconv, comp: 'TextInput', onChange: e => handleFilter({...filter3,idconv:e.target.value},3) },
                            { label: text.idmess, comp: 'TextInput', onChange: e => handleFilter({...filter3,idmess:e.target.value},3) },
                            { label: text.agentid, comp: 'TextInput', onChange: e => handleFilter({...filter3,agents:e.target.value},3) },
                            { label: text.archives, comp: 'DatePickerArch', selected:filter3.archive, onChange: e => handleFilter({...filter3,archive:e},3)},
                        ]}
                    />
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: 1.37, text: text.agent },
                            { xs: 1.37, text: text.aglogin },
                            { xs: .8, text: text.language },
                            { xs: 1.37, text: text.nbrmess },
                            { xs: 2.5, text: text.qccrit },
                            { xs: 1.37, text: text.appQC },
                            { xs: 1.37, text: text.actions },
                            { xs: 1, text: text.traitement }
                        ]} />
                        {
                            statByAgent.data.map((c, i) =>
                                <TableRow key={`stat_${c.agent_id}`} columns={[
                                    { xs: 1.37, text: c.agent_name },
                                    { xs: 1.37, text: c.agent_mail },
                                    { xs: .8,   text: c.agent_lang },
                                    { xs: 1.37, text: c.number_msg },
                                    {
                                        xs: 2.5,
                                        customContent: true,
                                        content:
                                            <Stack style={{ width: '100%', height: '100%', padding: '10px', justifyContent: 'center', placeItems: 'center' }}>
                                                <Box>{critereQCOpt.map((k, j) => <Box key={j} style={{ display: 'flex', gap: '5px' }}>
                                                    <Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '.8em' }}>{k.name} :</Typography>
                                                    <Typography style={{ fontFamily: 'cursive', fontSize: '.8em', fontWeight: 'bold', color: j === 1 || j === 6 || j === 7 ? '#ff2b36' : '#00bb00' }}>{c.type[j]}</Typography>
                                                </Box>)}
                                                </Box>
                                            </Stack>
                                    },
                                    {
                                        xs: 1.37,
                                        customContent: true,
                                        content: selectMenu2(c, i, handleChangeResult, appCntrlQC,1)
                                    },
                                    {
                                        xs: 1.37,
                                        customContent: true,
                                        content: selectMenu2(c, i, handleChangeResult, qualityResultOpt2,2)
                                    },
                                    {
                                        xs: 1,
                                        customContent: true,
                                        content: <Icon onClick={() => !c.validation && (c.qc_appreciate !== null && c.qc_action !== null) && validateAction(c,1)} style={{ width: '100%', cursor: 'pointer', justifyContent: 'center', background: c.validation ? '#82b57c66': (c.qc_appreciate !== null && c.qc_action !== null) ? '#82b57c':'#c9c9c9', color: 'white', height: '100%', display: 'flex', placeItems: 'center' }}>
                                            <TaskAltSharpIcon />
                                        </Icon>

                                    }
                                ]} />
                            )
                        }
                          {statByAgent.data.length ? 
                          <>
                          <TableRow key={`agent_final`} columns={[
                                    { xs: 1.37, text: ''},
                                    { xs: 2.24, customContent:true,
                                        content:<Typography  style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '1.3em' }}>{text.ttlmsg}</Typography>
                                    },
                                    { xs: 1.37, customContent:true,
                                        content:<Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '1.3em' }}>{statByAgent.total_mess}</Typography>
                                    },
                                    {
                                        xs: 2.5,
                                        customContent: true,
                                        content:
                                            <Stack style={{ width: '100%', height: '100%', padding: '10px', justifyContent: 'center', placeItems: 'center' }}>
                                                 <Box>{critereQCOpt.map((k, j) => <Box key={j} style={{ display: 'flex', gap: '5px' }}>
                                                    <Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '.8em' }}>{k.name} :</Typography>
                                                    <Typography style={{ fontFamily: 'cursive', fontSize: '.8em', fontWeight: 'bold', color: j === 1 || j === 6 || j === 7 ? '#ff2b36' : '#00bb00' }}>{statByAgent.total_type[j]}</Typography>
                                                </Box>)}
                                                </Box>
                                            </Stack>
                                    },
                                    { xs: 3.9,text:''},
                                ]} stat={9} />
                                <Stack justifyItems="center" alignItems="center" >
                                    <Pagination
                                        page={statByAgent.pagination.page}
                                        onChange={(e, v) => v !== statByAgent.pagination.page && handleFilter({...filter3,page:v}, 3)}
                                        count={statByAgent.pagination.total_pages}
                                        variant="outlined"
                                        shape="rounded"
                                        color="secondary"
                                    />
                                </Stack>
                            </>
                            : <></>}
                    </Stack>
                    
                </Stack>
            } 
        />}

        {admin && <BigContainerAccordion transparent
            summaryBg="#4f73f5"
            summary={text.statcontrolct}
            details={
                <Stack spacing={2}>
                    <SearchFilter
                        selectedDate={filter.date}
                        onDateChange={{
                            year: y => setFilter({ ...filter, year: y }),
                            month: m => setFilter({ ...filter, month: m }),
                            date: d => setFilter({ ...filter, date: d }),
                        }}
                        items={[
                            { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                            { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                            { label: text.platform, comp: 'Select', choices: platformSelect, onChange: e => handleFilter({ ...filter, platforms: e.target.value },4) },
                            { label: text.language, comp: 'Select', choices: languagesList, onChange: e => handleFilter({...filter4,language:e.target.value},4) },
                            { label: text.idconv, comp: 'TextInput', onChange: e => handleFilter({ ...filter, idconv: e.target.value },4) },
                            { label: text.idmess, comp: 'TextInput', onChange: e => handleFilter({ ...filter, idmess: e.target.value },4) },
                            { label: text.archives, comp: 'DatePickerArch', selected:filter4.archive, onChange: e => handleFilter({...filter4,archive:e},4)},
                        ]}
                    />
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: 1.5, text: text.controlID },
                            { xs: 1.5, text: text.controlLog },
                            { xs: 1.2, text: text.language },
                            { xs: 1.5, text: text.nbrmess },
                            { xs: 2.5, text: text.qccrit },
                            { xs: 1.5, text: text.appQC },
                            { xs: 1.5, text: text.traitement }
                        ]} />
                        {
                            statByQc.data.map((c, i) =>
                                <TableRow key={`qc_${i}`} columns={[
                                    { xs: 1.5, text: c.agent_name},
                                    { xs: 1.5, text: c.agent_mail},
                                    { xs: 1.2, text: c.agent_lang},
                                    { xs: 1.5, text: c.number_msg},
                                    {
                                        xs: 2.5,
                                        customContent: true,
                                        content:
                                            <Stack style={{ width: '100%', height: '100%', padding: '10px', justifyContent: 'center', placeItems: 'center' }}>
                                                <Box>{critereQCOpt.map((k, j) => <Box key={j} style={{ display: 'flex', gap: '5px' }}>
                                                    <Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '.8em' }}>{k.name} :</Typography>
                                                    <Typography style={{ fontFamily: 'cursive', fontSize: '.8em', fontWeight: 'bold', color: j === 1 || j === 6 || j === 7 ? '#ff2b36' : '#00bb00' }}>{c.type[j]}</Typography>
                                                </Box>)}
                                                </Box>
                                            </Stack>
                                    },
                                    {
                                        xs: 1.5,
                                        customContent: true,
                                        content: selectMenu2(c, i, handleChangeResult, appCntrlQC,3)
                                    },
                                    {
                                        xs: 1.5,
                                        customContent: true,
                                        content: <Icon onClick={() => !c.validation && c.qc_appreciate !== null && validateAction(c,2)} style={{ width: '100%', cursor: 'pointer', justifyContent: 'center', background: c.validation ? '#82b57c66': c.qc_appreciate !== null ? '#82b57c':'#c9c9c9', color: 'white', height: '100%', display: 'flex', placeItems: 'center' }}>
                                            <TaskAltSharpIcon />
                                        </Icon>

                                    }
                                ]}/>    
                            )
                        }
                         {statByQc.data.length ? 
                         <>
                         <TableRow key={`qc_final`} columns={[
                                    { xs: 1.5, text: ''},
                                    { xs: 2.77, customContent:true, content:<Typography  style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '1.3em' }}>{text.ttlmsg}</Typography> },
                                    { xs: 1.5, customContent:true, content:<Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '1.3em' }}>{statByQc.total_mess}</Typography> },
                                    {
                                        xs: 2.5,
                                        customContent: true,
                                        content:
                                            <Stack style={{ width: '100%', height: '100%', padding: '10px', justifyContent: 'center', placeItems: 'center' }}>
                                                <Box>{critereQCOpt.map((k, j) => <Box key={j} style={{ display: 'flex', gap: '5px' }}>
                                                    <Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '.8em' }}>{k.name} :</Typography>
                                                    <Typography style={{ fontFamily: 'cursive', fontSize: '.8em', fontWeight: 'bold', color: j === 1 || j === 6 || j === 7 ? '#ff2b36' : '#00bb00' }}>{statByQc.total_type[j]}</Typography>
                                                </Box>)}
                                                </Box>
                                            </Stack>
                                    },
                                    { xs: 3.05,text:''},
                                ]} stat={9}/>
                                <Stack justifyItems="center" alignItems="center" >
                                    <Pagination
                                        page={statByQc.pagination.page}
                                        onChange={(e, v) => v !== statByQc.pagination.page && handleFilter({...filter3,page:v}, 4)}
                                        count={statByQc.pagination.total_pages}
                                        variant="outlined"
                                        shape="rounded"
                                        color="secondary"
                                    />
                                </Stack>
                                </>
                                :<></>}
                    </Stack>
                </Stack>
            }
        />}

        {
            admin ?
                <>
                    <BigContainerAccordion transparent
                        summaryBg="#00bf15"
                        summary={text.addqc}
                        details={<AddQualityController />}
                    />
                    <BigContainerAccordion transparent
                        summaryBg="#10c7e9"
                        summary={text.lstqc}
                        details={
                            <Stack spacing={2}>
                                <SearchFilter
                                    selectedDate={filter.date}
                                    onDateChange={{
                                        year: y => setFilter({ ...filter, year: y }),
                                        month: m => setFilter({ ...filter, month: m }),
                                        date: d => setFilter({ ...filter, date: d }),
                                    }}
                                    items={[
                                        { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                                        { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                                        { label: text.platform, comp: 'TextInput', onChange: e => setFilter({ ...filter, platforms: e.target.value }) },
                                        { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e }) },
                                    ]}
                                />
                                <Stack spacing={'10px'}>
                                    <TableRow header columns={[
                                        { xs: 1.83, text: text.date },
                                        { xs: 1.83, text: text.platform },
                                        { xs: 1.83, text: text.language },
                                        { xs: 1.83, text: text.qcnm },
                                        { xs: 1.83, text: text.qclgn },
                                        { xs: 1.83, text: text.qclgnpsswrd },
                                    ]} />
                                    {
                                        qualityControllerList.data.map(s =>
                                            <TableRow key={s.id} columns={[
                                                { xs: 1.83, text: s.date_inscription ? format(new Date(s.date_inscription), 'dd/MM/yyyy') : '' },
                                                { xs: 1.83, text: s.platform },
                                                { xs: 1.83, text: s.language?.name },
                                                { xs: 1.83, text: s.name },
                                                { xs: 1.83, text: s.mail },
                                                {
                                                    xs: 1.83,
                                                    customContent: true,
                                                    content: <Stack width='100%'>
                                                        <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }}><Typography style={{ fontFamily: 'Delm' }}>{text.edit}</Typography></Button>
                                                        <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }}><Typography style={{ fontFamily: 'Delm' }}>{text.dlt}</Typography></Button>
                                                    </Stack>
                                                },
                                            ]} />
                                        )
                                    }
                                </Stack>
                            </Stack>
                        }
                    />
                </>

                : <></>
        }
    </Stack>
}

export default QualityControllerBody