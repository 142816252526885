import React, { useRef, useState} from "react";
import { Controller } from "react-hook-form"
import { Stack, Typography, IconButton, Box, Button, InputAdornment, OutlinedInput} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

const FileInput = ({variant="contained", visiblePartVariant, control, name, required=false, accept="*/*", error, label="", name2="", setValue = (a, b) =>{}, onExportFile=()=>{}, profil=false}) => {
    const {text} = useLanguage()
    const fileInput = useRef(null)
    const [file, setFile] = useState('')
    const getType = type =>{
        const parts = type.split("/")
        return parts[0]
    }
    const getFileName = nameWithExt =>{
        let parts = nameWithExt.split('.')
        parts.pop()
        return parts.join('.')
      }

    const visiblePart = () =>{
        switch (visiblePartVariant){
            case "FileName": return <Controller
                control={control}
                name={name2}
                defaultValue=""
                rules={{required: (required) ? "req" : false}}
                render={({ field }) =>
                    <OutlinedInput type='text' {...field} autoComplete="off" variant={variant}
                        endAdornment={
                            <>
                            <InputAdornment position="start">
                                {
                                    file.name ?
                                    <Typography style={{ backgroundColor: 'lightgray', padding: '5px 30px', borderRadius: '10px', width: 'fit-content' }}>
                                        {file.name}
                                    </Typography>
                                    : <></>
                                }
                            </InputAdornment>
                            <InputAdornment position="end">
                                <IconButton onClick={()=> fileInput.current.click()}>
                                    <UploadFileIcon/>
                                </IconButton>
                            </InputAdornment>
                            </>
                        }
                    />
                        
                }
            />
            case "TotalInput": return <Stack alignItems='center' style={{width: '100%', height: '100%'}}>
                <Stack style={{height: '52px', width: '100%', 
                    backgroundColor: '#f993', borderRadius: '3px',  border: '1px solid #cabbbb', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Stack/>
                    <IconButton onClick={()=> fileInput.current.click()} style={{
                        height: 'fit-content', width: 'fit-content', marginRight: '5px' }}>
                        <UploadFileIcon/>
                    </IconButton>
                </Stack>
            </Stack>
            case "Browser": return <Stack spacing={2}>
                <Box className="wrap-content"  gap="25px" padding="0px 20px" >
                    <Stack width="70%" alignSelf="center"><Typography variant="white">{label}</Typography></Stack>
                    <Stack width='20%'>
                        <Button variant="browser"  className="browser-button" onClick={()=> fileInput.current.click()}>
                            <Stack display="flex" flexDirection="row" alignItems='center' gap={1}>
                                <Typography>{text.info13}</Typography>
                                <OpenInBrowserIcon fontSize="small"/>
                            </Stack>
                        </Button>
                    </Stack>
                </Box>
                {profil ? <></> :
                    <Stack alignItems='center'>
                        { file.name ?
                            (
                                (getType(file.type) == "image")?
                                <Box component='img' sx={{height: '200px', width: '200px'}} src={URL.createObjectURL(file)}/>
                                : <Typography style={{ backgroundColor: 'lightgray', padding: '5px 30px', borderRadius: '10px', width: 'fit-content' }}>{file.name}</Typography> 
                            )
                        : <></>}
                    </Stack>
                }       
            </Stack>
            
            default: return <></>
        }
    }

    return(
        <Stack style={{width: '100%', height: '100%'}}>
            { visiblePart() }
            <Controller
                control={control}
                name={name}
                defaultValue=""
                rules={{required: (required) ? "req" : false}}
                render={({field:{ onChange, ...rest }}) =>
                    <input style={{display: 'none'}} type="file" 
                        onChange={e =>{
                            if (e.target.files.length){
                                setFile(e.target.files[0])
                                setValue(name2, getFileName(e.target.files[0].name))
                                onExportFile(e.target.files[0])
                                onChange(e)
                            }
                            else{
                                setFile('')
                                onChange(e)
                                setValue(name2, '')
                                onExportFile('')
                            }
                        }} {...rest}
                        ref={fileInput}
                        accept={accept}/>        
                }
                    />
            {error && <Typography variant="error" textAlign='left'>
                {text.required}
                </Typography>}
        </Stack>
    )
}
export default FileInput
