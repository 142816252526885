import React, {useState} from "react";
import { Stack, Typography, Button} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form';
import axiosInstance from "../../axios";
import countriesList from "../../helper/countries";
import useData from "../hooks/useData";

const AddAccountant = () => {

    const { text } = useLanguage()
    const { languagesList, roleList } = useData()
    const [error,setError] = useState('')
    
    const { control, handleSubmit, formState: {errors} } = useForm()
    
    const newAccountant = async({role,mail,password,confirmpassword,name,country,languageName}) =>{
        if(password === confirmpassword)
        try{
            const rep = await axiosInstance.post('/register',{mail,password,role,name,country,languageName})
        }catch(e){setError(e.response)}
        else setError(text.passdiff)
    }
    return (
        <Stack spacing={2} padding="20px 0">
            <FormRow label={text.role} comp="Select" control={control} options={roleList} name="role" error={errors.role} />
            <FormRow label={text.mail} control={control} name="mail" error={errors.mail} />
            <FormRow label={text.agname} control={control} name="name" error={errors.name} />
            <FormRow label={text.pays} comp="Select" options={countriesList} control={control} name="country" error={errors.country} />
            <FormRow label={text.language} comp="Select" options={languagesList} control={control} name="languageName" error={errors.languageName} />
            <FormRow label={text.password} comp="Password" control={control} name="password" error={errors.password} />
            <FormRow label={text.confirmpass} comp="Password" control={control} name="confirmpassword" error={errors.confirmpassword} />
            <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button onClick={handleSubmit(newAccountant)} variant="formrow">
                    <Typography>
                        {text.crtnwacc}
                    </Typography>
                </Button>
            </Stack>
        </Stack>
    )
} 

export default AddAccountant;