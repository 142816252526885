import React, { useContext, useState } from 'react'
import CustomPopup from '../Admin/CustomPopup'
import { Button, DialogActions, Grid, Stack, Typography } from '@mui/material'
import useLanguage from '../hooks/useLanguage'
import FormRow from '../tools/FormRow'
import { useForm } from 'react-hook-form'
import useData from '../hooks/useData'
import axiosInstance from '../../axios'
import { AuthStateContext } from '../state/AuthStateContext'
import CustomAlert from '../Admin/CustomAlert'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const PopAccountant = ({ type, data, open, setOpen, setDatas, setStatement, period }) => {
  const {text} = useLanguage()
  const { typeStatus } = useData()
  const [ loading, setLoading ] = useState()
  const [ alerto, setAlerto ] = useState(false)
  const [ alertContenu, setAlertContenu] = useState("")
  const { control, handleSubmit, formState: { errors }, reset } = useForm()
  const { authState } = useContext(AuthStateContext)
  const dateS = new Date()
  const [date, setDate] = useState(dateS);

  const handelClick = (dataForm) => {
    setLoading(true)
    if( type == 'generate'){
        axiosInstance.post('/invoice', {
            "userId" : data?.id,
            "period" : period
        },{
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            if(response.data.success){
                setDatas((prev) => prev.map((d) => d.id == response.data.data.id ? response.data.data : d))
                setAlertContenu(response.data.message)
                setAlerto(true)
            }
            setLoading(false)
            setOpen(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }else if( type == 'send' ){
        axiosInstance.post('/invoice_send', {
            "userId" : data?.id,
            "period" : period
        },{
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            if(response.data.success){
                setDatas((prev) => prev.map((d) => d.id == response.data.data.id ? response.data.data : d))
                setAlertContenu(response.data.message)
                setAlerto(true)
            }
            setLoading(false)
            setOpen(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }else if( type == 'status' ){
        axiosInstance.post('/invoice_status', {
            "userId" : data?.id,
            "date" : date,
            "period" : period
        },{
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            if(response.data.success){
                setDatas((prev) => prev.map((d) => d.id == response.data.data.id ? response.data.data : d))
                setAlertContenu(response.data.message)
                setAlerto(true)
                setStatement(response.data.invoiceStatement)
            }
            setLoading(false)
            setOpen(false)
            reset()
        })
        .catch((error) => {
            console.log(error)
        })
    }
  }

  return (
    <div>
        <CustomAlert
            open={alerto}
            autoHideDuration={1500}
            onClose={() => setAlerto(false)}
            severity="success"
            message={alertContenu}
        />
        <CustomPopup open={open} onClose={() => setOpen(false)}>
            <div style={{ padding: "10px"}}>
                { type == "generate" ? 
                    <div>
                        <Typography sx={{ fontWeight: "600", fontSize: "24px", marginBottom: "6px"}}>
                            Generate invoice :
                        </Typography>
                        <Stack>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Login:</span> 
                                {data?.login}
                            </Typography>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Full Name:</span> 
                                {data?.fullName}
                            </Typography>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Full Address:</span> 
                                {data?.fullAddress}
                            </Typography>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Country:</span> 
                                {data?.country}
                            </Typography>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Language:</span> 
                                {data?.language}
                            </Typography>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Payment Method:</span> 
                                {data?.paymentMethod}
                            </Typography>
                            <Typography>
                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Total Payment:</span> 
                                {data?.totalPayment} €
                            </Typography>
                            <Stack>
                                <Typography>
                                    <span style={{ fontWeight: "600" }}>Bank details:</span>
                                </Typography>
                                {data?.paymentMethod == "wireTransfer" ? 
                                    <Stack sx={{ marginLeft: "8px"}}>
                                        <Typography>
                                            <span style={{ fontWeight: "600", marginRight: '2px' }}>Name:</span> 
                                            {data?.bankDetails.name}
                                        </Typography>
                                        <Typography>
                                            <span style={{ fontWeight: "600", marginRight: '2px' }}>IBAN:</span> 
                                            {data?.bankDetails.iban}
                                        </Typography>
                                        <Typography>
                                            <span style={{ fontWeight: "600", marginRight: '2px' }}>BIC:</span> 
                                            {data?.bankDetails.bic}
                                        </Typography>
                                    </Stack>
                                    :
                                    <Stack sx={{ marginLeft: "8px"}}>
                                        <Typography>
                                            <span style={{ fontWeight: "600", marginRight: '2px' }}>Email:</span> 
                                            {data?.bankDetails.email}
                                        </Typography>
                                    </Stack>
                                }
                            </Stack>
                        </Stack>
                    </div>
                    :
                    ( type == "send" ? 
                        <div>
                            <Typography sx={{ fontWeight: "600", fontSize: "24px", marginBottom: "6px"}}>
                                Send invoice to {data?.fullName} :
                            </Typography>
                            <Stack>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Generated on:</span> 
                                    {data?.generate.date}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Invoice number:</span> 
                                    {data?.invoiceNumber}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Invoice Period:</span> 
                                    {data?.period}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Login:</span> 
                                    {data?.login}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Full Address:</span> 
                                    {data?.fullAddress}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Country:</span> 
                                    {data?.country}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Language:</span> 
                                    {data?.language}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Payment Method:</span> 
                                    {data?.paymentMethod}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Total Payment:</span> 
                                    {data?.totalPayment} €
                                </Typography>
                                <Stack>
                                    <Typography>
                                        <span style={{ fontWeight: "600" }}>Bank details:</span>
                                    </Typography>
                                    {data?.paymentMethod == "wireTransfer" ? 
                                        <Stack sx={{ marginLeft: "8px"}}>
                                            <Typography>
                                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Name:</span> 
                                                {data?.bankDetails.name}
                                            </Typography>
                                            <Typography>
                                                <span style={{ fontWeight: "600", marginRight: '2px' }}>IBAN:</span> 
                                                {data?.bankDetails.iban}
                                            </Typography>
                                            <Typography>
                                                <span style={{ fontWeight: "600", marginRight: '2px' }}>BIC:</span> 
                                                {data?.bankDetails.bic}
                                            </Typography>
                                        </Stack>
                                        :
                                        <Stack sx={{ marginLeft: "8px"}}>
                                            <Typography>
                                                <span style={{ fontWeight: "600", marginRight: '2px' }}>Email:</span> 
                                                {data?.bankDetails.email}
                                            </Typography>
                                        </Stack>
                                    }
                                </Stack>
                            </Stack>
                        </div>
                        :
                        <div style={{ padding: "100px"}} >
                            <Typography sx={{ fontWeight: "600", fontSize: "24px", marginBottom: "6px"}}>
                                Change the status of payment:
                            </Typography>
                            <Stack sx={{ gap: "8px"}}>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Agent:</span>
                                    {data?.fullName}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Login:</span> 
                                    {data?.login}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "600", marginRight: '2px' }}>Total Payment:</span> 
                                    {data?.totalPayment} €
                                </Typography>
                                <Stack style={{ marginBottom: "12px", width: "300px" }}>
                                    <Grid container gap='15px'>
                                        <Grid item xs={3.5} variant="maroon" style={{ minHeight: '50px' }}>
                                            <Typography variant="white" style={{
                                                height: '100%',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center',fontSize:'1.3em', fontFamily:'Chewy Pro'
                                            }}>
                                                {text.date}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4.5}>
                                            <Stack sx={{ maxWidth: "50px"}}>
                                                <DatePicker
                                                    showIcon
                                                    width="100px"
                                                    popperPlacement="top-end"
                                                    selected={date}
                                                    onChange={(date) => setDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="datepickerW"
                                                />
                                            </Stack>

                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </div>
                    )
                }
            </div>
            <DialogActions>
                <div style={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px"}}>
                    <Button
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => setOpen(false)}
                    >
                        {text.annuler}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }}
                        onClick={handleSubmit(handelClick)}
                    >
                        {loading ? text.phload : text.cnfrm}
                    </Button>
                </div>
            </DialogActions>
        </CustomPopup>
    </div>
  )
}

export default PopAccountant