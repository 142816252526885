import React, { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "../state/AuthStateContext";
import { Stack } from "@mui/material";
import Header1 from "../Header/Header1";
import useLanguage from "../hooks/useLanguage";
import useMessage from "../hooks/useMessage";
import Pusher from 'pusher-js'
import { MessageContext } from "../state/MessageContext.jsx";
import { PUSHER_SECRET, CLUSTER, apiURL } from "../../helper/conf.js";
import { Discussion, TextSC } from "../tools/Snackbars.jsx";
import useAuth from "../hooks/useAuth.jsx";
import Statistics from "./Statistics.jsx";
import incoming from "../../assets/audio/incoming.mp3"
import { GifsContext } from "../state/gifContext.jsx";

const Operator = () => {
    const { text } = useLanguage()
    const { logout } = useAuth()
    const { authState } = useContext(AuthStateContext)
    const { setChan1,setImage,setMessages, setNotifs, messageState, setOnHoldCounts } = useContext(MessageContext)
    const { getData, gifList } = useMessage()
    const [see, setSee] = useState(false)
    const { setGifs } = useContext(GifsContext)

    useEffect(() => {
        console.log(authState.token)
        const pusher = new Pusher(PUSHER_SECRET, { cluster: CLUSTER, channelAuthorization: { endpoint: `${apiURL}/pusher/user-auth`, transport: 'jsonp' } })
        const chan = pusher.subscribe('private-' + authState.userId.toString())
        // const chan1 = pusher.subscribe('private-noteEvent')
        setChan1(chan)
        
        chan.bind('Recuperation_Donnee', (data) => {
            console.log('Donnee : ', data)
            setMessages(data)
            const audio = new Audio(incoming)
            audio.play()
            setOnHoldCounts({type:true,data:data.nbrWait ? data.nbrWait : 0})
        })
        chan.bind('setDisconnect', data => {
            if (messageState) setMessages(null)
            logout()
        })
        chan.bind('onHold', data => {
            getData()
        })
        // chan.bind('Add_Notif', (data) => {
        //     setNotifs(p => {return { data: [...p.data, data], nbrChecked: p.nbrChecked + 1 }})
        // })
        chan.bind('countOnHoldEvent', (data) => {
            console.log('hold count : ', data)
            setOnHoldCounts({type:false,data:data.count})
        })
        chan.bind(`photo_request-${authState.userId}`, (data) => {
            console.log('photo-reponse', data)
            setImage((last) => [...last, ...data])
        })
        gifList()
        if (!messageState) getData()
        return () => {
            chan.unbind()
            // chan1.unbind()
            pusher.unsubscribe('private-' + authState.userId.toString())
        }
    }, [])

    // PUSHER GIFS LIST
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-addGif")
        channel.bind(`${authState?.userId}`, (data) => {
            setGifs((prev) => [ ...prev, data])
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-addGif")
        }
    },[])

    // PUSHER GIFS DELETE
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-deleteGif")
        channel.bind(`${authState?.userId}`, (data) => {
            console.log(data)
            setGifs((prev) => prev.filter((d) => d.id != data.id))
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-deleteGif")
        }
    },[])

    return (
        <Stack style={{}}>
        {see && <Statistics setSee={setSee} />}
            <Header1 setSee={setSee} />
            <Stack>
                <Stack style={{ width: '100%', backgroundColor: '#fff', position: 'fixed', zIndex: 30 }}>
                    <Stack style={{ zIndex: 30, width: '100%', height: '1.5em', backgroundColor:'#fff' }}></Stack>
                    <Stack flexDirection="row" justifyContent="center" alignItems="center" style={{ width: '100%', zIndex: 30,background:'#fff' }}>
                        <Stack style={{width:'80%',position:'relative',justifyContent:'center'}}>
                            <Stack style={{width:'6em',height:'6em',justifyContent:'center',position:'absolute',left:'-1.2em'}}>
                                <span style={{position:'absolute',width:'100%',height:'100%',border:'4px solid #cd20c2',borderRadius:'50%',zIndex:10,left:'-4px',boxShadow:'0 0 0 3px #fff,0 0 10px 10px #cd20c2'}}></span>
                                <img src="/images/operator/5.png" alt="" style={{ position:'absolute',width:'6.1em',zIndex:9}}/>
                            </Stack>
                            <Stack style={{height:'5.2em',width:'100%',position:'relative',justifyContent:'center',placeItems:'center'}}>
                                <img src="/images/operator/5.1.png" alt="" style={{position:'absolute',height:'5em',width:'100%'}}/>
                                <Stack style={{zIndex:10,gap:1,width:'80%',color:'#dbdbdb',fontWeight:'bold',justifyContent:'center',placeItems:'center'}}><TextSC/></Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                {!messageState ? <Stack width='100%' style={{height:'30em'}} sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack sx={{ fontWeight: 'bold', fontSize: '3.5em', color: 'teal' }}>{text.attdisc}</Stack>
                </Stack> : <Discussion messageState={messageState}/>
                }
            </Stack>
        </Stack>
    )
}

export default Operator;