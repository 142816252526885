import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BigContainerAccordion = ({summary, summaryBg='#fff', details, level=1, transparent=false}) =>{
    const getMinWidth = () =>{
        switch (level){
            case 2: return '20%'
            case 3: return '17.5%'
            case 4: return '15%'
            default: return '22.5%'
        }
    }
    const minWidth = getMinWidth()
    return(
        <Accordion variant="big-container" style={{boxShadow: 'none', width: '100%', backgroundColor:  transparent ? "transparent" : undefined}}>
            <AccordionSummary style={{background: summaryBg, width: 'max-content', minWidth: minWidth, left: '50%', transform: 'translateX(-50%)', marginBottom: '20px'}} expandIcon={<ArrowDropDownIcon style={{color: '#fff'}}/>}>
                <Typography variant="white" width='100%' textAlign='center'>{ summary }</Typography>
            </AccordionSummary> 
            <AccordionDetails variant="big-container" style={{boxShadow: 'none'}}>
                { details }
            </AccordionDetails>
        </Accordion>
    )
}

export default BigContainerAccordion