import { Stack, Typography, Divider} from "@mui/material";
import React from "react";
import useLanguage from "../hooks/useLanguage";
import BasicHeader from "../Header/BasicHeader";

const PrivacyPolicy = () => {
    const { text } = useLanguage()
    return(
        <Stack spacing={3} padding="20px" variant="maroongradientbg">
            <BasicHeader />
            <Stack alignItems="center" spacing={3} padding="10px">
                <Typography variant="h1" style={{textTransform: 'capitalize'}}>{text.pp}</Typography>
                <Divider variant="primarybold" width='22%'/>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp1}</Typography>
                <Stack spacing={2}>
                    <Typography>{text.pp11}</Typography>
                    <Typography>{text.pp12}</Typography>
                    <Typography>{text.pp13}</Typography>
                    <Typography>{text.pp14}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp2}</Typography>
                <Stack spacing={1}>
                    <Typography>{text.pp21}</Typography>
                    <Typography>{text.pp22}</Typography>
                    <Typography>{text.pp23}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp3}</Typography>
                <Stack>
                    <Typography>{text.pp31}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp4}</Typography>
                <Stack>
                    <Typography>{text.pp41}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp5}</Typography>
                <Stack>
                    <Typography>{text.pp51}</Typography>
                    <Typography>{text.pp52}</Typography>
                    <Typography>{text.pp53}</Typography>
                    <Typography>{text.pp54}</Typography>
                    <Typography>{text.pp55}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp6}</Typography>
                <Stack>
                    <Typography>{text.pp61}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp7}</Typography>
                <Stack>
                    <Typography>{text.pp71}</Typography>
                    <Typography>{text.pp72}</Typography>
                    <Typography>{text.pp73}</Typography>
                    <Typography>{text.pp74}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp8}</Typography>
                <Stack>
                    <Typography>{text.pp81}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={4}>
                <Typography variant="pfsbold">{text.pp9}</Typography>
                <Stack>
                    <Typography>{text.pp91}</Typography>
                </Stack>
            </Stack>
            <Typography paddingTop="20px" textAlign="center" >{text.copyright}</Typography>
        </Stack>
    )
}

export default PrivacyPolicy