import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../axios';
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";
import useLanguage from '../hooks/useLanguage';

const PokeDisplayMessage = ({data, type}) => {
    const { text } = useLanguage()

    return (
        <Box sx={{ width: '100%', minHeight: '200px'}}>
            <Grid container width='100%' sx={{ backgroundColor: '#1e1e1e', }} >
                <Grid item sx={{ flex: 1, padding: '8px' }} >
                    N :
                </Grid>
                <Grid item sx={{ flex: 2, padding: '8px' }} >
                    {text.recep} :
                </Grid>
                <Grid item sx={{ flex: 2, padding: '8px' }} >
                    {text.exp} :
                </Grid>
                <Grid item sx={{ flex: 2, padding: '8px' }} >
                    {text.dtofdlvr} :
                </Grid>
                <Grid item sx={{ flex: 4, padding: '8px' }} >
                    {type == "stop" ? text.rsptostp : ( type == "accroche" ? text.rsptostp2 : text.rsptostp3 )} :
                </Grid>
                <Grid item sx={{ flex: 4, padding: '8px' }} >
                    {text.respVp} :
                </Grid>
            </Grid>

            <Stack>
                {data?.map((d, key)=>{
                    return(
                        <Grid key={key} container width='100%' sx={{ borderBottom: "1px solid #1e1e1e", ":hover": {  bgcolor: "#0a0a0a" } }} >
                            <Grid item sx={{ flex: 1, padding: '8px' }} >
                                {key+1}
                            </Grid>
                            <Grid item sx={{ flex: 2, padding: '8px' }} >
                                {d.vprofil}
                            </Grid>
                            <Grid item sx={{ flex: 2, padding: '8px' }} >
                                {d.fprofil}
                            </Grid>
                            <Grid item sx={{ flex: 2, padding: '8px' }} >
                                {d.date}
                            </Grid>
                            <Grid item sx={{ flex: 4, padding: '8px' }} >
                                {d.response.length > 80 ? `${d.response.substring(0, 80)} ...` : (d.response == "pas encore." ? text.pasEn : d.response)}
                            </Grid>
                            <Grid item sx={{ flex: 4, padding: '8px' }} >
                                {d.réresponse.length > 80 ? `${d.réresponse.substring(0, 80)} ...` : (d.réresponse == "pas encore." ? text.pasEn : d.réresponse)}
                            </Grid>
                        </Grid>
                    )
                })}
            </Stack>
        </Box>
    )
}

export default PokeDisplayMessage