import React, { useEffect, useState, useRef } from "react";
import { Stack, Typography } from "@mui/material";

const SlidingTypography = ({children, variant="", textAlign="flex-start", fast=false, color='#000', delm=false, isKid="false"}) =>{
    const [typographyClassName, setTypographyClassName] = useState('')
    const [typographyWidth, setTypographyWidth] = useState(0);

    const stack = useRef(null)
    const typography = useRef(null)
    useEffect(() => {
        const checkOverflow = () => {
            const w = typography.current.offsetWidth
            setTypographyWidth(w)
            setTypographyClassName(
                (w >stack.current.offsetWidth) ? 'sliding-text' : ''
            )
        }
        checkOverflow()
        window.addEventListener("resize", checkOverflow)
        return () => window.removeEventListener("resize", checkOverflow)
    }, [])

    return(
        <Stack 
            ref={stack} 
            style={{ 
                width: '100%', 
                overflow: 'hidden',  
                minHeight: isKid == "true" ? "15px" : "26px", 
                position: 'relative',
                alignItems: textAlign 
            }}
        >
            <Typography 
                variant={variant} 
                ref={typography} 
                className={typographyClassName} 
                style={{
                    whiteSpace: 'nowrap', 
                    position: 'absolute', 
                    top: '50%', 
                    transform: 'translateY(-50%)', 
                    overflow: 'hidden', 
                    color: color,
                    fontFamily: delm ? 'Delm' : 'sans serif',
                    '--width': `${typographyWidth}px`, 
                    '--anim-duration': `${fast ? '3' : '7'}s`
                }}
            >
                {children}
            </Typography>
        </Stack>
    )
}

export default SlidingTypography