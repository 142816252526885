import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material'
import React, { useContext, useState } from 'react'
import axiosInstance from '../../axios'
import { AuthStateContext } from '../state/AuthStateContext'
import useLanguage from '../hooks/useLanguage'

const Popup = ({handleCl, setOpenAlert, convId}) => {
    const { authState } = useContext(AuthStateContext)
    const [ typePhoto, setTypePhoto ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const { text } = useLanguage()

    const submit = () => {
        setLoading(true)
        axiosInstance.post("/photoRequest/add", {
            "convId": convId,
            "type": typePhoto,
            "userId": authState?.userId,
        }, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.message)
            setTypePhoto("")
            setLoading(false)
            handleCl()
            setOpenAlert(1)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <div style={{ padding: "18px 30px"}}>
            <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "800", fontSize: "32px"}}>
                {text.phtitre} :
            </DialogTitle>
            <DialogContent>
            <Box>
                <Stack sx={{ mb: "8px", fontWeight: "700"}}>
                    {text.phlabel} :
                </Stack>
                <input 
                    value={typePhoto}
                    onChange={(e) => setTypePhoto(e.target.value)}
                    type="text" 
                    style={{ padding: "16px", width: "350px", fontSize: "20px", borderRadius: "10px"}} 
                />
            </Box>
            </DialogContent>
            <DialogActions>
            <Button variant='contained' sx={{ fontWeight: "700"}} onClick={submit}>
                { loading ? text.phload : text.phsend }
            </Button>
            </DialogActions>
        </div>
    )
}

export default Popup