import React, {useContext} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";

export default ({onDetails}) => {
    const { text } = useLanguage()
    const { stat } = useContext(StatContext);
    const platforms = Object.keys(stat?.photorequests ?? {});

    const StatPhotoTab = (platform) => {
        return (
            <TableRow columns={[
                
                { xs: 3.8,
                    customContent: true,
                    content:
                        <Typography>
                            {platform.charAt(0).toUpperCase() + platform.slice(1)}
                        </Typography>

                    
                },
                { xs: 3.8,
                    customContent: true,
                    content: 
                        <Typography  key={platform}>
                            {stat.photorequests[platform]?.requested}
                        </Typography>
                },
                { xs: 3.8,
                    customContent: true,
                    content: 
                        <Typography key={platform}>
                            {stat.photorequests[platform]?.sent}
                        </Typography>
                },
            ]}/>
        );
    };
    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.pr}</Title>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 3.8,  text: text.platform},
                { xs: 3.8,  text: text.dmndees},
                { xs: 3.8,  text: text.envoyees},
            ]}/>
            {platforms.map((platform) => (<Stack key={platform}>{StatPhotoTab(platform)}</Stack>))}
        </Stack>
        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

