import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary, IconButton, DialogTitle, DialogActions, Button, DialogContent, Select, TextField, Input, Badge, styled} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../../hooks/useLanguage";
import TabOtherInfo3 from "./TabOtherInfo3";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useData from "../../hooks/useData";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomAlert from "../../Admin/CustomAlert";
import { AuthStateContext } from "../../state/AuthStateContext";
import CustomPopup from "../../Admin/CustomPopup";
import axiosInstance from "../../../axios.js";
import { TextFields } from "@mui/icons-material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useForm } from "react-hook-form";
import AuthFormRow from "../../tools/AuthFormRow.jsx";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MessageContext } from "../../state/MessageContext.jsx";
import FormRow from "../../tools/FormRow.jsx";

const YellowNotificationsIcon = styled(NotificationsIcon)(({ theme }) => ({
    color: '#FFAA00',
}));

const NotPermanent = ({type, info}) =>{
    const { authState } = useContext(AuthStateContext)
    const {messageState} = useContext(MessageContext)
    const { text } = useLanguage()

    const [ loading, setLoading ] = useState()
    const [ alert, setAlert ] = useState()
    const [ alertContenu, setAlertContenu ] = useState()
    const [ notPermanents, setNotPermanents] = useState()

    useEffect(() => {
        if (type === "fake_profil") {
            setNotPermanents(info?.fpNotPermanent);
        } else {
            setNotPermanents(info?.vpNotPermantent);
        }
    }, [type, info]);

    // ADD NOT PERMANENT
    const [ openAdd, setOpenAdd ] = useState(null)
    // DELETE NOT PERMANENT
    const [ notpermanantToDelete, setNotPermanantToDelete ] = useState()
    const [ openDelete, setOpenDelete ] = useState(false)
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/information/delete/${notpermanantToDelete?.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data)
            setAlertContenu(text.delalert)
            setAlert(true)
            setNotPermanents((last)=>last.filter((a)=>a.id !== notpermanantToDelete?.id))
            setLoading(false)
            setOpenDelete(false)
        })
        .catch((error)=>{
            console.log(error)
            setLoading(false)
        })
    }
    const PermanentC = () => {
        const { control,handleSubmit,formState: { errors },reset } = useForm()
        
        const handleAdd = (data) => {
            setLoading(true)
            const datas = openAdd.type ? {id:openAdd.obj.id,nature:data.nature,content:data.contenu}:{
                "permanent": 0,
                "content": data.contenu,
                "nature": data.nature,
                "conv": messageState.convId,
                "isVpInformation": type === "fake_profil" ? 0 : 1,
            }
            axiosInstance.post(openAdd.type ? '/Information/Edit':"/information/add", datas,{
                headers: {
                    'Authorization': `Bearer ${authState.token}`
                }
            })
            .then((response)=>{
                console.log(response.data.message)
                setAlertContenu(text.addd)
                setAlert(true)
                const info = openAdd.type ? notPermanents.map(p => p.id === openAdd.obj.id ? {...p,nature:data.nature,content:data.contenu}:p):[...notPermanents, response.data.data]
                setNotPermanents(info)
                setLoading(false)
                setOpenAdd(null)
                reset()
            })
            .catch((error)=>{
                console.log(error)
                setLoading(false)
            })
        }
        return(
            <div style={{ padding: "18px 30px"}}>
                        <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                            <p style={{ fontWeight: "800", fontSize: "22px"}}>
                                {text.addperminfo}:
                            </p>
                        </DialogTitle>
    
                        {openAdd && <DialogContent>
                            <div style={{ marginBottom: "12px"}}>
                                <FormRow
                                    defaultValue={openAdd.type ? openAdd.obj.nature:''}
                                    txtCol="#fff"
                                    bgCol="#d48b70"
                                    label={text.nat}
                                    name='nature' 
                                    control={control} 
                                    error={errors.name}
                                />
                            </div>
                            <div style={{ marginBottom: "12px"}}>
                            <FormRow
                                    defaultValue={openAdd.type ? openAdd.obj.content:''}
                                    txtCol="#fff"
                                    bgCol="#d48b70"
                                    label={text.contenu} 
                                    name='contenu' 
                                    control={control} 
                                    error={errors.name}
                                />
                            </div>
                        </DialogContent>}
                        <DialogActions>
                            <Button
                                variant='contained' 
                                sx={{ 
                                    fontWeight: "700", 
                                    bgcolor: "error.main",
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "error.dark",
                                    } 
                                }} 
                                onClick={() => setOpenAdd(null)}
                            >
                                {text.annuler}
                            </Button>
                            <Button 
                                variant='contained' 
                                sx={{ 
                                    fontWeight: "700", 
                                    bgcolor: "success.main", 
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "success.dark",
                                    } 
                                }} 
                                onClick={handleSubmit(handleAdd)}
                            >
                                {loading ? text.phload : text.cnfrm}
                            </Button>
                        </DialogActions>
                    </div>
        )
    }

    return(
        <Box padding=" 10px 00px">
            <CustomAlert
                    open={alert}
                    autoHideDuration={1500}
                    onClose={() => setAlert(false)}
                    severity="success"
                    message={alertContenu}
            />

            {/* pop up add */}
            <CustomPopup open={openAdd ? true:false} onClose={() => setOpenAdd(null)}>
                <PermanentC/>
            </CustomPopup>

            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.dltinfo}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.idd} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {notpermanantToDelete?.id}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.contenu} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {notpermanantToDelete?.content}
                                </Stack>
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>

            <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>    
                
                <AccordionSummary style={{minHeight: '20px',backgroundColor: "#cf28c4", height: "30px", padding: '0', marginBottom: "4px"}} >
                    <Typography variant="white" style={{  textAlign: 'center', width: "85%"}}>
                        {text.otherimp3}
                    </Typography>
                    <Stack>
                        <Badge badgeContent={notPermanents?.length} color="secondary" showZero>
                            <YellowNotificationsIcon  style={{color:'#f9e238'}}/>
                        </Badge>
                    </Stack>
                </AccordionSummary>
            

                <AccordionDetails style={{ padding: '0'}}>
                    <Stack spacing={'2px'}>
                        <TabOtherInfo3 header columns={[
                            { xs: 2,  text: text.nat,color:'#fff'},
                            { xs: 8,  text: text.infs,color:'#fff'},
                            { xs: 1.8,  text: text.actions,color:'#fff'},
                        ]}/>
                        {
                            notPermanents?.map((npm, i) =>
                                <TabOtherInfo3 mt={npm} row={i} key={'npm' + i} columns={[
                                    { xs: 2,  text: npm.nature},
                                    { xs: 8,  text: npm.content},
                                    {
                                        xs: 1.8,
                                        customContent: true,
                                        content: <Stack style={{flexDirection:'row',gap:2}}>
                                        <Stack onClick={()=> setOpenAdd({type:true,obj:npm})}>
                                            <BorderColorIcon fontSize="small" style={{color:'#82c8f9'}} />
                                        </Stack>
                                        <Stack onClick={() => { setNotPermanantToDelete(npm); setOpenDelete(true) }}>
                                            <HighlightOffIcon fontSize="small" style={{color:'#ff7b7b'}}  />
                                        </Stack>
                                    </Stack>
                                        // <IconButton 
                                        //     onClick= {() => {setNotPermanantToDelete(npm); setOpenDelete(true)}}
                                        // >
                                        //     <HighlightOffIcon fontSize="small" />
                                        // </IconButton>
                                    },
                                    
                                ]}/>
                            )
                        }
                    </Stack>
                    <Stack display='flex' alignItems="flex-end">
                        <IconButton 
                            style={{width: "fit-content"}} 
                            onClick= {() => setOpenAdd({type:false})}
                        >
                            <AddCircleIcon style={{color:'#45d6e5'}} />
                        </IconButton>
                    </Stack>
                </AccordionDetails>
            </Accordion>

        </Box>
    )
}

export default NotPermanent