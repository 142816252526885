import { Stack, Typography, Button, DialogTitle, DialogActions, DialogContent, Pagination } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import countriesList from "../../helper/countries";
import useList from "../hooks/useList";
import AuthFormRow from "../tools/AuthFormRow";
import axiosInstance from "../../axios";
import CustomAlert from "../Admin/CustomAlert";
import { format } from "date-fns";
import { AuthStateContext } from "../state/AuthStateContext";
import CustomPopup from "../Admin/CustomPopup";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import { formatDate, formatDate2 } from "../../helper/formatDate";
import { roles } from "../../helper/roles";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const Agents = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const { languagesList, roleList, languagesgifList, paymentList } = useData()
    const {agentList, setAgentList, platformsPerf, agentsPerf, getOperatorList, getPlatformsPerformance, getAgentsPerformance} = useList()
    const [error,setError] = useState('')
    const [ppError, setPPError] = useState(false)
    const [pp, setPP] = useState(false)
    const [ alerto, setAlerto ] = useState(false)
    const { authState } = useContext(AuthStateContext)
    const [popContenu, setPopContenu] = useState()
    const [ loading, setLoading ] = useState(false)
    const success = text.scc

    useEffect(() =>{
        getPlatformsPerformance()
        getAgentsPerformance()
    },[])
    
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
    }))

    const [filter2, setFilter2] = useState(() => ({
        ...globalFilter,
        payment: ''
    }))

    const { control, reset, handleSubmit, getValues, formState: {errors} } = useForm()

    const [ selectedPayment, setSelectedPayment ] = useState()
    useEffect(() => { 
        setSelectedPayment(getValues("paymentName")) 
    }, [getValues("paymentName")])

	const newAgent = async({role,mail,password,login,confirmpassword,name, fullName, fullAddress, country,languageName, paymentName, bankname, iban, bic, paymail}) =>{
        if(error){
            setError('')
            setPPError(!pp)
        }
        if(password === confirmpassword){
            try{
                const data = await axiosInstance.post('/register', {
                    mail,
                    password,
                    login,
                    role,
                    name,
                    country,
                    languageName, 
                    paymentName, 
                    bankname, 
                    iban, 
                    bic, 
                    paymail,
                    fullName,
                    fullAddress,
                })
                console.log(data)
                setPopContenu(success)
                setAlerto(true)
                reset()
            }catch(e){
                console.log(e)
                setError(e.response.data.errors[0])
            }
        }else{
            setError(text.passdiff)
        }
	}

    // LIST OPERATOR
    const [ page, setPage] = useState(1)
    const [ pagination, setPagination ] = useState()
    const [ agents, setAgents ] = useState()
    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        axiosInstance.get(`/user/${roles.operator}?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&agents=${filter?.agents}&page=${page}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response) => {
            setAgents(response.data.data)
            setPagination(response.data.pagination)
        })
        .catch((e) => 
            console.log(e)
        )
    }, [page, filter])

    //UPDATE OPERATOR
    const [ openUpdate, setOpenUpdate ] = useState(false)
    const [ opToUpdate, setOpToUpdate ] = useState()
    const [ newPassword, setNewPassword] = useState("")
    const handleUpdate = () => {
        setLoading(true)
        axiosInstance.put(`/operator/${opToUpdate?.id}`,{
            "newPassword": newPassword,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setPopContenu(text.mdpupdtd)
            setAlerto(true)
            setLoading(false)
            setOpenUpdate(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    //SUSPEND OPERATOR
    const [ openSuspend, setOpenSuspend ] = useState(false)
    const [ opToSuspend, setOpToSuspend ] = useState()
    const handleSuspend = () => {
        console.log(authState.token)
        setLoading(true)
        axiosInstance.post(`/operator/suspend`,{
            "id": opToSuspend?.id,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setPopContenu(text.opsusp)
            setAlerto(true)
            setLoading(false)
            setOpenSuspend(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    //KICK OFF OPERATOR
    const [ openDelete, setOpenDelete ] = useState(false)
    const [ opToDelete, setOpToDelete ] = useState()
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/kickOff_operator/${opToDelete?.id}`,{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setPopContenu(text.delalert)
            setAlerto(true)
            setLoading(false)
            getOperatorList()
            setOpenDelete(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    // DECONNECT OPERATEURS
    const [ openDeconnect, setOpenDeconnect] = useState(false)
    const [ opToDec, setOpToDec ] = useState()
    const deconnectAgent = async(agent,index) => {
        console.log(opToDec?.id)
        setLoading(true)
        await axiosInstance.put('/AgentDeconnection', {
            id: opToDec?.id
        },{ 
            headers: { 
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then(rep => {
            console.log('deconnection : ', opToDec?.id)
            setPopContenu(text.opdec)
            setAlerto(true)
            setLoading(false)
            setAgents((last)=> last.map((p) => p.id == opToDec.id ? {...p, status: 'disconnect'} : { ...p } ))
            setOpenDeconnect(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }

    //PUSHER STATUT OPERATOR
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe('private-conversation')
        channel.bind('status', (data)=> {
            console.log(data.status)
            setAgents((last)=>last.map((a)=> a.id == data.id ? { ...a, status: data.status } : a))
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe('private-conversation')
        }
    },[])

    // LIST AGENT PERFORMANCE
    const [ datas, setDatas ] = useState()
    const [ pagination2, setPagination2 ] = useState()
    const [page2, setPage2] = useState(1);

    const handleChange2 = (event, value) => {
        setPage2(value);
    };

    useEffect(()=>{
        axiosInstance.get(`/agentRateStat?year=${filter2?.year}&month=${filter2?.month}&date=${formatDate2(filter2?.date)}&platform=${filter2?.platforms}&week=${filter2?.week}&startingDate=${formatDate2(filter2?.startingDate)}&closingDate=${formatDate2(filter2?.closingDate)}&page=${page2}&payment=${filter2?.payment}&agent=${filter2?.agents}&language=${filter2?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setDatas(response.data.data)
            setPagination2(response.data.pagination)
        })
        .catch((error) => {
            console.log(error)
        })
    },[filter2, page2])

    return(
        <Stack padding="2% 0%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={popContenu}
            />
            {/* pop up deconnect */}
            <CustomPopup open={openDeconnect} onClose={() => setOpenDeconnect(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.decop} {opToDec?.name} ?
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDeconnect(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> deconnectAgent()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.frop} {opToDelete?.name} ?
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up update */}
            <CustomPopup open={openUpdate} onClose={() => setOpenUpdate(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.chngmdp} : {opToUpdate?.name}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.nwmdp}:
                            </Stack>
                            <input
                                style={{fontSize: "22px", fontWeight: "500", color: "#333333", padding: '10px', width: '100%'}}
                                onChange={(e)=>setNewPassword(e.target.value)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenUpdate(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleUpdate()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up suspendre */}
            <CustomPopup open={openSuspend} onClose={() => setOpenSuspend(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.suspop} {opToSuspend?.name} ?
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenSuspend(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleSuspend()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#cb6ce6', WebkitTextStroke: '2px #5e0a76'}}
                >{text.agents}</Typography>
                <img className="card-img-resize-2" src={"/images/supervisor/2age.png"} alt="" height='50px' width='50px'/>
            </Stack> */}
            <Title>{text.agman}</Title>
            <BigContainerAccordion
                transparent
                summaryBg="#004aad"
                summary={text.aglist}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.agents , comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                authState?.userRole === "administrator" && {label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                            ]}
                        />

                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 2.22,  text: text.date},
                                { xs: 1.22,  text: text.agname},
                                { xs: 1.22,  text: text.agcountry},
                                { xs: 1.44,  text: text.aglogin},
                                { xs: 1.22,  text: text.agloginpw},
                                { xs: 1.22,  text: text.langpf},
                                { xs: 1.22,  text: text.status},
                                { xs: 1.22,  text: text.actions},
                            ]}/>
                            {
                                agents?.map((a, i) =>
                                    <TableRow key={'n' + i} columns={[
                                        { xs: 2.22,  text: a.date_inscription ? format(new Date(a.date_inscription), 'dd/MM/yyyy') : ''},
                                        { xs: 1.22,  text: a.name},
                                        { xs: 1.22,  text: a.country},
                                        { xs: 1.44,  text: a.mail},
                                        {
                                            xs: 1.22,
                                            customContent: true,
                                            content: <Button 
                                                onClick={()=>{ setOpToUpdate(a); setOpenUpdate(true)} }
                                                style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                            >
                                                <Typography style={{fontFamily: 'Delm'}}>
                                                    {text.edit}
                                                </Typography>
                                            </Button>
                                        },
                                        { xs: 1.22,  text: a.language?.name},
                                        { xs: 1.22,  text: a.status},
                                        {
                                            xs: 1.22,
                                            customContent: true,
                                            content: <Stack>
                                                <Button 
                                                    onClick={()=> {setOpToDec(a); setOpenDeconnect(true);}}
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.deconnecter}
                                                    </Typography>
                                                </Button>
                                                <Button 
                                                    onClick={()=>{ setOpToSuspend(a); setOpenSuspend(true)} }
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.susp}
                                                    </Typography>
                                                </Button>
                                                <Button 
                                                    onClick={()=>{ setOpToDelete(a); setOpenDelete(true)} }
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.koff}
                                                    </Typography>
                                                </Button>
                                            </Stack> 
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination 
                                page={page}
                                onChange={handleChange}
                                count={pagination?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#de5b6d"
                summary={text.pfsperf}
                details={
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: 1.6,  text: text.date},
                            { xs: 1.6,  text: text.platform},
                            { xs: 1.6, text: text.language },
                            { xs: 1.6,  text: text.agnumbewoon},
                            { xs: 1.6,  text: text.totsenmess},
                            { xs: 1.6,  text: text.totrecmess},
                            { xs: 1.6,  text: text.totrep},
                        ]}/>
                        {
                            platformsPerf.map((p, i) =>
                                <TableRow key={'p' + i} columns={[
                                    { xs: 1.6,  text: p.date},
                                    { xs: 1.6,  text: p.platform},
                                    { xs: 1.6, text: p.language ? p.language.charAt(0).toUpperCase() + p.language.charAt(1) : 'Fr' },
                                    { xs: 1.6,  text: p.numberOfFakeProfil},
                                    { xs: 1.6,  text: p.numberOfMessageSent},
                                    { xs: 1.6,  text: p.numberOfMessageReceived},
                                    { xs: 1.6,  text: p.numberOfReport},
                                ]}/>
                            )
                        }
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#ff3131"
                summary={text.agperf}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                            }}
                            items= {[
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter2({...filter2, platforms: e.target.value}) },                    
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter2({ ...filter2, language: e.target.value }) },
                                { label: text.agent, comp: 'TextInput', onChange: e => setFilter2({...filter2, agents: e.target.value}) },
                                // { label: text.paymeth, comp: 'Select', choices: paymentList, onChange: e => setFilter2({...filter2, payment: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.4,  text: text.agentid},
                                { xs: 1.4,  text: text.login},
                                { xs: 1.4,  text: text.language},
                                { xs: 1.4,  text: text.platform},
                                { xs: 1.4,  text: text.totsenmess},
                                { xs: 1.4,  text: "Total poke messages"},
                                { xs: 1.4,  text: "Total teaser messages"},
                                { xs: 1.4,  text: "Total stop messages"},
                                // { xs: 0.9,  text: "Total paid messages"},
                                // { xs: 0.9,  text: text.bonus},
                                // { xs: 0.9,  text: "Total payment"},
                                // { xs: 0.9,  text: text.paymeth},
                            ]}/>
                            { datas?.map((data, i) => (
                                <TableRow
                                    key={i}
                                    columns={[
                                        { xs: 1.4, text: data.name },
                                        { xs: 1.4, text: data.login },
                                        { xs: 1.4, text: data.language },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.platform}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.sentMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.pokeMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.teaserMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.stopMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        // { xs: 0.9, text: `${data.totalPaid} €` },
                                        // { xs: 0.9, text: `${data.bonus } €`},
                                        // { xs: 0.9, text: `${data.totalPayment} €` },
                                        // { xs: 0.9, text: data.paymentMethod },
                                    ]}
                                />
                            ))}
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={page2}
                                onChange={handleChange2}
                                count={pagination2?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#1cb743"
                summary={text.nwag}
                details={
                    <Stack spacing={2}>
                        <Typography alignSelf="center" sx={{ color: "red"}}>{error}</Typography>
                        <FormRow label={text.role} comp="Select" control={control} options={roleList} name="role" error={errors.role} />
                        <FormRow label={text.lgn} control={control} name="login" error={errors.login} />
                        <FormRow label={text.mail} control={control} name="mail" error={errors.mail} />
                        <FormRow label={text.agname} control={control} name="name" error={errors.name} />
                        <FormRow label="Full Name" control={control} name="fullName" error={errors.name} />
                        <FormRow label="Full Address" control={control} name="fullAddress" error={errors.name} />
                        <FormRow label={text.pays} comp="Select" options={countriesList} control={control} name="country" error={errors.country} />
                        <FormRow label={text.language} comp="Select" options={languagesgifList} control={control} name="languageName" error={errors.languageName} />
                        <FormRow label= {text.paymeth} comp="Select" options={paymentList} control={control} name="paymentName" error={errors.paymentName} />
                        {
                            (selectedPayment==paymentList[0].value) ?
                                <>
                                    <FormRow label= {text.bankn} control={control} name="bankname" error={errors.bankname} />
                                    <FormRow label= {text.iban} control={control} name="iban" error={errors.iban} />
                                    <FormRow label= {text.bic} control={control} name="bic" error={errors.bic} />
                                </>
                            :<FormRow label= {text.paymail} control={control} name="paymail" error={errors.paymail} />
                        }
                        <FormRow label={text.password} comp="Password" control={control} name="password" error={errors.password} />
                        <FormRow label={text.confirmpass} comp="Password" control={control} name="confirmpassword" error={errors.confirmpassword} />
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(newAgent)} variant="formrow">
                                <Typography>
                                    {text.crnwag}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Agents