import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React from "react";
import useLanguage from "../../hooks/useLanguage";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Meetings from "./Meetings";
import Permanent from "./Permanent";
import NotPermanent from "./NotPermanent";
import Esquive from "./Esquive";

const OtherInfo = ({ type, info, meet}) =>{
    const { text } = useLanguage()

    return(
        <Box> 
            {/* <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>    
                <AccordionSummary style={{cursor: "pointer", width: '100%',backgroundColor: '#1cb743'}} >
                    <Typography variant="white" style={{ textAlign: 'center', width:'100%'}}>{text.otherimp}</Typography>
                </AccordionSummary>
                <ArrowDropDownIcon style={{color: '#000', width:'100%' }}/>
                <AccordionDetails style={{cursor: "pointer", width: '100%', padding: '0'}}>
                    <Meetings />
                </AccordionDetails>
                <AccordionDetails style={{cursor: "pointer", width: '100%', padding: '0'}}>
                    <Permanent />
                </AccordionDetails>
                <AccordionDetails style={{ cursor: "pointer", width: '100%', padding: '0'}}>
                    <NotPermanent />
                </AccordionDetails>
            </Accordion>   */}
            <Stack style={{ width: '100%',backgroundColor: '#1cb743', padding: "10px 0", display: "flex", justifyContent: "center"}}>
                <Typography variant="white" style={{ textAlign: 'center', width:'100%'}}>
                    {text.otherimp}
                </Typography>
            </Stack>
            <ArrowDropDownIcon style={{color: '#000', width:'100%' }} />
                {type === "fake_profil" && <Esquive
                    meet={meet}
                    type={type}
                />}
                <Meetings
                    meet={meet}
                    type={type}
                />
            <Permanent
                info={info}
                type={type}
            />
            <NotPermanent
                info={info}
                type={type}
            />
        </Box>
    )
}

export default OtherInfo