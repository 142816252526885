import { List, Stack, styled } from '@mui/material';
import React, { createContext, memo, useEffect, useState } from 'react';

const MessageContext = createContext(false);

const MessageProvider = ({children}) => {
    const [state, setState] = useState(null)
    const [onHoldCount,setOnHoldCount] = useState(0)
    // const [notif, setNotif] = useState({data:[],nbrChecked:0})
    const [scrollT,setScrollT] = useState(0)
    const [hold,setHold] = useState(null)
    const [images, setImages] = useState([])
    const [open,setOpens] = useState(false)
    const [chan1,setChans1] = useState(null)

    useEffect(()=>{
      if(hold){
        setOnHoldCount(onHoldCount + hold)
        setHold(null)
      }
    },[hold])
    
    const setImage = (content) => {
      setImages(content)
    }
    const setChan1 = (content) => {
      setChans1(content)
    }
    const setOpen = (content) => {
      setOpens(content)
    }
    
    const CustomScrollList = styled(List)(({ theme }) => ({
        maxHeight: '300px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '12px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#616161',
          borderRadius: '10px',
    
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        },
      }));
    const setMessages = (content) =>{
        setState(content)
    }
    // const setNotifs = (content) => {
    //     setNotif(content)
    // }
    const setScrollTs = (content) => {
        setScrollT(content)
    }
    const setOnHoldCounts = (content) => {
      if(content.type) setOnHoldCount(content.data)
      else setHold(content.data)
    }
    
    return (
        <MessageContext.Provider value={{
            messageState: state,
            setMessages,
            onHoldCount,
            setOnHoldCounts,
            // notif,
            // setNotifs,
            scrollT,
            open,
            setOpen,
            setImage,
            images,
            setScrollTs,
            CustomScrollList,
            chan1,
            setChan1
        }}>
            {children}
        </MessageContext.Provider>
    )
}
export {MessageContext, MessageProvider}