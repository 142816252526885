import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary, IconButton, DialogTitle, DialogActions, Button, DialogContent, styled, Badge} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../../hooks/useLanguage";
import useData from "../../hooks/useData";
import TabOtherInfo2 from "./TabOtherInfo2";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomAlert from "../../Admin/CustomAlert";
import { AuthStateContext } from "../../state/AuthStateContext";
import CustomPopup from "../../Admin/CustomPopup";
import axiosInstance from "../../../axios.js";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useForm } from "react-hook-form";
import AuthFormRow from "../../tools/AuthFormRow.jsx";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MessageContext } from "../../state/MessageContext.jsx";
import FormRow from "../../tools/FormRow.jsx";

const YellowNotificationsIcon = styled(NotificationsIcon)(({ theme }) => ({
    color: '#FFAA00',
}));

const Permanent = ({type, info}) =>{
    const { authState } = useContext(AuthStateContext)
    const {messageState} = useContext(MessageContext)
    const { text } = useLanguage()

    const [ loading, setLoading ] = useState()
    const [ alert, setAlert ] = useState()
    const [ alertContenu, setAlertContenu ] = useState()
    const [ permanents, setPermanents] = useState()

    useEffect(() => {
        if (type === "fake_profil") {
            setPermanents(info?.fpPermanent);
        } else {
            setPermanents(info?.vpPermanent);
        }
    }, [type, info]);

    // ADD PERMANENT
    const [ openAdd, setOpenAdd ] = useState(null)
    const [ contenu, setContenu] = useState('');
    

    // DELETE PERMANENT
    const [ permanantToDelete, setPermanantToDelete ] = useState()
    const [ openDelete, setOpenDelete ] = useState(false)
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/information/delete/${permanantToDelete?.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data)
            setAlertContenu(text.delalert)
            setAlert(true)
            setPermanents((last)=>last.filter((a)=>a.id !== permanantToDelete?.id))
            setLoading(false)
            setOpenDelete(false)
        })
        .catch((error)=>{
            console.log(error)
            setLoading(false)
        })
    }
const PermanentC = () => {
    const { control,handleSubmit,formState: { errors },reset } = useForm()
    
    const handleAdd = (data) => {
        setLoading(true)
        const datas = openAdd.type ? {id:openAdd.obj.id,nature:data.nature,content:data.contenu}:{
            "permanent": 1,
            "content": data.contenu,
            "nature": data.nature,
            "conv": messageState.convId,
            "isVpInformation": type === "fake_profil" ? 0 : 1,
        }
        axiosInstance.post(openAdd.type ? '/Information/Edit':"/information/add", datas,{
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data.message)
            setAlertContenu(text.addd)
            setAlert(true)
            const info = openAdd.type ? permanents.map(p => p.id === openAdd.obj.id ? {...p,nature:data.nature,content:data.contenu}:p):[...permanents, response.data.data]
            setPermanents(info)
            setLoading(false)
            setOpenAdd(null)
            reset()
        })
        .catch((error)=>{
            console.log(error)
            setLoading(false)
        })
    }
    return(
        <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.addperminfo}:
                        </p>
                    </DialogTitle>

                    {openAdd && <DialogContent>
                        <div style={{ marginBottom: "12px"}}>
                            <FormRow
                                defaultValue={openAdd.type ? openAdd.obj.nature:''}
                                txtCol="#fff"
                                bgCol="#d48b70"
                                label={text.nat}
                                name='nature' 
                                control={control} 
                                error={errors.name}
                            />
                        </div>
                        <div style={{ marginBottom: "12px"}}>
                        <FormRow
                                defaultValue={openAdd.type ? openAdd.obj.content:''}
                                txtCol="#fff"
                                bgCol="#d48b70"
                                label={text.contenu} 
                                name='contenu' 
                                control={control} 
                                error={errors.name}
                            />
                        </div>
                    </DialogContent>}
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenAdd(null)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={handleSubmit(handleAdd)}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
    )
}
    return(
        <Box padding=" 10px 00px">
            <CustomAlert
                open={alert}
                autoHideDuration={1500}
                onClose={() => setAlert(false)}
                severity="success"
                message={alertContenu}
            />

            {/* pop up add */}
            <CustomPopup open={openAdd ? true:false} onClose={() => setOpenAdd(null)}>
                <PermanentC/>
            </CustomPopup>

            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.dltinfo}
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>

            <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>    
                <AccordionSummary style={{minHeight: '20px',backgroundColor: "#d48b70", height: "30px", padding: '0', marginBottom: "4px"}} >
                    <Typography variant="white" style={{  textAlign: 'center', width: "85%"}}>
                        {text.otherimp2}
                    </Typography>
                    <Stack>
                        <Badge badgeContent={permanents?.length} color="secondary" showZero>
                            <YellowNotificationsIcon style={{color:'#f9e238'}}/>
                        </Badge>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0'}}>
                    <Stack spacing={'2px'}>
                        {
                            permanents?.map((pm, i) =>
                                <TabOtherInfo2 row={i} mt={pm} key={'pm' + i} columns={[
                                    { xs: 2,  text: pm.nature},
                                    { xs: 8,  text: pm.content},
                                    {
                                        xs: 1.8,
                                        customContent: true,
                                        content: <Stack style={{flexDirection:'row',gap:2}}>
                                        <Stack onClick={()=> setOpenAdd({type:true,obj:pm})}>
                                            <BorderColorIcon fontSize="small" style={{color:'#82c8f9'}} />
                                        </Stack>
                                        <Stack onClick={() => { setPermanantToDelete(pm); setOpenDelete(true) }}>
                                            <HighlightOffIcon fontSize="small" style={{color:'#ff7b7b'}}  />
                                        </Stack>
                                    </Stack>
                                    }
                                ]}/>
                            )
                        }
                    </Stack>
                    <Stack display='flex' alignItems="flex-end">
                        <IconButton 
                            style={{width: "fit-content"}} 
                            onClick= {() => setOpenAdd({type:false})}
                        >
                            <AddCircleIcon style={{color:'#45d6e5'}} />
                        </IconButton>
                    </Stack>
                </AccordionDetails>

            </Accordion>

        </Box>
    )
}

export default Permanent