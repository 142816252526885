import React from "react";
import { Stack, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";

export default () => {
    const { text } = useLanguage()
    return <Stack display="flex" alignItems="center" justifyContent="center" spacing={4} height="100%">
        <Typography variant="h0" style={{ fontFamily: "Verona", fontWeight: "900", color: "#f2a490", WebkitTextStroke: ' 0.5px #d48a6f' }}>{text.wlcm?.toUpperCase()}</Typography>
        <Typography fontWeight="bold" maxWidth="50%">{text.wlcmtxt}</Typography>
    </Stack>
}