import { Box, Checkbox, Chip, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";

export  const findIndex = (obj, value) => {
    for (let i = 0; i < obj.length; i++)
        if (obj[i].name === value) return i
    return 0
}
export const selectMenu2 = (c,i,handleChange,qltOpt,type) => (
    <Stack textAlign="center" style={{ gap: 2, height: '100%' }} width='100%'>
    <FormControl fullWidth style={{ height: '100%' }}>
        <Select
            style={{ height: '100%' }}
            disabled={c.validation}
            value={type === 0 ? typeof c.qc_result === 'number' ? c.qc_result : '' : (type === 1 || type === 3) ? c.qc_appreciate !== null ? c.qc_appreciate:'': c.qc_action !== null ? c.qc_action : ''}
            onChange={e => handleChange(e, i, type)}>
            {qltOpt.map((p, i) =>
                <MenuItem value={i} key={i} style={{ cursor: "pointer" }}>
                    <Typography sx={{ fontFamily: 'Delm', color: p.color }}>{p.name}</Typography>
                </MenuItem>)}
        </Select>
    </FormControl>
</Stack>
)
export const selectMenu = (c, i, handleChange, qltOpt) => {
    const MenuProps = {
        PaperProps: {
            style: {

            },
        },
    }; 
    return (
        <Stack style={{ width: '100%', height: '100%' }}>
            <FormControl sx={{ width: '100%' }} style={{ height: '100%' }}>
                <Select
                    style={{ height: '100%' }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    disabled={c.validation}
                    multiple
                    value={c.qc_control}
                    onChange={(e) => handleChange(e, i)}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .4 }}>
                            {selected.map((value, i) => <Chip key={i} sx={{ fontFamily: 'Delm', color: qltOpt[value].color }} label={qltOpt[value].name}></Chip>)}
                        </Box>
                    )} MenuProps={MenuProps}>
                    {qltOpt.map((value, i) => (
                        <MenuItem key={i} value={i} style={{ height: '2em' }}>
                            <Checkbox checked={c.qc_control.indexOf(i) > -1} />
                            <Typography style={{ color: value.color, fontFamily: 'Delm', fontSize: '1.5rem' }}>{value.name}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    )
}