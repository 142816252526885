import React, { memo, useContext } from "react";
import { Stack, Typography } from "@mui/material";
import Language from "../Language/Language";
import UserOptions from "./UserOptions";
import useData from "../hooks/useData";
import { AuthStateContext } from "../state/AuthStateContext";
import TimerC from "./TimerC";
import { Notification, SendNote } from "../tools/Snackbars";
import { MessageContext } from "../state/MessageContext";

const Header1 = ({ setSee }) => {
    const { authState } = useContext(AuthStateContext)
    const { roles } = useData()
    const { authState: { login } } = useContext(AuthStateContext)
    return (
        <Stack className="head" variant='head' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' style={{ zIndex: 30 }} >
            <Stack className="logo" flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                <img src="/images/HeaderImage/logo.png" alt="" height='100%' />
                <SendNote/>
                <Typography color={'teal'} fontWeight={'bold'}>
                    Textmoding ({authState?.languageName})
                </Typography>
            </Stack>
            <TimerC/>
            <Stack className="header-opt" flexDirection='row' style={{ placeItems: 'center' }} gap={2}>
                <Stack sx={{ color: 'teal', justifyContent: 'center', fontWeight: 'bold', display: 'relative' }}>{login}</Stack>
                <Notification/>
                <UserOptions setSee={setSee} variant={roles.operator} />
                <Language />
            </Stack>
        </Stack>
    )
}

export default Header1;