import React from "react";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import Input from './input'
import useLanguage from "../hooks/useLanguage";
import { Controller } from "react-hook-form";

const AuthFormRow = ({label, control, name, error, comp='TextInput', options=[{}]}) =>{
    const {text} = useLanguage()
    const getInputComponent = () =>{
        switch (comp){
            case 'Select': return <Controller
                name= {name}
                control={control}
                rules={{required: text.required}}
                defaultValue={ options[0].value }
                render={({ field }) => 
                <Select {...field} style={{width: '100%', height: '100%', backgroundColor: '#ffcdcd'}}>
                    {
                        options.map(opt =>  <MenuItem key={opt.value} value={opt.value}>{opt.innerHTML}</MenuItem>)
                    }
                </Select>
            }
            />
            case 'Password': return <Input type="password" variant="authformrow" control={control} name={name} error={error} />
            default: return <Input control={control} name={name} error={error} variant="authformrow"/>
        }
    }
    const input = getInputComponent()
    return(
        <Grid container gap='15px'>
            <Grid item xs={3.5} variant="maroon" style={{minHeight: '50px'}}>
                <Typography variant="white" style={{height: '100%',
                display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={7.5}>{ input }</Grid>
        </Grid>
    )
}

export default AuthFormRow