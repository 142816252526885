import React from "react";
import { Stack } from "@mui/material";
import Language from "../Language/Language";
import UserOptions from "./UserOptions";
import useData from "../hooks/useData";

const QcHeader = () => {
    const {roles} = useData()

    return(
        <Stack variant="head" height='70px' padding='0 10px' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <div className="none"></div>
            <Stack className="logo" >
                <img src="/images/HeaderImage/logo.png" alt="" height='100%'/>
            </Stack> 
            <Stack className="header-opt" flexDirection='row' gap='5px'>
                <UserOptions variant={roles.qc}/>
                <Language/>
            </Stack>
                
        </Stack>
    )
} 

export default QcHeader
