import { Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import SearchFilter from "../tools/SearchFilter";
import FormRow from "../tools/FormRow";
import { useForm } from 'react-hook-form'
import axiosInstance from "../../axios";
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { AuthStateContext } from "../state/AuthStateContext";
import useData from "../hooks/useData";
import { formatDate } from "../../helper/formatDate";
import { GifsContext } from "../state/gifContext";
import { format } from "date-fns";
import { MessageContext } from "../state/MessageContext";
import { toNameCase } from "../tools/Snackbars";
import Title from "../Admin2/Comps/Title";

const Notes = () => {
    const { globalFilter } = useContext(GifsContext)
    const { text } = useLanguage()
    const {chan1} = useContext(MessageContext)
    const { languagesList, languagesgifList,noteActions } = useData()
    const [notesListReceive, setNotesListReceive] = useState([])
    const [notesListSend, setNotesListSend] = useState([])
    const [open, setOpen] = useState({ status: false, type: 0 })
    const [platform, setPlatform] = useState([])
    const [openDialog, setOpenDialog] = useState({ status: false, id: 0, type: 0, type1:0 })
    const { authState } = useContext(AuthStateContext)
    const [notesListReceive2,setNotesListReceive2] = useState([])
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
        event: ''
    }))
    const [filter1, setFilter1] = useState(() => ({
        ...globalFilter,
        event: ''
    }))
    // GET NOTE
    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    const [pagination, setPagination] = useState()
    const [pagination1, setPagination1] = useState()
    const handleChange = (event, value,type) => {
        console.log('change ',value,type)
        type === 1 ? setPage(value) : setPage1(value)
    };
    useEffect(()=>{
        if(chan1) {
            chan1.bind('channel_getNewNote',data => {
            console.log('note received : ',data)
            setNotesListReceive(p => [...p,data])
            setNotesListReceive2(p => [...p,data])
        })}
        return()=>chan1 && chan1.unbind()
    },[chan1])
    useEffect(() => {
        axiosInstance.get(`/Note/get?year=${filter1?.year}&month=${filter1?.month}&date=${formatDate(filter1?.date).split('-')[0]}&startingDate=${formatDate(filter1?.startingDate)}&closingDate=${formatDate(filter1?.closingDate)}&platforms=${filter1?.platforms}&language=${filter1?.language}&event=${filter1?.event}&page=${page1}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
            .then((response) => {
                console.log('Note 2 : ', response)
                if(!platform.length) setPlatform(response.data.platform)
                setNotesListSend(response.data.data)
                setPagination1(response.data.pagination)
            })
            .catch((e) =>
                console.log(e)
            )
    }, [page1, filter1])
    useEffect(() => {
        axiosInstance.get(`/Note2/get?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&event=${filter?.event}&page=${page}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
            .then((response) => {
                console.log('Note 1: ', response)
                // setPlatform(response.data.platform)
                setNotesListReceive(response.data.data)
                setNotesListReceive2(response.data.data)
                setPagination(response.data.pagination)
            })
            .catch((e) =>
                console.log(e)
            )
    }, [page, filter])

    const { control, handleSubmit, formState: { errors }, reset } = useForm()

    const sendNote = datas => {
        const date = format(datas.validity, 'yyyy-MM-dd')
        const data = { ...datas, validity: date }
        console.log(data)
        const plt = platform.find(p => p.value === datas.platform)
        axiosInstance.post('/Note/Add', data, {
            headers: { 'Authorization': `Bearer ${authState.token}` }
        }).then(res => {
            console.log(res.data)
            const result = [...notesListSend, { ...res.data, ...data, action:null,platform: plt }]
            setNotesListSend(result)
            setOpen({ status: true, type: 1 })
            reset()
        }).catch(e => console.log(e))
    }
    const deleteNote = () => {
        axiosInstance.get(`/Note/delete/${noteDialog.idnote}`, {
            headers: { 'Authorization': `Bearer ${authState.token}` }
        }).then(() => {
            if(openDialog.type1)
            setNotesListSend(listNote => listNote.filter(note => note.idnote !== noteDialog.idnote)) 
            else{
                setNotesListReceive(listNote => listNote.filter(note => note.idnote !== noteDialog.idnote))
                setNotesListReceive2(listNote => listNote.filter(note => note.idnote !== noteDialog.idnote))
            }
            setOpen({ status: true, type: 2 })
            closeODialog()
        }).catch(e => console.log(e))
    }
    const closeODialog = () => setOpenDialog({ ...openDialog, status: false })
    const EditNote = () => {
        const { control, handleSubmit, formState: { errors } } = useForm()
        const editNote = datas => {
            axiosInstance.post(`/Note/edit/${noteDialog.idnote}`, {...datas,type:false}, {
                headers: { 'Authorization': `Bearer ${authState.token}` }
            }).then(res => {
                openDialog.type1 ? 
                setNotesListSend(listNote => listNote.map(note => note.idnote === noteDialog.idnote ? { ...note, event: datas.event } : note)):
                setNotesListReceive(listNote => listNote.map(note => note.idnote === noteDialog.idnote ? { ...note, event: datas.event } : note))
                setOpen({ status: true, type: 3 })
                closeODialog()
            }).catch(e => console.log(e))
        }
        return (
            <Stack sx={{ padding: '1em', gap: 2, justifyContent: 'center', placeItems: 'center' }}>
                <DialogTitle fontWeight='bold' fontSize='2em'>
                    {text.editNote} {noteDialog.idnote}
                </DialogTitle>
                <DialogContent sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                    <FormRow defaultValue={noteDialog ? noteDialog.event : ''} label={text.evnt} control={control} name="event" error={errors.event} />
                    {/* <FormRow defaultValue={noteDialog ? noteDialog.platform.name : ''} label={text.platform} control={control} name="platform" error={errors.platform} /> */}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={closeODialog}><Typography>{text.annuler}</Typography></Button>
                    <Button variant="contained" onClick={handleSubmit(editNote)} color='success' type="submit"><Typography>{text.editer}</Typography></Button>
                </DialogActions>
            </Stack>
        )
    }
    const handleChangeResult = (e, j) => {
        setNotesListReceive(p => p.map((k, i) => i === j ? { ...k, action: e.target.value } : k))
    }
    let noteDialog = false
    noteDialog = (!noteDialog && openDialog.status) ? openDialog.type1 ? notesListSend[openDialog.id]:notesListReceive[openDialog.id] : false
    const checkCh = (n,i) => n.action !== notesListReceive2[i].action
    const validChange = (n,i) => {
        const datas = {type:true,event:notesListReceive[i].action}
        axiosInstance.post(`/Note/edit/${notesListReceive[i].idnote}`, datas, {
            headers: { 'Authorization': `Bearer ${authState.token}` }
        }).then(res => {
            setNotesListReceive2(p => p.map((k,j) => j === i ? n : k))
            setOpen({ status: true, type: 3 })
            closeODialog()
        }).catch(e => console.log(e))
    }
    return (
        <Stack padding="2% 0%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
            <Snackbar open={open.status} autoHideDuration={1500} onClose={() => setOpen({ ...open, status: false })}>
                {open.type === 2 ?
                    <MuiAlert elevation={6} variant="filled" severity='error'>{text.notesuppr}</MuiAlert> :
                    open.type === 1 ?
                        <MuiAlert elevation={6} variant="filled" severity='success'>{text.addnote}</MuiAlert> :
                        <MuiAlert elevation={6} variant="filled" severity='warning'>{text.editNote}</MuiAlert>
                }
            </Snackbar>
            <Dialog open={openDialog.status} onClose={closeODialog}>
                {openDialog.type === 1 ?
                    <>
                        <DialogContent sx={{ fontWeight: 'bold', fontSize: '2em' }}>
                            {text.dltnt} {noteDialog.idnote} ?
                        </DialogContent>
                        <DialogActions sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                            <Button variant='contained' color='error' onClick={closeODialog}>{text.annuler}</Button>
                            <Button variant='contained' color='success' onClick={deleteNote}>{text.vld}</Button>
                        </DialogActions>
                    </> :
                    <EditNote />
                }
            </Dialog>
            {/* <Stack style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row' }}>
                <Typography variant="h1" alignSelf='center' style={{ fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#a5e2ff', WebkitTextStroke: '2px #215e7b' }}
                >{text.notes}</Typography>
                <img className="card-img-resize-2" src={"/images/supervisor/4no.png"} alt="" height='50px' width='50px' />
            </Stack> */}
            <Title>{text.notesman}</Title>
            <BigContainerAccordion
                transparent
                summaryBg="#ff9b45"
                summary={text.lorn}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({ ...filter, year: y }),
                                month: m => setFilter({ ...filter, month: m }),
                                date: d => setFilter({ ...filter, date: d }),
                            }}
                            items={[
                                { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                                { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                                { label: text.evnt, comp: 'TextInput', onChange: e => setFilter({ ...filter, event: e.target.value }) },
                                { label: text.platform, comp: 'TextInput', onChange: e => setFilter({ ...filter, platforms: e.target.value }) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.6, text: text.date },
                                { xs: 1.4, text: text.ntid },
                                { xs: 1, text: text.language },
                                { xs: 1.4, text: text.agentid },
                                { xs: 2, text: text.notes },
                                { xs: 1.4, text: text.traitement },
                                { xs: 1.4, text: text.actions },
                                { xs: 1, text: text.status}
                            ]} />
                            {
                                !notesListReceive.length ? <Stack fontWeight={'bold'} textAlign={'center'} fontSize={'1.5em'}>Aucune Note</Stack> :
                                    notesListReceive.sort((a,b) => b.idnote - a.idnote).map((n, i) =>
                                        <TableRow key={'n' + i} columns={[
                                            { xs: 1.6, text: n.date },
                                            { xs: 1.4, text: n.idnote },
                                            { xs: 1, text: n.language ? n.language.charAt(0).toUpperCase() + n.language.charAt(1) : 'Fr' },
                                            { xs: 1.4, text: n.agent_id.name },
                                            { xs: 2, text: n.event },
                                            {
                                                xs: 1.4,
                                                customContent: true,
                                                content: <Stack style={{ width: '100%' }}>
                                                    <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }} onClick={() => setOpenDialog({ status: true, id: i, type: 0, type1:0 })}><Typography style={{ fontFamily: 'Delm' }}>{text.edit}</Typography></Button>
                                                    <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }} onClick={() => setOpenDialog({ status: true, id: i, type: 1, type1:0 })}><Typography style={{ fontFamily: 'Delm' }}>{text.dlt}</Typography></Button>
                                                </Stack>
                                            },
                                            {
                                                xs: 1.4,
                                                customContent: true,
                                                content:
                                                    <Stack textAlign="center" style={{ gap: 2, height: '100%' }} width='100%'>
                                                        <FormControl fullWidth style={{ height: '100%' }}>
                                                            <Select
                                                                style={{ height: '100%' }}
                                                                disabled={n.action !== null && !checkCh(n,i)}
                                                                defaultValue={n.action ? n.action:''}
                                                                value={typeof n.action === 'number' ? n.action : ''}
                                                                onChange={e => handleChangeResult(e, i)}
                                                            >
                                                                {noteActions.map((p, i) =>
                                                                    <MenuItem value={i} key={i} style={{ cursor: "pointer" }}>
                                                                        <Typography sx={{ fontFamily: 'Delm', color: p.color }}>{p.name}</Typography>
                                                                    </MenuItem>)}
                                                            </Select>
                                                        </FormControl>
        
                                                    </Stack>
                                            },
                                            {xs:1,customContent:true,
                                                content:
                                                    <Stack style={{width:'100%',height:'100%',justifyContent:'center',cursor:checkCh(n,i) ? 'pointer':'default',placeItems:'center',backgroundColor:checkCh(n,i) ? '#81d39b':'#dbdbdb'}} onClick={()=>checkCh(n,i) && validChange(n,i)}>
                                                    <Typography style={{fontFamily:'Delm',color:checkCh(n,i) ? '#000':'#fff'}}>{text.valider}</Typography>
                                                </Stack>
                                            }

                                        ]} />
                                    )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={page}
                                onChange={(e,value) => handleChange(e,value,1)}
                                count={pagination?.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion
                transparent
                summaryBg="#5d8cc5"
                summary={text.losn}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({ ...filter1, year: y }),
                                month: m => setFilter({ ...filter1, month: m }),
                                date: d => setFilter({ ...filter1, date: d }),
                            }}
                            items={[
                                { label: text.startingdate, comp: 'DatePicker', selected: filter1.startingDate, onChange: d => setFilter({ ...filter1, startingDate: d }) },
                                { label: text.closingdate, comp: 'DatePicker', selected: filter1.closingDate, onChange: d => setFilter({ ...filter1, closingDate: d }) },
                                { label: text.evnt, comp: 'TextInput', onChange: e => setFilter({ ...filter1, event: e.target.value }) },
                                { label: text.platform, comp: 'TextInput', onChange: e => setFilter({ ...filter1, platforms: e.target.value }) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter1, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.6, text: text.date },
                                { xs: 1.6, text: text.evnt },
                                { xs: 1.6, text: text.platform },
                                { xs: 1.6, text: text.language },
                                { xs: 1.6, text: text.ntid },
                                { xs: 1.6, text: text.validity },
                                { xs: 1.6, text: text.actions },
                            ]} />
                            {
                                !notesListSend.length ? <Stack fontWeight={'bold'} textAlign={'center'} fontSize={'1.5em'}>Aucune Note</Stack> :
                                    notesListSend.sort((a,b) => b.idnote - a.idnote).map((n, i) => 
                                        <TableRow key={'n' + i} columns={[
                                            { xs: 1.6, text: n.date },
                                            { xs: 1.6, text: n.event },
                                            { xs: 1.6, text: n.platform.innerHTML },
                                            { xs: 1.6, text: n.language ? toNameCase(n.language) : 'Fr' },
                                            { xs: 1.6, text: n.idnote },
                                            { xs: 1.6, text: n.validity },
                                            {
                                                xs: 1.6,
                                                customContent: true,
                                                content: <Stack style={{ width: '100%' }}>
                                                    <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }} onClick={() => setOpenDialog({ status: true, id: i, type: 0, type1:1 })}><Typography style={{ fontFamily: 'Delm' }}>{text.edit}</Typography></Button>
                                                    <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }} onClick={() => setOpenDialog({ status: true, id: i, type: 1, type1:1 })}><Typography style={{ fontFamily: 'Delm' }}>{text.dlt}</Typography></Button>
                                                </Stack>
                                            },

                                        ]} />
                                    )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={page1}
                                onChange={(e,value) => handleChange(e,value,2)}
                                count={pagination1?.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion
                transparent
                summaryBg="#cb6ce6"
                summary={text.crtsndnts}
                details={
                    <Stack spacing={2}>
                        <FormRow value={notesListSend.length ? notesListSend[notesListSend.length - 1].idnote + 1 : 0} label={text.ntid} comp="readOnly" control={control} name="noteid" error={errors.noteid} />
                        <FormRow label={text.evnt} control={control} name="event" error={errors.event} maxLength={100}/>
                        {!!platform.length && <FormRow label={text.platform} control={control} comp='Select' options={platform} name="platform" error={errors.platform} />}
                        <FormRow label={text.language} comp='Select' options={languagesgifList} control={control} name="language" error={errors.language} />
                        <FormRow label={text.validity} minDate={new Date()} maxDate={null} control={control} comp="DatePicker" name="validity" error={errors.validity} />
                        <Stack style={{ width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button onClick={handleSubmit(sendNote)} variant="formrow">
                                <Typography>
                                    {text.sndnt}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Notes