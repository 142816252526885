import { Stack, Typography, Button, Dialog, DialogActions, DialogTitle, DialogContent, Box, Pagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import CustomAlert from "../Admin/CustomAlert";
import { filterData } from "../../helper/filter";
import { formatDate } from "../../helper/formatDate";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";

const PokeMessages = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const { languagesList,languagesgifList} = useData()

    const [filter, setFilter] = useState(() => ({
        ...globalFilter
    }))
    const [filter1, setFilter1] = useState(() => ({
        ...globalFilter
    }))

    const { control,reset, handleSubmit, formState: {errors} } = useForm()

    // GET ALL POKE
    const [ poke, setPoke ] = useState([])
    useEffect(() => {
        axiosInstance.get('/poke')
        .then((response) => {
          console.log(response.data.data)
          setPoke(response.data.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])

    // PUSHER RECEIVE REPONSE POKE
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-poke")
        channel.bind("add", (data) => {
            console.log(data)
            setPoke((prev) => prev.some((d) => d.id === data.id) ? 
                prev.map((d) => (d.id === data.id ? data : d))
                : 
                [...prev, data]
            );
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-poke")
        }
    },[])

    // LIST MESSAGE POKE PAS ENCORE ENVOYER
    const [ pokeMessagesNon, setPokeMessagesNon ] = useState()
    const [ paginationHold, setPaginationHold ] = useState()
    const [pageHold, setPageHold] = useState(1);
    const handleChangeHold = (event, value) => {
        setPageHold(value);
    };
    useEffect(() => {
        console.log(filter1)
        axiosInstance.get(`/pokemessage/NotSendYet?year=${filter1?.year}&month=${filter1?.month}&date=${formatDate(filter1?.date).split('-')[0]}&startingDate=${formatDate(filter1?.startingDate)}&closingDate=${formatDate(filter1?.closingDate)}&platforms=${filter1?.platforms}&language=${filter1?.language}&page=${pageHold}`,{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setPokeMessagesNon(response.data.data)
            setPaginationHold(response.data.pagination)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [pageHold, filter1])

    // ADD MESSAGE POKE
    const [loading, setLoading] = useState(false)
    const [alerto, setAlerto] = useState(false)
    const [popContenu, setPopContenu] = useState()
    const AddPokeMessage = data => {
        setLoading(true)
        axiosInstance.post('/pokemessage/add',{
            "platformId": data.platform,
            "content": data.pokemessage,
            "language": data.language,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setLoading(false)
            setPopContenu(text.pkmesscrtd)
            setAlerto(true)
            setPokeMessagesNon((last)=>[...last, response.data.data])
            reset()
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    // DELETE MESSAGE POKE (pas encore envoyer)
    const [pop, setPop] = useState(false);
    const [ loadingDelete, setLoadingDelete] = useState(false)
    const [ deleteData, setDeleteData ] = useState()
    const handleDelete = () => {
        setLoadingDelete(true)
        axiosInstance.delete(`/pokemessage/delete/${deleteData?.id}`,{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setLoadingDelete(false)
            setPopContenu(text.delalert)
            setAlerto(true)
            setPop(false)
            setPokeMessagesNon((last) => last.filter((p)=>p.id!==deleteData?.id))
            setDeleteData()
        })
        .catch((error) => {
            console.log(error)
            setLoadingDelete(false)
        })
    }

    // UPDATE MESSAGE POKE (pas encore envoyer)
    const [popUpdate, setPopUpdate] = useState(false);
    const [ updateData, setUpdateData ] = useState()
    const [ loadingUpdate, setLoadingUpdate] = useState(false)
    const [ newContent, setNewContent] = useState("")
    const handleUpdate = () => {
        setLoadingUpdate(true)
        axiosInstance.post('/pokemessage/update',{
            "pokeMessageId": updateData?.id,
            "newContent": newContent,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setPopContenu(text.updtdsucc)
            setAlerto(true)
            setPopUpdate(false)
            setLoadingUpdate(false)
            setPokeMessagesNon((last)=>last.map((p)=> p.id==updateData.id ? {...p, content: newContent} : p))
            setUpdateData()
        })
        .catch((error) => {
            console.log(error)
            setLoadingUpdate(false)
        })
    }

    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <Dialog
                open={popUpdate}
                onClose={()=>setPopUpdate(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{padding: "30px"}}
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                    <p style={{ fontWeight: "800", fontSize: "22px"}}>
                        {text.updt} :
                    </p>
                </DialogTitle>
                <DialogContent>
                    <textarea
                        style={{height: "150px", width: "97%", fontSize: "18px", fontWeight: "500", color: "#333333"}}
                        defaultValue={updateData?.content}
                        onChange={(e)=>setNewContent(e.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={{ pb: "20px"}}>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => {setPopUpdate(false); setUpdateData()}}
                    >
                        {text.annuler}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }} 
                        onClick={handleUpdate}
                    >
                        {loadingUpdate ? text.phload : text.updt}
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={popContenu}
            />
            <Dialog
                open={pop}
                onClose={()=>setPop(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{padding: "16px"}}
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                    <p style={{ fontWeight: "800", fontSize: "22px"}}>
                        {text.dltpkmess} 
                    </p>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.contenu}:
                            </Stack>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                {deleteData?.content}
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.plt} :
                            </Stack>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                {deleteData?.platform.name}
                            </Stack>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ pb: "20px"}}>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => {setPop(false); setDeleteData()}}
                    >
                        {text.annuler}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }} 
                        onClick={handleDelete}
                    >
                        {loadingDelete ? text.phload : "Supprimer"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#c1ff72', WebkitTextStroke: '2px #427a01'}}
                >{text.pokemessages}</Typography> */}
                <Title>{text.pokemessages}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/6pok.png"} alt="" height='50px' width='50px'/> */}
            </Stack>

            {/* Debut Filtre */}
            <BigContainerAccordion 
                transparent
                summaryBg="#bd2424"
                summary={text.lstpkmsg}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.9,  text: "N:"},
                                { xs: 1.9,  text: text.recep},
                                { xs: 1.9, text: text.exp },
                                { xs: 1.9,  text: text.dtofdlvr},
                                { xs: 1.9,  text: text.rsptostp3},
                                { xs: 1.9,  text: text.respVp},
                            ]}/>
                            {
                                poke.map((sm, key) =>
                                    <TableRow key={key} columns={[
                                        { xs: 1.9,  text: key+1},
                                        { xs: 1.9,  text: sm.vprofil},
                                        { xs: 1.9, text: sm.fprofil},
                                        { xs: 1.9,  text: sm.date},
                                        { xs: 1.9,  text: sm.response.length > 80 ? `${sm.response.substring(0, 80)} ...` : (sm.response == "pas encore." ? text.pasEn : sm.response)},
                                        { xs: 1.9,  text: sm.réresponse.length > 80 ? `${sm.réresponse.substring(0, 80)} ...` : (sm.réresponse == "pas encore." ? text.pasEn : sm.réresponse)},
                                    ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
            {/* Fin filtre */}

            <BigContainerAccordion 
                transparent
                summaryBg="#bd2424"
                summary={text.pokePh}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter1.date}
                            onDateChange={{
                                year: y => setFilter1({...filter1, year: y}),
                                month: m => setFilter1({...filter1, month: m}),
                                date: d => setFilter1({...filter1, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter1.startingDate, onChange: d => setFilter1({...filter1, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter1.closingDate, onChange: d => setFilter1({...filter1, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter1({...filter1, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter1({ ...filter1, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.9,  text: text.date},
                                { xs: 1.9,  text: text.platform},
                                { xs: 1.9, text: text.language },
                                { xs: 1.9,  text: text.idpkmsg},
                                { xs: 1.9,  text: text.pkmsg},
                                { xs: 1.9,  text: text.actions},
                            ]}/>
                            {
                                pokeMessagesNon?.map((pm, i) =>
                                    <TableRow key={'pm' + i} columns={[
                                        { xs: 1.9,  text: pm.created_at},
                                        { xs: 1.9,  text: pm.platform.name},
                                        { xs: 1.9, text: pm.language ? pm.language.charAt(0).toUpperCase() + pm.language.charAt(1) : 'Fr' },
                                        { xs: 1.9,  text: pm.id},
                                        { xs: 1.9,  text: pm.content},
                                        {
                                            xs: 1.9,
                                            customContent: true,
                                            content: <Stack style={{width: '100%'}}>
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick={()=>{setPopUpdate(true);setUpdateData(pm)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>{text.edit}</Typography>
                                                </Button>
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick={()=>{setPop(true);setDeleteData(pm)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography>
                                                </Button>
                                            </Stack>
                                        },
                                        
                                    ]}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination 
                                page={pageHold}
                                onChange={handleChangeHold}
                                count={paginationHold?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#1e3d58"
                summary={text.crtpkmsg}
                details={
                    <Stack spacing={2}>
                        {/* <FormRow label={text.idpkmsg} control={control} name="idpokemessage" error={errors.idpokemessage} /> */}
                        <FormRow label={text.platform} control={control} name="platform" error={errors.platform} />
                        <FormRow label={text.language} comp='Select' options={languagesgifList} control={control} name="language" error={errors.language} />
                        <FormRow label={text.pkmsg} control={control} name="pokemessage" error={errors.pokemessage} />
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(AddPokeMessage)} variant="formrow">
                                <Typography>
                                    { loading ? text.phload : text.crtpkmsg }
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default PokeMessages