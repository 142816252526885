import React, {useContext} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";

export default ({onDetails}) => {
    const { text } = useLanguage()
    const { stat } = useContext(StatContext);
    const languages = Object.keys(stat?.panicroom ?? {});

    const StatPanicTab = (language) => {
        const platforms = Object.keys(stat?.panicroom[language] ?? {});
        return (
            <TableRow columns={[
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {stat.panicroom[language][platform]?.new}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.panicroom[language][platform]?.all}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };
    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.panicroom}</Title>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 2.9,  text: text.language},
                { xs: 2.9,  text: text.platform},
                { xs: 2.9,  text: text.unNouv},
                { xs: 2.9,  text: text.total},
            ]}/>
            {languages.map((language) => (<Stack key={language}>{StatPanicTab(language)}</Stack>))}
        </Stack>

        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

