
export const filterData = (data, filter) => {
    return data?.filter(item => {

        if(filter.startingDate && filter.closingDate && filter.platforms){
            let withinDateRange = new Date(item.created_at) >= filter.startingDate && new Date(item.created_at) <= filter.closingDate
            let platformMatches = filter.platforms == item.platform.id || item.platform.name.toLowerCase() == filter.platforms.toLowerCase();
            return withinDateRange && platformMatches;
        }

        if(filter.startingDate && filter.closingDate){
            let withinDateRange = new Date(item.created_at) >= filter.startingDate && new Date(item.created_at) <= filter.closingDate
            return withinDateRange;
        }

        if(filter.platforms){
            let platformMatches = filter.platforms == item.platform.id || item.platform.name.toLowerCase() == filter.platforms.toLowerCase();
            return platformMatches;
        }

        if(filter.year && filter.month && filter.date){
            let yearMatches = filter.year == item.created_at.slice(0,4);
            let monthMatches = filter.month == item.created_at.slice(5,7);
            let dateMatches = filter.date.toString().slice(8,10) == item.created_at.slice(8,10);
            return yearMatches && monthMatches && dateMatches;
        }

        if(filter.year && filter.month){
            let yearMatches = filter.year == item.created_at.slice(0,4);
            let monthMatches = filter.month == item.created_at.slice(5,7);
            return yearMatches && monthMatches;
        }

        return item;

    })
}