import React, { useContext, useEffect, useState } from "react";
import { Stack, ToggleButton, ToggleButtonGroup, IconButton, Typography, Button } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import { Menu, Close } from '@mui/icons-material';
import { AuthStateContext } from "../state/AuthStateContext";
import { MessageContext } from "../state/MessageContext";
import Pusher from 'pusher-js'
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";
import Welcome from "./Comps/00Welcome";
import Conversations from "./Comps/01Conversations"
import AgentsM from "./Comps/02AgentsManagement"
import PanicRoom from "./Comps/03PanicRoom"
import Notes from "./Comps/04Notes"
import Teasers from "./Comps/05Teasers"
import Pokes from "./Comps/06Pokes"
import Stops from "./Comps/07Stops"
import PhotoRequests from "./Comps/08PhotoRequests"
import AgentsR from "./Comps/09AgentsReport"
import LogBooks from "./Comps/10LogBooks"
import Duplicated from "./Comps/11Duplicateds"
import Gifts from "./Comps/12GiftsGifs"
import Supervising from "./Comps/13Supervising"
import QualityControl from "./Comps/14QualityControl"
import Accounts from "./Comps/15Accounts"
import FolderAccess from "./Comps/16FolderAccess"
import Help from "./Comps/17Help"
import NavItem from "./NavItem";
import { getCountH, Notification } from "../tools/Snackbars"
import Language from "../Language/Language";
import SettingsIcon from '@mui/icons-material/Settings';
import Logout from "../Auth/Logout";
import ConversationsDetails from "../Supervisor/Conversations1";
import AgentsDetails from "../Supervisor/Agents2"
import PanicDetails from "../Supervisor/PanicRoom3"
import NotesDetails from "../Supervisor/Notes4"
import TeaserD from "../Supervisor/TeaserMessages5"
import PokeD from "../Supervisor/PokeMessages6"
import StopD from "../Supervisor/StopMessages7"
import PhotoReqD from "../Supervisor/PhotosRequest8"
import ReportD from "../Supervisor/Report9"
import LogBookD from "../Supervisor/LogBook10"
import DuplicatedD from "../Supervisor/DuplicatedMessages11"
import GifsD from "../Supervisor/Gifs12"
import SupervisingD from "../Admin/SupTrMng"
import QCD from "../QualityController/QualityControllerBody"
import AccD from "../Accountant/AccountantBody"
import axiosInstance from "../../axios";
import {  useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { StatContext } from "../state/StatContext";
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';

export default () => {
    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const {setChan1} = useContext(MessageContext)
    const [ expanded, setExpanded ] = useState(false)
    const [ value, setValue ] = useState(16)
    const [ comp, setComp ] = useState(<></>)
    const { authState: { login, userId, userName, userRole } } = useContext(AuthStateContext)
    const {holdC, setStat, stat} = useContext(StatContext)
    const navigate = useNavigate()

    //PUSHER STATISTIC2
    const [ data, setData ] = useState()
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-statistic2")
        channel.bind(`${authState?.userId}`, (data)=> {
            console.log("comming data....", data)
            setData(data)
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-statistic2")
        }
    },[])
    
    useEffect(()=>{
        console.log("execution 2em useEffect...")
        if(data){
            if(data.name == "photorequests"){
                setStat((prev) => {
                    console.log("test..")
                    const updatedStat = { ...prev };
                    updatedStat[data.name][data.platform][data.field] += data.value;
                    return updatedStat;
                });
            }else if(data.name == "agents"){
                setStat((prev) => {
                    console.log("test..")
                    const updatedStat = { ...prev };
                    updatedStat[data.name][data.language][data.field] += data.value;
                    return updatedStat;
                });
            }else if(data.name == "gifs"){
                setStat((prev) => {
                    console.log("test..")
                    const updatedStat = { ...prev };
                    updatedStat[data.name][data.platform] += data.value;
                    return updatedStat;
                });
            }else{
                setStat((prev) => {
                    console.log("test..")
                    const updatedStat = { ...prev };
                    updatedStat[data.name][data.language][data.platform][data.field] += data.value;
                    return updatedStat;
                });
            }
        }
    }, [data])

    const getComp = () => {
        switch(value){
            case 0:  return <Welcome/>
            case 1: return <Conversations onDetails={() => setValue(-1) }/>
            case 2: return <AgentsM onDetails={() => setValue(-2) }/>
            case 3: return <PanicRoom onDetails={() => setValue(-3) }/>
            case 4: return <Notes onDetails={() => setValue(-4)} />
            case 5: return <Teasers onDetails={() => setValue(-5) }/>
            case 6: return <Pokes onDetails={() => setValue(-6) }/>
            case 7: return <Stops onDetails={() => setValue(-7) }/>
            case 8: return <PhotoRequests onDetails={() => setValue(-8) }/>
            case 9: return <AgentsR onDetails={() => setValue(-9) }/>
            case 10: return <LogBooks onDetails={() => setValue(-10) }/>
            case 11: return <Duplicated onDetails={() => setValue(-11) }/>
            case 12: return <Gifts onDetails={() => setValue(-12) }/>
            case 13: return <Supervising onDetails={() => setValue(-13) }/>
            case 14: return <QualityControl onDetails={() => setValue(-14) }/>
            case 15: return <Accounts onDetails={() => setValue(-15) }/>
            case 16: return <FolderAccess/>
            case 17: return <Help/>
            case 18: return <Stack>Platform Language</Stack>
            case -1: return <ConversationsDetails/>
            case -2: return <AgentsDetails/>
            case -3: return <PanicDetails/>
            case -4: return <NotesDetails/>
            case -5: return <TeaserD/>
            case -6: return <PokeD/>
            case -7: return <StopD/>
            case -8: return <PhotoReqD/>
            case -9: return <ReportD/>
            case -10: return <LogBookD/>
            case -11: return <DuplicatedD/>
            case -12: return <GifsD/>
            case -13: return <SupervisingD/>
            case -14: return <QCD/>
            case -15: return <AccD/>
            default: return <></>
        }
    }

    useEffect(() => {setComp(getComp()) }, [value])

    return <Stack>
        {/* Nav */}
        <Stack flexDirection="row" height='70px' justifyContent= "space-between" alignItems="center" padding="0 10px">
            <IconButton onClick={() => setExpanded(e => !e)} >{ expanded ? <Close/> : <Menu/> }</IconButton>
            <Stack className="usericon" style={{ cursor: 'pointer' }} onClick={() => setValue(-1)}>
                <Badge color='info' badgeContent={holdC} showZero>
                    <EmailIcon style={{ color: '#ebae3d', fontSize: '2.5rem' }} />
                </Badge>
            </Stack>
            {/* <Stack style={{ cursor: "pointer" }} onClick={() => setValue(-1)}>
                <img src="/images/admin2/Conversation_en_hold.png" alt="" height='50px'/>
            </Stack> */}
            <Typography variant="h2" style={{whiteSpace: 'nowrap', fontFamily: 'Futura Std Medium', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#000000', WebkitTextStroke: '2px #d48a6f'}}>{text.admdash.toUpperCase()}</Typography>
            <Button variant= "formrow" onClick={()=>navigate(RoutesList.adminFinal)}>Previous design</Button>
            <Notification/>
            <Stack className="logo" ><img src="/images/HeaderImage/logo.png" alt="" height='100%'/></Stack>
        </Stack>

        <Stack variant="maroongradientbg" flexDirection='row' height='calc(100vh - 70px)' width='100%'>
            <Stack style={{transition: '.5s', width: expanded ? '440px' : '65px', overflowX: 'hidden' }} height='100%' borderRight= "2px solid #d48a6f">
                <Stack width='330px' height='100%' style={{ overflowY: "scroll" }} >
                    {/* User Info */}
                    <Stack flexDirection="row" padding="2%" justifyContent="space-between" borderBottom= "2px solid #000" alignItems="center">
                        <Stack paddingLeft="3%"><img src="/images/admin2/Profil Admin.png" alt="" height='35px'/></Stack>
                        <Stack>
                            <Typography variant="pfsbold">{userName}</Typography>
                            <Typography variant="pfsbold">{text.role}: {userRole}</Typography>
                            <Typography variant="pfsbold">{text.idd}: {userId}</Typography>
                            <Typography variant="pfsbold">{text.mail}: {login}</Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Language icon/>
                            <Logout icon/>
                        </Stack>
                    </Stack>

                    <ToggleButtonGroup
                        orientation="vertical"
                        value={value}
                        exclusive
                        onChange={(e, newState) => { if (Boolean(newState)) setValue(newState) }}
                        style={{ height: "100%", display: "flex", justifyContent: "space-between",gap:6,padding:'2% 0%' }}
                    >
                        <Stack spacing={1} padding="2% 0%">
                            <ToggleButton value={1}><NavItem selected={Math.abs(value)} rank={1} text={text.convman}/></ToggleButton>
                            <ToggleButton value={2}><NavItem selected={Math.abs(value)} rank={2} text={text.agman}/></ToggleButton>
                            <ToggleButton value={3}><NavItem selected={Math.abs(value)} rank={3} text={text.panicroom}/></ToggleButton>
                            <ToggleButton value={4}><NavItem selected={Math.abs(value)} rank={4} text={text.notesman}/></ToggleButton>
                            <ToggleButton value={5}><NavItem selected={Math.abs(value)} rank={5} text={text.teasermessages}/></ToggleButton>
                            <ToggleButton value={6}><NavItem selected={Math.abs(value)} rank={6} text={text.pokemessages}/></ToggleButton>
                            <ToggleButton value={7}><NavItem selected={Math.abs(value)} rank={7} text={text.stopmessages}/></ToggleButton>
                            <ToggleButton value={8}><NavItem selected={Math.abs(value)} rank={8} text={text.pr}/></ToggleButton>
                            <ToggleButton value={9}><NavItem selected={Math.abs(value)} rank={9} text={text.agrep}/></ToggleButton>
                            <ToggleButton value={10}><NavItem selected={Math.abs(value)} rank={10} text={text.logbook}/></ToggleButton>
                            <ToggleButton value={11}><NavItem selected={Math.abs(value)} rank={11} text={text.duplicatedmessages}/></ToggleButton>
                            <ToggleButton value={12}><NavItem selected={Math.abs(value)} rank={12} text={text.gftgtfman}/></ToggleButton>
                            <ToggleButton value={13}><NavItem selected={Math.abs(value)} rank={13} text={text.suptraffman}/></ToggleButton>
                            <ToggleButton value={14}><NavItem selected={Math.abs(value)} rank={14} text={text.qctrl}/></ToggleButton>
                            <ToggleButton value={15}><NavItem selected={Math.abs(value)} rank={15} text={text.acc}/></ToggleButton>
                            <ToggleButton value={16}><NavItem selected={Math.abs(value)} rank={16} text={text.tmfldaa}/></ToggleButton>
                        </Stack>
                        <Stack spacing={1}>
                            <ToggleButton value={17}><NavItem selected={value} rank={17} text={text.hlp}/></ToggleButton>
                            <ToggleButton value={18}><NavItem rank={18} text={text.pltflng}/></ToggleButton>
                        </Stack>
                    </ToggleButtonGroup>
                </Stack>
            </Stack>

            {/* Droite */}
            <Stack height='100%' width='100%' >
                { comp }
            </Stack>
        </Stack>
    </Stack>
}
