import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React,{useContext} from "react";
import useLanguage from "../../hooks/useLanguage";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { baseURL } from "../../../helper/conf";
import { GifsContext } from "../../state/gifContext";

const GayGifs = ({ setGifs }) =>{
    const { text } = useLanguage()
    const {gifs} = useContext(GifsContext)
    const tabGaG = [
        {text:text.bdsmg,couleur:'#004aad',type:'wig',typeopt:'bdsmg'},
        {text:text.wisegg,couleur:'#ff3131',type:'wig',typeopt:'wisegg'},
        {text:text.sexgg,couleur:'#1cb743',type:'wig',typeopt:'sexgg'},
]
    return(
        <Box>

            <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>
                <AccordionSummary  style={{width: "100%", backgroundColor: "#5271ff", cursor: "pointer", minHeight: '20px', height: "30px"}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                    <Typography variant="pfsboldwhite" style={{textAlign: "center", width: '100%'}}>{text.gayg}</Typography>
                </AccordionSummary>
                <Stack marginTop={'.5em'} gap={'.5em'}>
                    {tabGaG.map((p,i) => <Accordion key={i} style={{position:'relative',left:'1.5em', backgroundColor: "transparent", boxShadow: 'none' }}>
                        <AccordionSummary style={{ width: "115%", backgroundColor: `${p.couleur}`, cursor: "pointer", minHeight: '20px', height: "35px" }} expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}>
                            <Typography variant="pfsboldwhite" style={{ width: "100%", textAlign: 'center' }}>{p.text}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'flex',gap:1}}>
                            {gifs.map(gif => gif.type === p.type && gif.typeoption === p.typeopt && <span key={gif.id} onClick={()=>setGifs(gif)}>
                            <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                            </span>)}
                        </AccordionDetails>
                    </Accordion>
                    )}
                </Stack>
            </Accordion>

        </Box>
    )
}

export default GayGifs