const countriesList = [
    { value: 'AF', innerHTML: 'Afghanistan - افغانستان' },
    { value: 'AL', innerHTML: 'Albania - Shqipëri' },
    { value: 'DZ', innerHTML: 'Algeria - الجزائر' },
    { value: 'AD', innerHTML: 'Andorra - Andorra' },
    { value: 'AO', innerHTML: 'Angola - Angola' },
    { value: 'AG', innerHTML: 'Antigua and Barbuda - Antigua and Barbuda' },
    { value: 'AR', innerHTML: 'Argentina - Argentina' },
    { value: 'AM', innerHTML: 'Armenia - Հայաստան' },
    { value: 'AU', innerHTML: 'Australia - Australia' },
    { value: 'AT', innerHTML: 'Austria - Österreich' },
    { value: 'AZ', innerHTML: 'Azerbaijan - Azərbaycan' },
    { value: 'BS', innerHTML: 'Bahamas - Bahamas' },
    { value: 'BH', innerHTML: 'Bahrain - البحرين' },
    { value: 'BD', innerHTML: 'Bangladesh - বাংলাদেশ' },
    { value: 'BB', innerHTML: 'Barbados - Barbados' },
    { value: 'BY', innerHTML: 'Belarus - Беларусь' },
    { value: 'BE', innerHTML: 'Belgium - België' },
    { value: 'BZ', innerHTML: 'Belize - Belize' },
    { value: 'BJ', innerHTML: 'Benin - Bénin' },
    { value: 'BT', innerHTML: 'Bhutan - འབྲུག་ཡུལ' },
    { value: 'BO', innerHTML: 'Bolivia - Bolivia' },
    { value: 'BA', innerHTML: 'Bosnia and Herzegovina - Bosna i Hercegovina' },
    { value: 'BW', innerHTML: 'Botswana - Botswana' },
    { value: 'BR', innerHTML: 'Brazil - Brasil' },
    { value: 'BN', innerHTML: 'Brunei - Brunei' },
    { value: 'BG', innerHTML: 'Bulgaria - България' },
    { value: 'BF', innerHTML: 'Burkina Faso - Burkina Faso' },
    { value: 'BI', innerHTML: 'Burundi - Burundi' },
    { value: 'CV', innerHTML: 'Cabo Verde - Cabo Verde' },
    { value: 'KH', innerHTML: 'Cambodia - កម្ពុជា' },
    { value: 'CM', innerHTML: 'Cameroon - Cameroun' },
    { value: 'CA', innerHTML: 'Canada - Canada' },
    { value: 'CF', innerHTML: 'Central African Republic - République centrafricaine' },
    { value: 'TD', innerHTML: 'Chad - تشاد' },
    { value: 'CL', innerHTML: 'Chile - Chile' },
    { value: 'CN', innerHTML: 'China - 中国' },
    { value: 'CO', innerHTML: 'Colombia - Colombia' },
    { value: 'KM', innerHTML: 'Comoros - Comores' },
    { value: 'CG', innerHTML: 'Congo (Congo-Brazzaville) - Congo' },
    { value: 'CR', innerHTML: 'Costa Rica - Costa Rica' },
    { value: 'HR', innerHTML: 'Croatia - Hrvatska' },
    { value: 'CU', innerHTML: 'Cuba - Cuba' },
    { value: 'CY', innerHTML: 'Cyprus - Κύπρος' },
    { value: 'CZ', innerHTML: 'Czechia (Czech Republic) - Česko' },
    { value: 'DK', innerHTML: 'Denmark - Danmark' },
    { value: 'DJ', innerHTML: 'Djibouti - جيبوتي' },
    { value: 'DM', innerHTML: 'Dominica - Dominica' },
    { value: 'DO', innerHTML: 'Dominican Republic - República Dominicana' },
    { value: 'TL', innerHTML: 'East Timor - Timor-Leste' },
    { value: 'EC', innerHTML: 'Ecuador - Ecuador' },
    { value: 'EG', innerHTML: 'Egypt - مصر' },
    { value: 'SV', innerHTML: 'El Salvador - El Salvador' },
    { value: 'GQ', innerHTML: 'Equatorial Guinea - Guinea Ecuatorial' },
    { value: 'ER', innerHTML: 'Eritrea - ኤርትራ' },
    { value: 'EE', innerHTML: 'Estonia - Eesti' },
    { value: 'SZ', innerHTML: 'Eswatini (fmr. Swaziland) - Eswatini' },
    { value: 'ET', innerHTML: 'Ethiopia - ኢትዮጵያ' },
    { value: 'FJ', innerHTML: 'Fiji - Fiji' },
    { value: 'FI', innerHTML: 'Finland - Suomi' },
    { value: 'FR', innerHTML: 'France - France' },
    { value: 'GA', innerHTML: 'Gabon - Gabon' },
    { value: 'GM', innerHTML: 'Gambia - Gambia' },
    { value: 'GE', innerHTML: 'Georgia - საქართველო' },
    { value: 'DE', innerHTML: 'Germany - Deutschland' },
    { value: 'GH', innerHTML: 'Ghana - Ghana' },
    { value: 'GR', innerHTML: 'Greece - Ελλάδα' },
    { value: 'GD', innerHTML: 'Grenada - Grenada' },
    { value: 'GT', innerHTML: 'Guatemala - Guatemala' },
    { value: 'GN', innerHTML: 'Guinea - Guinée' },
    { value: 'GW', innerHTML: 'Guinea-Bissau - Guiné-Bissau' },
    { value: 'GY', innerHTML: 'Guyana - Guyana' },
    { value: 'HT', innerHTML: 'Haiti - Haïti' },
    { value: 'HN', innerHTML: 'Honduras - Honduras' },
    { value: 'HU', innerHTML: 'Hungary - Magyarország' },
    { value: 'IS', innerHTML: 'Iceland - Ísland' },
    { value: 'IN', innerHTML: 'India - भारत' },
    { value: 'ID', innerHTML: 'Indonesia - Indonesia' },
    { value: 'IR', innerHTML: 'Iran - ایران' },
    { value: 'IQ', innerHTML: 'Iraq - العراق' },
    { value: 'IE', innerHTML: 'Ireland - Éire' },
    { value: 'IL', innerHTML: 'Israel - ישראל' },
    { value: 'IT', innerHTML: 'Italy - Italia' },
    { value: 'CI', innerHTML: 'Ivory Coast - Côte d\'Ivoire' },
    { value: 'JM', innerHTML: 'Jamaica - Jamaica' },
    { value: 'JP', innerHTML: 'Japan - 日本' },
    { value: 'JO', innerHTML: 'Jordan - الأردن' },
    { value: 'KZ', innerHTML: 'Kazakhstan - Қазақстан' },
    { value: 'KE', innerHTML: 'Kenya - Kenya' },
    { value: 'KI', innerHTML: 'Kiribati - Kiribati' },
    { value: 'KP', innerHTML: 'Korea, North - 북한' },
    { value: 'KR', innerHTML: 'Korea, South - 대한민국' },
    { value: 'XK', innerHTML: 'Kosovo - Kosovë' },
    { value: 'KW', innerHTML: 'Kuwait - الكويت' },
    { value: 'KG', innerHTML: 'Kyrgyzstan - Кыргызстан' },
    { value: 'LA', innerHTML: 'Laos - ລາວ' },
    { value: 'LV', innerHTML: 'Latvia - Latvija' },
    { value: 'LB', innerHTML: 'Lebanon - لبنان' },
    { value: 'LS', innerHTML: 'Lesotho - Lesotho' },
    { value: 'LR', innerHTML: 'Liberia - Liberia' },
    { value: 'LY', innerHTML: 'Libya - ليبيا' },
    { value: 'LI', innerHTML: 'Liechtenstein - Liechtenstein' },
    { value: 'LT', innerHTML: 'Lithuania - Lietuva' },
    { value: 'LU', innerHTML: 'Luxembourg - Luxembourg' },
    { value: 'MG', innerHTML: 'Madagascar - Madagascar' },
    { value: 'MW', innerHTML: 'Malawi - Malawi' },
    { value: 'MY', innerHTML: 'Malaysia - Malaysia' },
    { value: 'MV', innerHTML: 'Maldives - ދިވެހިރާއްޖެ' },
    { value: 'ML', innerHTML: 'Mali - Mali' },
    { value: 'MT', innerHTML: 'Malta - Malta' },
    { value: 'MH', innerHTML: 'Marshall Islands - Marshall Islands' },
    { value: 'MR', innerHTML: 'Mauritania - موريتانيا' },
    { value: 'MU', innerHTML: 'Mauritius - Maurice' },
    { value: 'MX', innerHTML: 'Mexico - México' },
    { value: 'FM', innerHTML: 'Micronesia - Micronesia' },
    { value: 'MD', innerHTML: 'Moldova - Moldova' },
    { value: 'MC', innerHTML: 'Monaco - Monaco' },
    { value: 'MN', innerHTML: 'Mongolia - Монгол улс' },
    { value: 'ME', innerHTML: 'Montenegro - Crna Gora' },
    { value: 'MA', innerHTML: 'Morocco - المغرب' },
    { value: 'MZ', innerHTML: 'Mozambique - Moçambique' },
    { value: 'MM', innerHTML: 'Myanmar (formerly Burma) - မြန်မာ' },
    { value: 'NA', innerHTML: 'Namibia - Namibia' },
    { value: 'NR', innerHTML: 'Nauru - Nauru' },
    { value: 'NP', innerHTML: 'Nepal - नेपाल' },
    { value: 'NL', innerHTML: 'Netherlands - Nederland' },
    { value: 'NZ', innerHTML: 'New Zealand - Aotearoa' },
    { value: 'NI', innerHTML: 'Nicaragua - Nicaragua' },
    { value: 'NE', innerHTML: 'Niger - Niger' },
    { value: 'NG', innerHTML: 'Nigeria - Nigeria' },
    { value: 'MK', innerHTML: 'North Macedonia - Северна Македонија' },
    { value: 'NO', innerHTML: 'Norway - Norge' },
    { value: 'OM', innerHTML: 'Oman - عُمان' },
    { value: 'PK', innerHTML: 'Pakistan - پاکستان' },
    { value: 'PW', innerHTML: 'Palau - Palau' },
    { value: 'PS', innerHTML: 'Palestine - فلسطين' },
    { value: 'PA', innerHTML: 'Panama - Panamá' },
    { value: 'PG', innerHTML: 'Papua New Guinea - Papua Niugini' },
    { value: 'PY', innerHTML: 'Paraguay - Paraguay' },
    { value: 'PE', innerHTML: 'Peru - Perú' },
    { value: 'PH', innerHTML: 'Philippines - Pilipinas' },
    { value: 'PL', innerHTML: 'Poland - Polska' },
    { value: 'PT', innerHTML: 'Portugal - Portugal' },
    { value: 'QA', innerHTML: 'Qatar - قطر' },
    { value: 'RO', innerHTML: 'Romania - România' },
    { value: 'RU', innerHTML: 'Russia - Россия' },
    { value: 'RW', innerHTML: 'Rwanda - Rwanda' },
    { value: 'KN', innerHTML: 'Saint Kitts and Nevis - Saint Kitts and Nevis' },
    { value: 'LC', innerHTML: 'Saint Lucia - Saint Lucia' },
    { value: 'VC', innerHTML: 'Saint Vincent and the Grenadines - Saint Vincent and the Grenadines' },
    { value: 'WS', innerHTML: 'Samoa - Samoa' },
    { value: 'SM', innerHTML: 'San Marino - San Marino' },
    { value: 'ST', innerHTML: 'Sao Tome and Principe - São Tomé e Príncipe' },
    { value: 'SA', innerHTML: 'Saudi Arabia - السعودية' },
    { value: 'SN', innerHTML: 'Senegal - Sénégal' },
    { value: 'RS', innerHTML: 'Serbia - Србија' },
    { value: 'SC', innerHTML: 'Seychelles - Sesel' },
    { value: 'SL', innerHTML: 'Sierra Leone - Sierra Leone' },
    { value: 'SG', innerHTML: 'Singapore - Singapore' },
    { value: 'SK', innerHTML: 'Slovakia - Slovensko' },
    { value: 'SI', innerHTML: 'Slovenia - Slovenija' },
    { value: 'SB', innerHTML: 'Solomon Islands - Solomon Islands' },
    { value: 'SO', innerHTML: 'Somalia - Soomaaliya' },
    { value: 'ZA', innerHTML: 'South Africa - South Africa' },
    { value: 'SS', innerHTML: 'South Sudan - South Sudan' },
    { value: 'ES', innerHTML: 'Spain - España' },
    { value: 'LK', innerHTML: 'Sri Lanka - ශ්‍රී ලංකාව' },
    { value: 'SD', innerHTML: 'Sudan - السودان' },
    { value: 'SR', innerHTML: 'Suriname - Suriname' },
    { value: 'SE', innerHTML: 'Sweden - Sverige' },
    { value: 'CH', innerHTML: 'Switzerland - Schweiz' },
    { value: 'SY', innerHTML: 'Syria - سوريا' },
    { value: 'TW', innerHTML: 'Taiwan - 台灣' },
    { value: 'TJ', innerHTML: 'Tajikistan - Тоҷикистон' },
    { value: 'TZ', innerHTML: 'Tanzania - Tanzania' },
    { value: 'TH', innerHTML: 'Thailand - ประเทศไทย' },
    { value: 'TG', innerHTML: 'Togo - Togo' },
    { value: 'TO', innerHTML: 'Tonga - Tonga' },
    { value: 'TT', innerHTML: 'Trinidad and Tobago - Trinidad and Tobago' },
    { value: 'TN', innerHTML: 'Tunisia - تونس' },
    { value: 'TR', innerHTML: 'Turkey - Türkiye' },
    { value: 'TM', innerHTML: 'Turkmenistan - Türkmenistan' },
    { value: 'TV', innerHTML: 'Tuvalu - Tuvalu' },
    { value: 'UG', innerHTML: 'Uganda - Uganda' },
    { value: 'UA', innerHTML: 'Ukraine - Україна' },
    { value: 'AE', innerHTML: 'United Arab Emirates - الإمارات العربية المتحدة' },
    { value: 'GB', innerHTML: 'United Kingdom - United Kingdom' },
    { value: 'US', innerHTML: 'United States of America - United States of America' },
    { value: 'UY', innerHTML: 'Uruguay - Uruguay' },
    { value: 'UZ', innerHTML: 'Uzbekistan - Oʻzbekiston' },
    { value: 'VU', innerHTML: 'Vanuatu - Vanuatu' },
    { value: 'VA', innerHTML: 'Vatican City (Holy See) - Città del Vaticano' },
    { value: 'VE', innerHTML: 'Venezuela - Venezuela' },
    { value: 'VN', innerHTML: 'Vietnam - Việt Nam' },
    { value: 'YE', innerHTML: 'Yemen - اليمن' },
    { value: 'ZM', innerHTML: 'Zambia - Zambia' },
    { value: 'ZW', innerHTML: 'Zimbabwe - Zimbabwe' }    
]
export default countriesList