import React from "react";
import { Stack } from "@mui/material";
import Language from "../Language/Language";
import RoutesList from "../../helper/routes";

const BasicHeader = () => {

    return(
        <Stack variant="maroongradientbg" height='70px' padding='0 10px' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <img src="/images/HeaderImage/logo.png" alt="" height='50px' width='50px'/>  
            <Stack className="header-opt" flexDirection='row' gap='5px'>
                <Language redirectRoute={RoutesList.pp}/>
            </Stack>
                
        </Stack>
    )
} 

export default BasicHeader
