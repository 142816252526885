import { Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Accordion, AccordionSummary } from "@mui/material";
import React, { useState,useRef,useContext, useEffect } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import useList from "../hooks/useList";
import { format } from "date-fns";
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const Report = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()

    const { reports, photoRequests, languagesList } = useData()
    const {authState} = useContext(AuthStateContext)
    const [open, setOpen] = useState({ status: false, type: 0 })
    const {reportList, getReportList,setReportList} = useList()
    const [openDialog, setOpenDialog] = useState({ status: false, content: null, ind:0 })
    const [messageRep,setMessageRep] = useState('')

    const closeODialog = () => {
        setOpenDialog({ ...openDialog, status: false })
        setMessageRep('')
    }

    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        reporttype: ''
    })

    useEffect(()=>{
        getReportList()
   },[])
   useEffect(()=>{
    console.log(reportList)
   },[reportList])
   const reportAgent = async() => {
        let reportData = openDialog.content
        const agentId = reportData.operatorId
    
        const data = {
            type: 'Report',
            content: messageRep,
            id_message:reportData.id_message,
            id_report:reportData.id_report,
            sender:true
        }
        await axiosInstance.post(`/Notifications/Add/${agentId}`,data, {headers: { 'Authorization': `Bearer ${authState.token}` }})
        .then(res => {
            if(res.status === 200){
                setReportList(p => p.map((k,j) => j === openDialog.ind ? {...k,status:true}:k))
                console.log(res.data)
                setOpen({status:true,type:1})
                setMessageRep('')
                closeODialog()
            }
        }).catch(e => console.log(e))
   }
    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            {openDialog.content && <>
                <Snackbar open={open.status} autoHideDuration={1500}  onClose={() => setOpen({ ...open, status: false })}>
                    <MuiAlert elevation={6} variant="filled" severity='success'> {openDialog.content.operatorName}: {text.agntinfrmd}</MuiAlert>
                </Snackbar>
                <Dialog open={openDialog.status} onClose={closeODialog}>
                    <DialogTitle sx={{fontWeight:'bold',fontSize:'2em'}}>{text.infrmagnt} {openDialog.content.operatorName} ?</DialogTitle>
                    <DialogContent sx={{ fontWeight: 'bold', fontSize: '2em' }}>
                        <Stack sx={{gap:'5px'}}>
                            <Typography sx={{ fontWeight: 'bold' }}>{text.mid} : {openDialog.content.id_message}</Typography>
                            <textarea value={messageRep} onChange={e => setMessageRep(e.target.value)} style={{minHeight:'4em',padding:'5px',resize:"none"}} placeholder="Message a reporter..." ></textarea>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant='contained' color='error' onClick={closeODialog}>{text.annuler}</Button>
                        <Button variant='contained' color='success' onClick={reportAgent}>{text.vld}</Button>
                    </DialogActions>
                </Dialog>
            </>}
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#fff', WebkitTextStroke: '2px #ff9898'}}
                >{text.report}</Typography> */}
                <Title>{text.agrep}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/9re.png"} alt="" height='50px' width='50px'/> */}
            </Stack>
            <BigContainerAccordion 
                transparent
                summaryBg="#f4a100"
                summary={text.report}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.rpttp, comp: 'TextInput', onChange: e => setFilter({...filter, reporttype: e.target.value}) }
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: .9,  text: text.date},
                                { xs: 1,  text: text.platform},
                                { xs: .5, text: text.language },
                                { xs: .8,  text: text.idrprt},
                                { xs: .8,  text: text.idmess},
                                { xs: 1.2,  text: text.mess},
                                { xs: 1.2,  text: text.user},
                                { xs: 1,  text: text.agentReport},
                                { xs: 1.2,  text: text.agent},
                                { xs: 1.2,  text: text.raison},
                                { xs: 1,  text: text.actions},
                            ]}/>
                            {
                                reportList?.sort((a,b) => a.id_report - b.id_report).map((r,i) => 
                                    <TableRow key={`r${i}`} columns={[
                                        { xs: .9,  text: r.reportdate ? format(new Date(r.reportdate), 'dd/MM/yyyy') : ''},
                                        { xs: 1,  text: r.platform},
                                        { xs: .5, text: r.language ? r.language.charAt(0).toUpperCase() + r.language.charAt(1) : 'Fr' },
                                        { xs: .8,  text: r.id_report},
                                        { xs: .8,  text: r.id_message},
                                        { xs: 1.2,  text: r.message},
                                        { xs: 1.2,  text: r.sender_profil.Name},
                                        { xs: 1,  text: r.operatorName},
                                        { xs: 1.2,  text: r.agentSign},
                                        { xs: 1.2,  text: r.content},
                                        {
                                            xs: 1,
                                            customContent: true,
                                            content: <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}} onClick={()=>!r.status ? setOpenDialog({ind:i,status:true,content:reportList[i]}):{}}><Typography style={{fontFamily: 'Delm',color:r.status ? 'limegreen':'tomato'}}>{!r.status ? text.infrmagnt:text.agentInform}</Typography></Button>                       
                                        },
                                    ]} number={r} stat={4} ind={i > -1 ? true:false} />
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Report