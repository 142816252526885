import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLanguage from '../hooks/useLanguage';

const SwitchFeature = () => {
    const navigate = useNavigate();
    const { text } = useLanguage();

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        
        if (selectedValue) {
            navigate(selectedValue);
        }
    };

    return (
        <select name="options" id="options" className="styled-select" onChange={handleSelectChange}>
            <option value="" disabled selected>
                {text.features}
            </option>
            <option value="/sendmessage">{text.tit1}</option>
            <option value="/teasermessage">{text.tit2}</option>
            <option value="/pokemessage">{text.tit3}</option>
            <option value="/stopmessage">{text.tit4}</option>
        </select>
    )
}

export default SwitchFeature