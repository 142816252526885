import { Controller } from "react-hook-form"
import { useState } from "react"
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { OutlinedInput, Stack, Typography, IconButton, InputAdornment } from "@mui/material";
import useLanguage from "../hooks/useLanguage";

const Input = ({type, control, name,defaultValue = '', placeholder="", error, variant= "", maxLength, multiline=false}) => {
    const { text } = useLanguage()
    const isPassword = type === 'password'
    const [vis, setVis] = useState(!isPassword)
    const switchVis = () => setVis(vis => !vis)
    const maildomain = undefined // exemple: 'gmail.com'
    const domain = (maildomain) ? maildomain.replace('.', '\\.'): undefined
    return(  
        <Stack>
            <Controller 
                name= {name}
                control={control}
                rules={ (type === 'email') ? {
                    required: text.required,
                    pattern: {
                        value: new RegExp(`^[A-Z0-9._%+-]+@${domain || `[A-Z0-9.-]+\\.[A-Z]{2,}`}$`, 'i'),
                        message: text.invalid
                    }}
                 : {required: text.required}
                }
                defaultValue={defaultValue}
                render={({ field }) =>
                    <OutlinedInput variant={variant} {...field} type={(vis) ? 'text' : 'password'} inputProps={{maxLength:maxLength}} placeholder={placeholder} autoComplete="off"
                        multiline={multiline} rows={multiline? 5 : 1}
                        endAdornment={(isPassword) ?<InputAdornment position="end"><IconButton onClick={switchVis}>{(vis) ?  <VisibilityOff /> : <Visibility/>}</IconButton></InputAdornment>: <></>}/>
                }
            />
            {error && <Typography variant="error">{error.message}</Typography>}
        </Stack>
    )
}
export default Input
