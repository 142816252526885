import { Stack, Typography } from "@mui/material";

export default ({ children }) => {
    return <Stack width="fit-content">
    <Stack sx={{ width: "100%",
        position: "relative",
        "&::before" : {
            content: '""',
            height: "5px",
            width: "50%",
            background: "#000",
            top: "0",
            left: "0",
            position: "absolute",
            transform: "skew(-45deg)",
        },
        "&::after" : {
            content: '""',
            height: "5px",
            width: "50%",
            background: "#000",
            top: "0",
            left: "43%",
            position: "absolute",
            transform: "skew(45deg)",
        },
     }}/>
    <Typography padding="10px 3px" fontWeight="bold">{children}</Typography>
    <Stack sx={{ width: "100%",
        position: "relative",
        "&::before" : {
            content: '""',
            height: "5px",
            width: "50%",
            background: "#000",
            bottom: "0",
            right: "0",
            position: "absolute",
            transform: "skew(-45deg)",
        },
        "&::after" : {
            content: '""',
            height: "5px",
            width: "50%",
            background: "#000",
            bottom: "0",
            right: "43%",
            position: "absolute",
            transform: "skew(45deg)",
        },
     }}/>
</Stack>
}