import React from 'react'
import useLanguage from '../hooks/useLanguage';
import { Box, Typography } from '@mui/material';

const CardProfil = ({profil, active, isVrai}) => {
    const { text } = useLanguage();

    return (
        <Box sx={{ border: active ? '1px solid red' : '1px solid white', p: '10px' }} style={{ backgroundColor: isVrai ? '' : '#252323', position: 'relative', opacity: profil.bani ? '30%' : (profil.suspendu ? '30%' : '100%')}}>
            { profil.bani && 
                <div style={{ position: 'absolute', top: 4, right: 4, background: '#d50000', padding: '0px 6px', borderRadius: '5px', color: 'black', fontSize: '14px', fontWeight: '600', opacity: '100%' }}>
                    {text.banit}
                </div>
            }
            { profil.suspendu && 
                <div style={{ position: 'absolute', top: 4, right: 4, background: '#8e24aa', padding: '0px 6px', borderRadius: '5px', color: 'black', fontSize: '14px', fontWeight: '600', opacity: '100%' }}>
                    {text.suspt}
                </div>
            }
            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                <div>
                    { isVrai ? 
                        <img src="/images/sitetest/avatar3.webp" alt="" height='50px'/>
                        :
                        <img src="/images/sitetest/avatar2.webp" alt="" height='50px'/>
                    }
                </div>
                <div>
                    <div style={{ display: "flex", gap: '4px' }}>
                        <Typography variant='p' sx={{ fontSize: "16px", fontWeight: "600"}}>{text.nomd}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "16px", color: '#dddddd'}}>{profil.name}</Typography>
                    </div>
                    <div style={{ display: "flex", gap: '5px' }}>
                        <Typography variant='p' sx={{ fontSize: "16px", fontWeight: "600"}}>{text.langs}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "16px", color: '#dddddd'}}>{profil.language}</Typography>
                    </div>
                </div>
            </div>
            <div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px"}}>
                    <div style={{width: "50%", display: "flex", gap: '4px' }}>
                        <Typography variant='p' sx={{ fontSize: "13px", color: "orange", fontWeight: "600"}}>{text.ag}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.age}</Typography>
                    </div>
                    <div style={{width: "50%", display: "flex", gap: '5px' }}>
                        <Typography variant='p' sx={{ fontSize: "13px", color: "orange", fontWeight: "600"}}>{text.sexe}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.sex}</Typography>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px"}}>
                    <div style={{width: "50%", display: "flex", gap: '4px' }}>
                        <Typography variant='p' sx={{ fontSize: "13px", color: "green", fontWeight: "600"}}>{text.situation}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.status}</Typography>
                    </div>
                    <div style={{width: "50%", display: "flex", gap: '5px' }}>
                        <Typography variant='p' sx={{ fontSize: "13px", color: "green", fontWeight: "600"}}>{text.searchfor}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.search}</Typography>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px"}}>
                    <div style={{width: "50%", display: "flex", gap: '4px' }}>
                        <Typography variant='p' sx={{ fontSize: "13px", color: "red", fontWeight: "600"}}>{text.pays}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.country}</Typography>
                    </div>
                    <div style={{width: "50%", display: "flex", gap: '5px' }}>
                        <Typography variant='p' sx={{ fontSize: "13px", color: "red", fontWeight: "600"}}>{text.ville}:</Typography>
                        <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.city}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", gap: '4px', marginBottom: "5px" }}>
                    <Typography variant='p' sx={{ fontSize: "13px", color: "yellow", fontWeight: "600"}}>{text.sexor}:</Typography>
                    <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>{profil.oriatation}</Typography>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Typography variant='p' sx={{ fontSize: "13px" , color: "orange", fontWeight: "600"}}>{text.descri}:</Typography>
                    <Typography variant='p' sx={{ fontSize: "13px", color: '#dddddd'}}>
                        {profil.desc}
                    </Typography>
                </div>
            </div>
        </Box>
    )
}

export default CardProfil