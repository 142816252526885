import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";

export default ({onDetails}) => {
    const { text } = useLanguage()
    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.duplicatedmessages}</Title>
        <TableRow header columns={[
            { xs: 2.9,  text: text.language},
            { xs: 2.9,  text: text.platform},
            { xs: 2.9,  text: text.nvaux},
            { xs: 2.9,  text: text.total},
        ]}/>
        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

