import React, { useState, createContext } from "react";

export const GifsContext = createContext();

const GifsProvider = ({ children }) => {
  const [gifs, setGifs] = useState([]);

  const [ globalFilter, setGlobalFilter ] = useState({
    year: '',
    month: '',
    date: '',
    startingDate: '',
    closingDate: '',
    platforms: '',
    agents: '',
    language: '',
    semaine: '',
  })

  return (
    <GifsContext.Provider value={{ gifs, setGifs, globalFilter, setGlobalFilter }}>
      {children}
    </GifsContext.Provider>
  );
};

export default GifsProvider;