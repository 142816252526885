import {  Typography,  Box, Stack , Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React,{useContext} from "react";
import useLanguage from "../../hooks/useLanguage";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { baseURL } from "../../../helper/conf";
import { GifsContext } from "../../state/gifContext";

const TravestiteGifs = ({ setGifs }) =>{
    const { text } = useLanguage()
    const {gifs} = useContext(GifsContext)

    return(
        <Box>
            <Accordion style={{backgroundColor: "transparent", boxShadow: 'none'}}>
                <AccordionSummary  style={{width: "100%", backgroundColor: "#7ed957", cursor: "pointer", minHeight: '20px', height: "30px"}} expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}>
                    <Typography variant="pfsboldwhite" style={{textAlign: "center", width: '100%'}}>{text.travg}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display:'flex',gap:1}}>
                    {gifs.map(gif => gif.type === 'tvg' && gif.typeoption === "femaletranst" && <span key={gif.id} onClick={()=>setGifs(gif)}>
                    <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                    </span>)}
                </AccordionDetails>
            </Accordion>

        </Box>
    )
}

export default TravestiteGifs