import React from "react";
import { Stack, Typography } from "@mui/material";
import useLanguage from "../hooks/useLanguage";

const ConvOnHold = ({stat,inverseDisplay=false, value=0}) =>{
    const {text} = useLanguage()
    return(
        <div className={inverseDisplay ? 'cond-rendering-inv' : 'cond-rendering'} style={{alignSelf: 'center'}}>
            <Stack style={{transform: 'skewX(-15deg)', padding: '0 20px', backgroundColor: '#e3b5b5'}}>
                <Stack style={{padding: '0 20px', backgroundColor: '#d67f7f'}}>
                    <Stack className="padding-change" style={{backgroundColor: '#da0000', display: 'flex', flexDirection: 'row', gap: '5px'}}>
                        <Typography variant="white" flexDirection='row' style={{transform: 'skewX(15deg)'}}>{text.convonhold}:</Typography>
                        <Typography color='#0f0' style={{transform: 'skewX(15deg)'}}>{stat ? stat.conversation.onHold:0}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}

export default ConvOnHold