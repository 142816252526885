import { Stack, Box, Button } from "@mui/material";
import React, { useState } from "react";
import useLanguage from "../hooks/useLanguage";
import CustomFormItem from "./CustomFormItem";
import TimeSelect from "./TimeSelect";

const SearchFilter = ({ selectedDate, onDateChange, items, payment=false, onSemaineClick}) =>{
    const {text} = useLanguage()
    const [activeButton, setActiveButton] = useState(null);

    const handleClick = (weekNumber) => {
        setActiveButton(weekNumber);
        onSemaineClick(weekNumber);
    };

    const getButtonStyle = (weekNumber, backgroundColor) => ({
        backgroundColor,
        maxWidth: '50%',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        color: '#000',
        border: activeButton === weekNumber ? '2px solid #000' : 'none'
    });

    return(
        <Stack spacing={2}>
            <Box className="wrap-content" gap='20px' justifyContent='space-between'>
                <TimeSelect type='year' onChange={e => onDateChange.year(e.target.value)}/>
                <TimeSelect type='month' onChange={e => onDateChange.month(e.target.value)}/>
                {
                    payment ? <></> :<TimeSelect type='date' selected={selectedDate} onChange={onDateChange.date}/>
                }
            </Box>
            {
                payment ? 
                <>
                    <Box className="wrap-content" gap='10px'justifyContent='flex-start'>
                        <Button
                            onClick={() => handleClick(1)}
                            style={getButtonStyle(1, '#00bf62')}
                        >
                            {text.month + '4'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(2)}
                            style={getButtonStyle(2, '#5ce1e6')}
                        >
                            {text.month + '3'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(3)}
                            style={getButtonStyle(3, '#ffde59')}
                        >
                            {text.month + '2'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(4)}
                            style={getButtonStyle(4, '#cb6be6')}
                        >
                            {text.month + '1'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(5)}
                            style={getButtonStyle(5, '#5d17eb')}
                        >
                            {text.month + 'M'}
                        </Button>    
                    </Box>
                    <Stack className="width-resize">
                        {
                            items.map(i =>
                                <CustomFormItem key={i.label} label={i.label} options={{comp: i.comp, selected: i.selected,
                                    onChange: i.onChange, choices: i.choices}}/>
                            )
                        }
                    </Stack>
                </> :
                <>
                    <Box className="wrap-content" gap='10px'justifyContent='flex-start'>
                        <Button
                            onClick={() => handleClick(1)}
                            style={getButtonStyle(1, '#7ed957')}
                        >
                            {text.week + '1'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(2)}
                            style={getButtonStyle(2, '#a5e2ff')}
                        >
                            {text.week + '2'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(3)}
                            style={getButtonStyle(3, '#ffde59')}
                        >
                            {text.week + '3'}
                        </Button>    
                        <Button
                            onClick={() => handleClick(4)}
                            style={getButtonStyle(4, '#ff5757')}
                        >
                            {text.week + '4'}
                        </Button>    
                    </Box>
                    <Stack className="width-resize">
                        {
                            items.map(i =>
                                <CustomFormItem key={i.label} label={i.label} options={{comp: i.comp, selected: i.selected,
                                    onChange: i.onChange, choices: i.choices}}/>
                            )
                        }
                    </Stack>
                </>
            }
        </Stack>
    )
}

export default SearchFilter