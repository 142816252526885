import { Typography, Box, Stack, Accordion, AccordionDetails, AccordionSummary, IconButton, DialogActions, Button, DialogTitle, DialogContent, TextField, Badge, styled, MenuItem, Select, FormControl } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import useLanguage from "../../hooks/useLanguage.jsx";
import TabOtherInfo1 from "./TabOtherInfo1.jsx";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useData from "../../hooks/useData.jsx";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomAlert from "../../Admin/CustomAlert.jsx";
import { AuthStateContext } from "../../state/AuthStateContext.jsx";
import CustomPopup from "../../Admin/CustomPopup.jsx";
import axiosInstance from "../../../axios.js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import AuthFormRow from "../../tools/AuthFormRow.jsx";
import { formatDate } from "../../../helper/formatDate.js";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MessageContext } from "../../state/MessageContext.jsx";
import TabOtherInfoEsq from "./TabOtherInfoEsq.jsx";
import FormRow from "../../tools/FormRow.jsx";
import { MeetStateContext } from "../../state/MeetingContext.jsx";

const YellowNotificationsIcon = styled(NotificationsIcon)(({ theme }) => ({
    color: '#FFAA00',
}));

const Esquive = ({ type, meet }) => {
    
    const { authState } = useContext(AuthStateContext)
    const { messageState } = useContext(MessageContext)
    const { setMeet, meetState } = useContext(MeetStateContext)
    // const {meet,setMeet} = useMessage()
    const { text } = useLanguage()
    const { typeMeet } = useData()
    const placeRef = useRef(null)
    const esqvRef = useRef(null)
    const [loading, setLoading] = useState()
    const [alert, setAlert] = useState()
    const [alertContenu, setAlertContenu] = useState()

    // ADD MEETING
    const [meetToAdd, setMeetToAdd] = useState()
    const [openAdd, setOpenAdd] = useState(null)
    const [date, setDate] = useState(new Date());
    const [heure, setHeure] = useState(new Date());

    const vHeure = heure.toISOString().slice(11, 16).split(":").map((v, i) => i == 0 ? (parseInt(v) + 3).toString() : v).join(":")

    useEffect(() => {
        if (openAdd) console.log(openAdd)
    }, [openAdd])
    

    // DELETE MEETING
    const [meetToDelete, setMeetToDelete] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/meeting/delete/${meetToDelete?.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
            .then((response) => {
                console.log(response.data)
                setAlertContenu(text.delalert)
                setAlert(true)
                // setMeeting((last) => last.filter((a) => a.id !== meetToDelete?.id))
                setLoading(false)
                setOpenDelete(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }
    
    const EsquiveC = () => {
        const { control,handleSubmit,formState: { errors } } = useForm()

        const handleAdd = data => {
            if(data.esqv !== openAdd.esquive){
                const datas = {id:openAdd.id,esqv:data.esqv}
                setLoading(true)
                axiosInstance.post("/Meeting/AddEsqv",datas, {
                    headers: {
                        'Authorization': `Bearer ${authState.token}`
                    }
                })
                    .then((response) => {
                        console.log(response.data)
                        const meet = meetState.map(p=> p.id === datas.id ? {...p,esquive:datas.esqv}:p)
                        setMeet(meet)
                        setLoading(false)
                        setOpenAdd(null)
                    })
                    .catch((error) => {
                        console.log(error)
                        setLoading(false)
                    })
            }else setOpenAdd(null)
        }

        return (
            <div style={{ padding: "18px 30px" }}>
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                <p style={{ fontWeight: "800", fontSize: "22px" }}>
                    {text.propesqu} :
                </p>
            </DialogTitle>
            <DialogContent>
                {openAdd && <Box flexDirection="row">
        <div style={{ marginBottom: "12px" }}>
            <FormRow bgCol="#6b8bbb" txtCol="#fff" value={openAdd.place} label={text.plc} comp="readOnly" control={control} name="place" error={errors.place} />
        </div>
        <div style={{ marginBottom: "12px" }}>
            <FormRow bgCol="#6b8bbb" txtCol="#fff" value={openAdd.date} label={text.date} comp="readOnly" control={control} name="date" error={errors.date} />
        </div>
        <div style={{ marginBottom: "12px" }}>
            <FormRow bgCol="#6b8bbb" txtCol="#fff" value={openAdd.time} label={text.heure} comp="readOnly" control={control} name="heure" error={errors.heure} />
        </div>
        <div style={{ marginBottom: "12px" }}>
            <FormRow bgCol="#6b8bbb" txtCol="#fff" defaultValue={openAdd.esquive ? openAdd.esquive : ''} label={text.esqv} control={control} name="esqv" error={errors.esqv} />
        </div>
    </Box>}
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    sx={{
                        fontWeight: "700",
                        bgcolor: "error.main",
                        color: "white",
                        "&:hover": {
                            bgcolor: "error.dark",
                        }
                    }}
                    onClick={() => setOpenAdd(null)}
                >
                    {text.annuler}
                </Button>
                <Button
                    variant='contained'
                    sx={{
                        fontWeight: "700",
                        bgcolor: "success.main",
                        color: "white",
                        "&:hover": {
                            bgcolor: "success.dark",
                        }
                    }}
                    onClick={handleSubmit(handleAdd)}
                >
                    {loading ? text.phload : text.cnfrm}
                </Button>
            </DialogActions>
        </div>
        )
    }
    return (
        <Box padding=" 10px 00px">
            <CustomAlert
                open={alert}
                autoHideDuration={1500}
                onClose={() => setAlert(false)}
                severity="success"
                message={alertContenu}
            />

            {/* pop up add */}
            <CustomPopup open={openAdd ? true : false} onClose={() => setOpenAdd(null)}>
               <EsquiveC/>
            </CustomPopup>

            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px" }}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "800", fontSize: "22px" }}>
                            {text.dltrdv}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.obj} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.object}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.date}:
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.date}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.plc} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.place}
                                </Stack>
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                }
                            }}
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "success.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                }
                            }}
                            onClick={() => handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>

            <Accordion style={{ backgroundColor: "transparent", boxShadow: 'none' }}>

                <AccordionSummary style={{ minHeight: '20px',backgroundColor: "#fb5c49", height: "30px", padding: '0', marginBottom: "4px"}} >
                    <Typography variant="white" style={{  textAlign: 'center', width: "85%" }}>
                        {text.esquive}
                    </Typography>
                    <Stack>
                        <Badge badgeContent={meetState?.length} color="secondary" showZero>
                            <YellowNotificationsIcon  style={{color:'#f9e238'}} />
                        </Badge>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0' }}>
                    <Stack spacing={'2px'}>
                        <TabOtherInfoEsq header columns={[
                            { xs: 3, text: text.plc, color: '#fff' },
                            { xs: 3, text: text.date, color: '#fff' },
                            { xs: 2.5, text: text.heure, color: '#fff' },
                            { xs: 3.2, text: text.esqv, color: '#fff' },
                            // { xs: 1, text: text.actions, color: '#fff' },
                        ]} />
                        {
                            meetState?.map((mt, i) =>
                                <TabOtherInfoEsq mt={mt} type={type} row={i} key={'mt' + i} openAdd={setOpenAdd} columns={[
                                    { xs: 3, text: mt.place },
                                    { xs: 3, text: mt.date },
                                    { xs: 2.5, text: mt.time },
                                    { xs: 3.2, text: mt.esquive }
                                ]} />
                            )
                        }
                    </Stack>
                </AccordionDetails>
            </Accordion>

        </Box>
    )
}

export default Esquive