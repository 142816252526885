import React, { createContext, useState } from 'react';

const MeetStateContext = createContext(false);

const MeetStateProvider = ({children}) => {
    const [stat, setStat] = useState([])
    const setState = (content) => {
        setStat(content)
    }

    return (
        <MeetStateContext.Provider value={{
            meetState:stat,
            setMeet:setState,
        }}>
            {children}
        </MeetStateContext.Provider>
    )
}
export {MeetStateContext, MeetStateProvider}