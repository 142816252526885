import React, { useState } from "react";
import { Box, Fade, Grid, Paper, Popper, Typography } from "@mui/material";
import SlidingTypography from "./SlidingTypography";
import useLanguage from "../hooks/useLanguage";
import { checkPop } from "../../helper/formatDate";
import { PopperS } from "./Snackbars";

const TableRow = ({yellow = false, columns, header = false, footer = false, end=false, clickT, number, type, stat=null, ind,custombg=false }) => {
    const {text} = useLanguage()
    const [pop, setPop] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [placement, setPlacement] = React.useState();
    const [profil,setProfil] = useState(null)
    const handleClick = (event, newPlacement,ind) => { //stat 7
        if(checkPop(setProfil,ind,stat,number)){
            setAnchorEl(event.currentTarget);
            setPlacement(newPlacement);
            setPop(true)
        }
    };
    const checkClick = (i) => {
        if(stat === 8) return false
        if(clickT && number && (type === 2 ? i < columns.length - 3 : i < columns.length - 1)) return true
        return false
    }

    const getContent = ({ customContent, content, color, text }) => {
        if (customContent) return content
        if (header) return <Typography style={{ color: color ? color : '#000', fontFamily: 'Chewy Pro', textAlign: "center" }}>{text}</Typography>
        if (footer) return <Typography style={{ color: color ? color : '#000', fontFamily: 'Chewy Pro', textAlign: "center" }}>{text}</Typography>
        return <SlidingTypography color={color ? color : '#000'} delm textAlign="center" fast>{text}</SlidingTypography>
    }
    return (
        <Grid className="gap-resize" container style={{ minHeight: '50px', justifyContent: end ? "flex-end" : undefined }}>
           <PopperS pop={pop} anchorEl={anchorEl} placement={placement} profil={profil} text={text} types={1}/>
            {
                columns.map((cell, i) =>
                    <Grid key={i} onMouseLeave={() => ind && stat && setPop(false)} onMouseEnter={e => ind && stat && handleClick(e, 'top',i)} style={{
                        overflow: 'hidden', display: 'flex',
                        alignItems: "center", justifyContent: 'center', cursor: number ? 'pointer' : ''
                    }} item xs={cell.xs}
                        variant={header ? stat === 9 ? '#fff' : yellow ? "yellowbg" : "primarybg"  : stat === 9 ? 'quartertransparentprimarybg' : yellow ? "halfyellowbg" : ((i == 0) ? "halftransparentprimarybg" : "quartertransparentprimarybg")} onClick={() => checkClick(i) ? clickT(number) : {}}>
                        {getContent(cell)}
                    </Grid>
                )
            }
        </Grid>
    )
}

export default TableRow