import React, { useState, useEffect } from "react";
import { Typography, Stack } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
const getHoursMinutes = () => {
    const handleTwoChars = val => val<10 ? '0'+val : val
    const date = new Date()
    const hours = handleTwoChars(date.getUTCHours())
    const minutes = handleTwoChars(date.getUTCMinutes())
    return hours + ':' + minutes
}

const TimeDisplayer = () =>{
    const { text } = useLanguage()
    const [time, setTime] = useState(getHoursMinutes())

    useEffect(()=>{
        const timeInterval = setInterval(()=>{
            setTime(getHoursMinutes())
        }, 60000)
        return () => clearInterval(timeInterval)
    }, [])
    return(
        <Stack display="flex" flexDirection="row" gap={1} style={{color: "#fb7a6a", textShadow: '0px 0px 5px #9c311f'}}>
            <Typography style={{fontWeight: "bold"}}>{text.time}</Typography>
            <Typography style={{fontWeight: "bold"}}>{time}</Typography>

        </Stack>
    )
}

export default TimeDisplayer