import { Stack, Typography, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const DuplicatedMessages = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const {languagesList} = useData()
    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        agents: '',
        idconv: '',
        idmess: '',
    })
    const { duplicatedMessages } = useData()
    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#000', textShadow: '3px 3px 3px #83dbbc'}}
                >{text.duplicatedmessages}</Typography> */}
                <Title>{text.duplicatedmessages}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/11du.png"} alt="" height='50px' width='50px'/> */}
            </Stack>
            <BigContainerAccordion 
                transparent
                summaryBg="#ff5757"
                summary={text.lstdplmss}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.agents , comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                                { label: text.idconv , comp: 'TextInput', onChange: e => setFilter({...filter, idconv: e.target.value}) },
                                { label: text.idmess , comp: 'TextInput', onChange: e => setFilter({...filter, idmess: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.6,  text: text.date},
                                { xs: 1.6,  text: text.platform},
                                { xs: 1.6, text: text.language },
                                { xs: 1.6,  text: text.idconv},
                                { xs: 1.6,  text: text.iddplmss},
                                { xs: 1.6,  text: text.agent},
                                { xs: 1.6,  text: text.actions},
                            ]}/>
                            {
                                duplicatedMessages.map((dm, i) =>
                                    <TableRow key={`dm${i}`} columns={[
                                        { xs: 1.6,  text: dm.date},
                                        { xs: 1.6,  text: dm.platform},
                                        { xs: 1.6, text: dm.language ? dm.language.charAt(0).toUpperCase() + dm.language.charAt(1) : 'Fr' },
                                        { xs: 1.6,  text: dm.idconv},
                                        { xs: 1.6,  text: dm.idmess},
                                        { xs: 1.6,  text: dm.agent},
                                        {
                                            xs: 1.6,
                                            customContent: true,
                                            content: <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.infrmagnt}</Typography></Button>
                                        }
                                    ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default DuplicatedMessages