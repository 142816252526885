import React, { useEffect, useState } from "react";
import { IconButton, Stack, Menu, MenuItem, Typography } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import useLanguage from "../hooks/useLanguage";
import useData from "../hooks/useData";
import { Icon } from "@mui/material";
import RoutesList from "../../helper/routes";

const Language = ({ redirectRoute= RoutesList.operator, icon=false }) =>{
    const { setLanguage } = useLanguage()
    const { languagesChangeList: languagesList } = useData()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => languagesList.length ? setAnchorEl(event.currentTarget) : {}
    const handleClose = () => setAnchorEl(null)
    const [colored, setColored] = useState(false)
    useEffect(()=> setColored(anchorEl != null) , [anchorEl])
    
    return(
        <Stack>
            {
                icon ? <LanguageIcon onClick={handleClick} style={{cursor: 'pointer'}}/> :
                <IconButton
                onMouseEnter={()=>setColored(true)} style={{color: colored ? '#ff9999': '#1C1B1B'}}
                onMouseLeave={()=> {if (!anchorEl) setColored(false)}}
                variant='head'
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                ><LanguageIcon/>
                </IconButton>
            }
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}>
                {
                    languagesList.map( language =>
                        <MenuItem key={language.value} onClick={() => {setLanguage(language.value, redirectRoute); handleClose()}}><Typography><Icon>{language.icon}</Icon> {language.innerHTML}</Typography></MenuItem>
                    )
                }
            </Menu>
        </Stack>
    )
}
export default Language
