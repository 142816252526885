import {useContext,useState,useEffect, memo} from 'react'
import { MessageContext } from "../state/MessageContext";
import useMessage from "../hooks/useMessage";
import { Stack } from "@mui/material";

const Timer = () => {
    const {messageState,setOpen} = useContext(MessageContext)
    const {sendMessage}  = useMessage()
    const [timer,setTimers] = useState({min:5,sec:0})
    const TimeC = ({tm}) =>  <span>{tm < 10 ? '0':''}{tm}</span>
     useEffect(()=>{
    let t
        if(timer.sec || timer.min){
            t = setTimeout(()=>{
                if(timer.sec === 0) setTimers({sec:59,min:timer.min - 1})
                else setTimers({...timer,sec:timer.sec - 1})
            },1000)
        }else{
            sendMessage(false,messageState.message_data[messageState.message_data.length-1].content)
            setOpen(2)
            clearTimeout(t)
        }
    return()=> clearTimeout(t)
},[timer.sec])
    return(
        <Stack sx={{position:'absolute',width:'100%',placeContent:'center',fontWeight:'bold',fontSize:'2.5em',gap:1,color:'#000',flexDirection:'row'}}>
            <TimeC tm={timer.min}/>:<TimeC tm={timer.sec}/>
        </Stack>
    )
}

const TimerC = memo(() => {
    const {messageState} = useContext(MessageContext)
    return(
        <Stack sx={{ flex: 1, display: 'flex', justifyContent: 'center', placeItems: 'center' }}>
            {messageState && <Timer/>}
        </Stack>
    )
})
export default TimerC