import React, { useContext, useEffect, useState } from "react";
import { Badge, Box, Divider, ListItemButton, ListItemText, Menu, Stack, Typography } from "@mui/material";
import Language from "../Language/Language";
import UserOptions from "./UserOptions";
import useData from "../hooks/useData";
import ConvOnHold from "../Supervisor/ConvOnHold";
import { Notification } from "../tools/Snackbars";

const SupervisorHeader = ({ stat, setOpen, setState }) => {
    const { roles } = useData()
    return (
        <Stack variant="head" height='70px' padding='0 10px' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <img src="/images/HeaderImage/logo.png" alt="" height='50px' width='50px' />
            <div style={{ cursor: "pointer" }} onClick={() => setState(1)} >
                <ConvOnHold stat={stat} />
            </div>
            <Stack className="header-opt" flexDirection='row' style={{ placeItems: 'center' }} gap={2}>
                <Notification/>
                <UserOptions setOpen={setOpen} variant={roles.supervisor} />
                <Language />
            </Stack>

        </Stack>
    )
}

export default SupervisorHeader
