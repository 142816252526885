import { Stack, Typography, Grid, Button } from "@mui/material";
import React from "react";
import useLanguage from "../hooks/useLanguage";
import SlidingTypography from "./SlidingTypography";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AdminCard = ({ imgSrc, label, fields=[], onDetails, borderFile, view=false, isKid, type }) =>{
    const {text} = useLanguage()
    const baseUrl = '/images/admin/'
    return(
        <Stack className="admin-card" position='relative'>
             <img src={baseUrl + borderFile} alt="" style={{position: 'absolute', width: '100%', height: '100%'}}/>
            <Stack paddingY="40px" style={{height:'100%', justifyContent: 'space-between'}}>
                <Stack paddingX="40px">
                    <SlidingTypography fast textAlign="center" delm variant="h3">
                        {label}
                    </SlidingTypography>
                </Stack>
                <div style={{alignSelf: 'center', paddingLeft: "40px", paddingRight: "40px" }}>
                    <img src={imgSrc} alt="" height={type === "logbook" ? '50px' : (type === "compte" ? '70px' : '100px')} width={type === "logbook" ? '50px' : '100px'} />
                </div>
                {type === "logbook" ? 
                    <Stack display='flex' alignItems='center' justifyContent='center' paddingX="15px">
                        <Stack display='flex' alignItems='center' justifyContent='center' width='98%' spacing={1} >
                            <Grid container gap='2px'>
                                <Grid item xs={5.4}>
                                                                 
                                </Grid>
                                <Grid item xs={3.1} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                        {text.boot}
                                    </SlidingTypography>
                                </Grid>
                                <Grid item xs={3.1} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                        {text.user}
                                    </SlidingTypography>
                                </Grid>
                            </Grid>
                            <Grid container gap='2px'>
                                <Grid item xs={5.4}>
                                                                 
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CheckCircleIcon 
                                            style={{
                                                color: 'green', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CancelIcon 
                                            style={{
                                                color: 'red', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CheckCircleIcon
                                            style={{
                                                color: 'green', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CancelIcon 
                                            style={{
                                                color: 'red', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                fields?.map(f =>
                                    <Grid key={f.label} container gap='2px'>
                                        <Grid item xs={5.4} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.label}:
                                            </SlidingTypography>                               
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.boot?.prise}
                                            </SlidingTypography>
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.boot?.nprise}
                                            </SlidingTypography>
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.user?.prise}
                                            </SlidingTypography>
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.user?.nprise}
                                            </SlidingTypography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Stack>
                    </Stack>
                    :
                    <Stack display='flex' alignItems='center' justifyContent='center' paddingX={ type === "compte" ? "15px" : ( type == "controle" ? "20px" : "40px") }>
                        <Stack display='flex' alignItems='center' justifyContent='center' width='95%' spacing={1} >
                            {
                                fields.map(f =>
                                    <Grid key={f.label} container gap='5px'>
                                        <Grid item xs={isKid || type === "controle" ? 8 : 5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>{f.label}:</SlidingTypography>                               
                                        </Grid>
                                        <Grid item xs={isKid || type === "controle" ? 3 : 6} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>{f.value}</SlidingTypography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Stack>
                    </Stack>
                }
                <Stack paddingX="40px" marginTop="5px">
                    <Button onClick={onDetails} style={{backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}><Typography style={{fontFamily: 'Delm'}}>{view ? text.vw : text.details}</Typography></Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default AdminCard