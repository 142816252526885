import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useLanguage from '../hooks/useLanguage'
import { Link } from 'react-router-dom'
import Language from '../Language/Language'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axios'

const Home = () => {
  const { text } = useLanguage();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const endDate = localStorage.getItem('endDate');
  const [ok, setOk] = useState()

  useEffect(()=>{
    if(!token || !endDate){
        navigate('/login');
    }else{
        axiosInstance.post('/interface/token',{
            "date" : endDate,
        })
        .then((response) => {
            console.log(response)
            if(response.data.message != "OK"){
                navigate('/login');
            }
            setOk(response.data.message)
        })
        .catch((error) => {
            console.log(error)
        })
    }
  }, [])


if(ok == "OK"){

  return (
    <div style={{ maxWidth: "100%", minHeight: "100vh", background: "black" }} >
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray"}}>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <img src="/images/HeaderImage/logo.png" alt="" height='50px'/>
            </Stack>
            <Typography variant='h4' style={{fontWeight: 'bold', color: "white"}}>
                {text.inter}
            </Typography>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Language redirectRoute={'/SendMessage'} />
            </Stack>
        </div>
        <div style={{ width: "100%", minHeight: "89vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "60%"}}>
                <div style={{ marginBottom: "20px", color: "white"}}>
                    <Typography variant='h2' style={{fontWeight: 'bold', marginBottom: '2px', color: 'orange'}}>
                        {text.biev},
                    </Typography>
                    <Typography variant='h4'  style={{fontWeight: 'bold', marginBottom: '2px', color: '#dddddd'}}>
                        {text.interSel} :
                    </Typography>
                </div>
                <Grid container sx={{ padding: "20px", background: "linear-gradient(135deg, #f3e5f5 0%, #e1f5fe 100%)", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)", borderRadius: "10px"}}>
                    {/* Send Message */}
                    <Grid item sm={6} lg={6} sx={{padding: "5px"}} >
                        <Box sx={{ cursor: 'pointer', height: "150px", bgcolor: "#FFCDD2", border: "2px solid #000", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", paddingX: "10px"}} >
                            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                                <img src="/images/sitetest/send.png" alt="" height='50px'/>
                            </Stack>
                            <Link to="/sendmessage" style={{ textDecoration: 'none', color: 'black' }}>
                                <Stack direction="row">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.tache} :
                                    </Typography>
                                    <Typography>
                                        {text.tit1}
                                    </Typography>
                                </Stack>
                                <Stack direction="column">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.descri} :
                                    </Typography>
                                    <Typography variant='p'>
                                        {text.des1}
                                    </Typography>
                                </Stack>
                            </Link>
                        </Box>
                    </Grid>
                    {/* Teaser Message */}
                    <Grid item sm={6} lg={6} sx={{padding: "5px"}} >
                        <Box sx={{ cursor: 'pointer', height: "150px", bgcolor: "#C8E6C9", border: "2px solid #000", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", paddingX: "10px"}} >
                            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                                <img src="/images/sitetest/accroche.png" alt="" height='50px'/>
                            </Stack>
                            <Link to="/teasermessage" style={{ textDecoration: 'none', color: 'black' }}>
                                <Stack direction="row">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.tache} :
                                    </Typography>
                                    <Typography>
                                        {text.tit2}
                                    </Typography>
                                </Stack>
                                <Stack direction="column">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.descri} :
                                    </Typography>
                                    <Typography variant='p'>
                                        {text.des2}
                                    </Typography>
                                </Stack>
                            </Link>
                        </Box>
                    </Grid>
                    {/* Poke Message */}
                    <Grid item sm={6} lg={6} sx={{padding: "5px"}} >
                        <Box sx={{ cursor: 'pointer', height: "150px", bgcolor: "#FFF9C4", border: "2px solid #000", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", paddingX: "10px"}} >
                            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                                <img src="/images/sitetest/poke.png" alt="" height='50px'/>
                            </Stack>
                            <Link to="/pokemessage" style={{ textDecoration: 'none', color: 'black' }}>
                                <Stack direction="row">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.tache} :
                                    </Typography>
                                    <Typography>
                                        {text.tit3}
                                    </Typography>
                                </Stack>
                                <Stack direction="column">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.descri} :
                                    </Typography>
                                    <Typography variant='p'>
                                        {text.des3}
                                    </Typography>
                                </Stack>
                            </Link>
                        </Box>
                    </Grid>
                    {/* Stop Message */}
                    <Grid item sm={6} lg={6} sx={{padding: "5px"}} >
                        <Box sx={{ cursor: 'pointer', height: "150px", bgcolor: "#BBDEFB", border: "2px solid #000", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", paddingX: "10px"}} >
                            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                                <img src="/images/sitetest/stop.png" alt="" height='50px'/>
                            </Stack>
                            <Link to="/stopmessage" style={{ textDecoration: 'none', color: 'black' }}>
                                <Stack direction="row">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.tache} :
                                    </Typography>
                                    <Typography>
                                        {text.tit4}
                                    </Typography>
                                </Stack>
                                <Stack direction="column">
                                    <Typography sx={{ fontWeight: "700", marginRight: "2px"}}>
                                        {text.descri} :
                                    </Typography>
                                    <Typography variant='p'>
                                        {text.des4}
                                    </Typography>
                                </Stack>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    </div>
  )}else{
    return(
        <div style={{ maxWidth: "100%", height: "100vh", background: "black" }} >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <CircularProgress />
            </Box>
        </div>
    )
  }
}

export default Home