import React, { useContext, useEffect, useState } from "react";
import { Badge, Box, Button, Divider, ListItemButton, ListItemText, Menu, Stack, Typography } from "@mui/material";
import Language from "../Language/Language";
import UserOptions from "./UserOptions";
import useData from "../hooks/useData";
import ConvOnHold from "../Supervisor/ConvOnHold";
import { Notification } from "../tools/Snackbars";
import {  useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";


const AdminHeader = ({ stat, setState }) => {
    const { roles } = useData()
    const navigate = useNavigate()


    return (
        <Stack variant="head" height='70px' padding='0 10px' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <img src="/images/HeaderImage/logo.png" alt="" height='50px' width='50px' />
            <div style={{ cursor: "pointer" }} onClick={() => setState(1)} >
                <ConvOnHold stat={stat} />
            </div>
            <Stack className="header-opt" flexDirection='row' gap={2} style={{ placeItems: 'center' }}>
                <Button variant= "formrow" onClick={()=>navigate(RoutesList.adminNewFinal)}>New design</Button>
                <Notification/>
                <UserOptions variant={roles.admin} />
                <Language />
            </Stack>

        </Stack>
    )
}

export default AdminHeader
