import { Alert, Snackbar } from '@mui/material';
import React from 'react';


const CustomAlert = ({ open, autoHideDuration, onClose, severity, message }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;