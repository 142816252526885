import React, { useEffect, useState, useContext } from "react";
import useAuth from "../hooks/useAuth";
import { Stack, Typography, MenuItem, Button, Grid, Dialog, DialogContent, DialogActions } from "@mui/material";
import useLanguage from '../hooks/useLanguage'
import { MessageContext } from "../state/MessageContext";
import LogoutIcon from '@mui/icons-material/Logout';

const Logout = ({icon = false}) => {
    const { logout } = useAuth()
    const [activated, setActivated] = useState(false)
    const {messageState} = useContext(MessageContext)
    const switchVisible = () => setActivated(act => !act)
    const { text } = useLanguage()
    return(
        <Stack width='100%'>
            {
                icon ? <LogoutIcon onClick={switchVisible} style={{cursor: 'pointer'}}/> : 
                <MenuItem disabled={messageState ? true:false} style={{backgroundColor: '#f61b01', justifyContent: 'center'}} onClick={switchVisible}>
                    <Typography variant="white">{text.logout}</Typography>   
                </MenuItem>
            }
            <Dialog
                open={activated}
                keepMounted
                onClose={switchVisible}
            >
            <Stack variant="popup">
                <DialogContent><Typography>{text.qlogout}</Typography></DialogContent>
                <DialogActions style={{ width: '100%' }}>
                    <Button onClick={logout} style={{width: '50%', color: '#fff', }} sx={{ transition: '.5s', '&:hover':{ transform: 'scale(1.35)' }}}>{text.yes}</Button>
                    <Button onClick={switchVisible} style={{width: '50%', color: '#fff',}} sx={{ transition: '.5s', '&:hover':{ transform: 'scale(1.35)' }}}>{text.no}</Button>
                </DialogActions> 
            </Stack>
            </Dialog>         
        </Stack>
    )
} 

export default Logout