import React, {useContext} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";

export default ({onDetails}) => {
    const { text } = useLanguage()
    const { stat } = useContext(StatContext);

    const languages = Object.keys(stat?.agents ?? {});

    const StatAgentTab = (language) => {
        
        return (
            <TableRow columns={[
                { xs: 3.8,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                
                { xs: 3.8,
                    customContent: true,
                    content: 
                        <Typography >
                            {stat.agents[language]?.online}
                        </Typography>
                },
                { xs: 3.8,
                    customContent: true,
                    content: 
                        <Typography >
                            {stat.agents[language]?.total}
                        </Typography>
                },
            ]}/>
        );
    };

    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.agman}</Title>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 3.8,  text: text.language},
                { xs: 3.8,  text: text.onl},
                { xs: 3.8,  text: text.total},
            ]}/>
            {languages.map((language) => (<Stack key={language}>{StatAgentTab(language)}</Stack>))}
        </Stack>

        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

