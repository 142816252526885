import React,{useState} from "react";
import { Box, Fade, Grid, Paper, Popper, Typography } from "@mui/material";
import SlidingTypography from "../../tools/SlidingTypography";
import useLanguage from "../../hooks/useLanguage";

const TabOtherInfo3 = ({columns, header=false, mt=null, row=null}) =>{
    const { text } = useLanguage()
    const [pop, setPop] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [placement, setPlacement] = React.useState();
    const handleClick = (event,newPlacement) => {
      setAnchorEl(event.currentTarget);
      setPlacement(newPlacement);
      setPop(true)
    };
    const getContent = ({customContent, content, color, text},i) =>{
        if (customContent) return content
        if (header) return <SlidingTypography delm color={color ? color : '#000'} textAlign="center" fast>{text}</SlidingTypography>
        return <SlidingTypography color={color ? color : (mt && i === 0) ? '#fff':'#000'} delm textAlign="center" fast>{text}</SlidingTypography>
    }
    return(
        <Grid onMouseLeave={() => row !== null && setPop(false)} onMouseEnter={e => row !== null && handleClick(e,'left')} container style={{ minHeight: '50px', gap: '2px', cursor: 'pointer' }}>
            <Popper
                sx={{ zIndex: 1200 }}
                open={pop}
                anchorEl={anchorEl}
                placement={placement}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Box style={{ fontFamily: 'Delm', fontWeight: 800 ,padding:'10px'}}>
                                <p style={{ fontSize: '1.5em' }}>{text.nat} : {mt.nature}</p>
                                <hr style={{ borderBottom: '1px solid #000' }} />
                                <p style={{ fontSize: '1.5em' }}>{text.infs} : {mt.content}</p>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            {
                columns.map((cell, i) =>
                    <Grid key={'item' + i} style={{overflow: 'hidden', display: 'flex', 
                        alignItems: "center", justifyContent: 'center'}} item xs={cell.xs}
                        bgcolor ={header ? "#604789" : ((i == 0) ? "#64483b" : "#ece3ff")}>
                        { getContent(cell,i) }
                    </Grid>
                )
            }
        </Grid>
    )
}

export default TabOtherInfo3