import React, { useEffect, useState } from "react";
import getText from "../../helper/texts"
import { useParams, useNavigate, useLocation } from "react-router-dom";

const cnlangtoken = 'chat-tool-lang'

const useLanguage = () =>{
    const eventName = 'changeLanguage'
    const { lang } = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const getLang = () => {
        if (['fr', 'en', 'it', 'nl', 'es', 'de'].includes(lang)){
            localStorage.setItem(cnlangtoken, lang)
            return lang
        }
        const nlang = localStorage.getItem(cnlangtoken) || 'en'
        if (lang){
            let paths = pathname.split('/')
            paths.pop()
            const rootpath = paths.join('/')
            navigate(rootpath + '/' + nlang)
        }
        return nlang
    }
    const [currentLanguage, setCurrentLanguage] = useState(null)
    useEffect(() => { setCurrentLanguage(getLang()) }, [])
    useEffect(()=>{
        window.addEventListener(eventName, e => { setCurrentLanguage(e.detail); localStorage.setItem(cnlangtoken, e.detail) })
        return () => window.removeEventListener(eventName, e => { setCurrentLanguage(e.detail); localStorage.setItem(cnlangtoken, e.detail) })
    }, [])
    const setLanguage = lang =>{
        localStorage.setItem(cnlangtoken, lang)
        const e = new CustomEvent(eventName, {detail: lang})
        window.dispatchEvent(e)
        let paths = pathname.split('/')
        if (paths[1] !== 'login' && paths[1] !== 'home'
         && paths[1] !== 'sendmessage' && paths[1] !== 'sendMessage'
         && paths[1] !== 'teasermessage'
         && paths[1] !== 'pokemessage'
         && paths[1] !== 'stopmessage') {
            paths.pop()
            const rootpath = paths.join('/')
            navigate(rootpath + '/' + lang)
        }
        
    }
    const text = getText(currentLanguage)
    return { text, currentLanguage, setLanguage }
}

export default useLanguage
