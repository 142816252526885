const RoutesList = {
    landing: '/',
    lang: '/lang/',
    auth: '/auth/',
    register: '/register/',
    operator: '/operator/',
    supervisor: '/supervisor/',
    admin: '/admin/',
    qualitycontroller: '/quality-controller/',
    accountant: '/accountant/',
    statistics: '/statistics/',
    pp: '/privacy-policy/',
    adminNew: '/admin_new/',
    
    authFinal: '/auth/:lang',
    registerFinal:'/register/:lang',
    operatorFinal: '/operator/:lang',
    adminFinal: '/admin/:lang',
    supervisorFinal: '/supervisor/:lang',
    qualitycontrollerFinal: '/quality-controller/:lang',
    accountantFinal: '/accountant/:lang',
    statisticsFinal: '/statistics/:lang',
    ppFinal: '/privacy-policy/:lang',
    adminNewFinal: '/admin_new/:lang',
}
export default RoutesList