import { Stack, Typography, Grid, Button } from "@mui/material";
import React from "react";
import useLanguage from "../hooks/useLanguage";
import SlidingTypography from "./SlidingTypography";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SupervisorCard = ({borderColor, imgFile, label, fields, onDetails, shadowColor, isKid, type}) =>{
    const {text} = useLanguage()
    const baseUrl = '/images/supervisor/'
    return(
        <Stack className="supervisor-card" style={{position: 'relative'}}>
            <Stack style={{position: 'absolute', top: '-10px', width: 'calc(100% + 20px)', left: '-10px', height: 'calc(100% + 20px)', borderRadius: '15px', background: shadowColor, zIndex: '-1', filter: 'blur(10px)'}}/>
            <Stack style={{ background: borderColor, borderRadius: '15px', zIndex: '2', padding: '3px', width: '100%', height: '100%'}}>
                <Stack style={{position: 'relative', overflow: 'hidden', borderRadius: '12px', width: '100%', height: '100%'}}>
                    <Stack style={{position: 'absolute', top: '0px', width: '100%', left: '0px', height: '100%', background: shadowColor, zIndex: '-1', filter: 'blur(10px)'}}/>
                    <Stack padding='15px' style={{width: '100%', height: '100%', boxSizing: 'border-box'}}>
                        <Stack style={{background: '#fff',width: '100%', height: '100%', alignItems: 'center', borderRadius: '12px', boxShadow: '0px 0px 9px 6px #fff', display: 'flex', justifyContent: 'space-between'}}>
                            <SlidingTypography fast textAlign="center" delm variant="h3">
                                {label}
                            </SlidingTypography>
                            <div style={{alignSelf: 'center', paddingLeft: "40px", paddingRight: "40px" }}>
                                <img src={baseUrl + imgFile } alt="" height={type === "logbook" ? '50px' : '100px' } width={type === "logbook" ? '50px' : '100px'} />
                            </div>
                            {type === "logbook" ?  
                                <Stack display='flex' alignItems='center' justifyContent='center' width='98%' spacing={1}>
                                    <Stack display='flex' alignItems='center' justifyContent='center' width='98%' spacing={1} >
                                        <Grid container gap='2px'>
                                            <Grid item xs={5.4}>
                                                                            
                                            </Grid>
                                            <Grid item xs={3.1} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                                <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                    {text.boot}
                                                </SlidingTypography>
                                            </Grid>
                                            <Grid item xs={3.1} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                                <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                    {text.user}
                                                </SlidingTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid container gap='2px'>
                                            <Grid item xs={5.4}>
                                                                            
                                            </Grid>
                                            
                                            <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CheckCircleIcon 
                                            style={{
                                                color: 'green', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CancelIcon 
                                            style={{
                                                color: 'red', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CheckCircleIcon
                                            style={{
                                                color: 'green', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                    <Typography sx={{ minHeight: "20px", display: 'flex', justifyContent: 'center'}}>
                                        <CancelIcon 
                                            style={{
                                                color: 'red', 
                                                width: '18px', 
                                                height: '18px'
                                            }} 
                                        />
                                    </Typography>
                                </Grid>
                                        </Grid>
                                        {
                                            fields?.map(f =>
                                                <Grid key={f.label} container gap='2px'>
                                                      <Grid item xs={5.4} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.label}:
                                            </SlidingTypography>                               
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.boot?.prise}
                                            </SlidingTypography>
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.boot?.nprise}
                                            </SlidingTypography>
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.user?.prise}
                                            </SlidingTypography>
                                        </Grid>
                                        <Grid item xs={1.5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                            <SlidingTypography variant={ isKid == "true" ? "" : ""} isKid={isKid} fast textAlign="center" delm>
                                                {f.value?.user?.nprise}
                                            </SlidingTypography>
                                        </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Stack>
                                </Stack>
                                :
                                <Stack display='flex' alignItems='center' justifyContent='center' width='95%' spacing={1}>
                                    {
                                        fields.map(f =>
                                            <Grid key={f.label} container gap='5px'>
                                                <Grid item xs={5} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                                    <SlidingTypography fast textAlign="center" delm>{f.label}:</SlidingTypography>                               
                                                </Grid>
                                                <Grid item xs={6} style={{ backgroundColor: '#ffebcd', border: '2px solid #b09795' }}>
                                                    <SlidingTypography fast textAlign="center" delm>{f.value}</SlidingTypography>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Stack>
                            }
                            <Stack paddingX="40px" marginTop="5px">
                                <Button onClick={onDetails} style={{backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}>
                                    <Typography style={{fontFamily: 'Delm'}}>
                                        {text.details}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Stack>      
                    </Stack>
                </Stack>
            </Stack>

        </Stack>
    )
}

export default SupervisorCard