// src/components/state/StatContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../../axios';
import { AuthStateContext } from './AuthStateContext';
import { funcObj } from '../tools/Snackbars';

const StatContext = createContext();

const StatProvider = ({ children }) => {
    const [stat, setStat] = useState(null);
    const { authState } = useContext(AuthStateContext);
    const [hold,setHold] = useState(0)

    const getStatistic = async () => {
        if (!authState.token) {
            console.log("No token found");
            return;
        }
        try {
            const { data } = await axiosInstance.get(`/statistic2`, {
                headers: { 'Authorization': `Bearer ${authState.token}` }
            });
            const conv = data.conversations
            console.log(data);
            setStat(data);
            setHold(conv ? funcObj(conv).reduce((acc,lang) => {
                const plats = conv[lang]
                return acc + funcObj(plats).reduce((langAcc, plat) => {
                    return langAcc + plats[plat].onHold;
                }, 0)},0):0)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (authState.token) {
            getStatistic();
        }
    }, [authState.token]);

    return (
        <StatContext.Provider value={{ 
            stat,
            setStat,
            holdC:hold
        }}>
            {children}
        </StatContext.Provider>
    );
};

export { StatProvider, StatContext };
