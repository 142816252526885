import React, {useState, useEffect, useContext} from "react";
import { Stack, Button, Typography, Box, CircularProgress } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import Subtitle from "./Subtitle";
import { useForm } from "react-hook-form";
import FormRow from "../../tools/FormRow";
import axiosInstance from "../../../axios";
import useData from "../../hooks/useData";
import countriesList from "../../../helper/countries";
import useList from "../../hooks/useList";
import { StatContext } from "../../state/StatContext";
import { format, parseISO } from 'date-fns';
import { AuthStateContext } from "../../state/AuthStateContext";
import { AccessArea } from "../../tools/Snackbars";

export default () => {
    const { text } = useLanguage()
    const { languagesgifList,listCAdmin,qualityControllerOpt1 } = useData()
    const { authState } = useContext(AuthStateContext)
    const { control, reset, handleSubmit, formState: { errors } } = useForm()
    const [error,setError] = useState('')
    const {platform, getPlatform} = useList()
    const [opt,setOpt] = useState(listCAdmin)
    const { stat,setStat } = useContext(StatContext);
    const admins = Object.keys(stat?.adminlist ?? {});
    const [adminss, setAdminss] = useState(admins) ;
    const [loadingActions, setLoadingActions] = useState(0) ;
    const deleteAdmin = async id =>{
        setLoadingActions( id )
        try {
            if (error) setError('')
            await axiosInstance.delete(`/admin_delete/${id}`, {headers: { 'Authorization': `Bearer ${authState.token}`}})
            const newList = admins.filter(a => stat.adminlist[a]?.id !== id)
            setAdminss(newList)
            setStat({...stat, adminlist: newList})
        } catch (e){setError(e.response)}
        setLoadingActions( 0 )
    }

    const StatAdminTab = (admin) => {
        return (
            <TableRow yellow columns={[
                { xs: 1.23,
                    customContent: true,
                    content: 
                            <Typography>
                                {stat.adminlist[admin]?.name}
                            </Typography>
                },
                { xs: 1.23,
                    customContent: true,
                    content: 
                            <Typography>
                                {stat.adminlist[admin]?.company}
                            </Typography>
                },
                { xs: 1.23,
                    customContent: true,
                    content: 
                            <Typography>
                                {stat.adminlist[admin]?.id}
                            </Typography>
                },
                { xs: 1.23,
                    customContent: true,
                    content: 
                            <Typography>
                                {text.administrator}
                            </Typography>
                },
                { xs: 1.23,
                    customContent: true,
                    content: 
                            <Typography>
                                {stat.adminlist[admin]?.tmFolder}
                            </Typography>
                },
                { xs: 1.23,
                    customContent: true,
                    content: <AccessArea admin={admin}/>
                },
                { xs: 1.23,
                    customContent: true,
                    content: 
                            <Typography>
                                { stat.adminlist[admin]?.accessvalidity ? format(parseISO(stat.adminlist[admin]?.accessvalidity), 'dd/MM/yy') : ""}
                            </Typography>
                },
                {
                    xs: 2.46,
                    customContent: true,
                    content: <Stack width='100%'>
                        {/* <Button 
                            style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                            onClick={()=>{}}
                        >
                            <Typography style={{fontFamily: 'Delm'}}>
                                {text.edit}
                            </Typography>
                        </Button>                  */}
                        <Button 
                            style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                            onClick={() => deleteAdmin (stat.adminlist[admin]?.id)}
                        >
                            {(loadingActions == stat.adminlist[admin]?.id) ? <CircularProgress/> :
                                <Typography style={{fontFamily: 'Delm'}}>
                                    {text.dlt}
                                </Typography>
                            }
                        </Button>          
                    </Stack>
                },
            ]}/>
        );
    };

    useEffect(() =>{
        getPlatform()
    },[])

    const newAdmin = async({name, mail, country, company, folder,password,accessarea,validity, languageName}) =>{
        if (error) setError('')
        try{
            await axiosInstance.post('/register',{name,mail, country, role: "administrator", company, folder,password,accessarea:JSON.stringify(opt),validity,languageName})
            reset()
        }catch(e){setError(e.response)}
    } 

    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        
        <Title>{text.tmfldaa}</Title>
        <Stack spacing={1}>
            <TableRow header yellow columns={[
                { xs: 1.23,  text: text.nm},
                { xs: 1.23,  text: text.cmpn},
                { xs: 1.23,  text: text.idd},
                { xs: 1.23,  text: text.role},
                { xs: 1.23,  text: text.tmfld},
                { xs: 1.23,  text: text.accar},
                { xs: 1.23,  text: text.assvldt},
                { xs: 2.46,  text: text.action},
            ]}/>
            {adminss.map((admin) => (<Stack key={admin}>{StatAdminTab(admin)}</Stack>))}
        </Stack>

        <Subtitle>{text.ddnwadm}</Subtitle>
        <Stack spacing={2}>
            <FormRow control={control} label={text.nm} name="name" error={errors.name} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.mladd} name="mail" error={errors.mail} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.language} name="languageName" comp="Select" error={errors.languageName} options={languagesgifList} bgCol="#9aacff" inpCol='#e3e8fe' />
            <FormRow control={control} label={text.pays} name="country" comp="Select" error={errors.country} options={countriesList} bgCol="#9aacff" inpCol='#e3e8fe'/>
            <FormRow control={control} label={text.cmpnnm} name="company" error={errors.company} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.tmfldr} name="folder" comp="Select" error={errors.folder} options={platform.map(p=> ({value: p.id, innerHTML:p.name}))} bgCol="#9aacff" inpCol='#e3e8fe'/>
            <FormRow control={control} label={text.psswrd} name="password" error={errors.password} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.accssar} comp="Select1" options={qualityControllerOpt1} name="accessarea" error={errors.accessarea} bgCol="#9aacff" variant="formrow3" inpCol='#e3e8fe' opt={opt} setOpt={setOpt}/>
            <FormRow control={control} label={text.valdt} name="validity" comp="DatePicker" maxDate="null" minDate = {new Date()} error={errors.validity} bgCol="#9aacff" className/>
        </Stack>
        <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <Button onClick={handleSubmit(newAdmin)} style={{ fontWeight: "bold", borderRadius: "30px", width: "fit-content", color: "#fff", background: "#4091d1", border: "3px solid #1c4b8e" }}>{text.rgstr}</Button>
            <Box component= "img" src="/images/admin2/register.png" style={{ height: "45px" }}/>
        </Stack>
    </Stack>
}

