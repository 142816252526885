import { Box, Dialog, Stack } from '@mui/material';
import React from 'react';

const CustomPopup = ({ open, onClose, children }) => {
  return (
    <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        {children}
    </Dialog>
  );
};

export default CustomPopup;